import {GridOverlay} from '@mui/x-data-grid-pro';
import React from "react";
import {Box, LinearProgress} from "@mui/material";

export function DataGridLoadingOverlay() {
    return (
        <GridOverlay>
            <Box sx={{ position: 'absolute', top: 0, width: '100%' }}>
                <LinearProgress />
            </Box>
        </GridOverlay>
    );
}

