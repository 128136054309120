import React, {FC} from "react";
import {Avatar, Box, IconButton, ListItem} from "@mui/material";
import {Delete, Engineering, Person} from "@mui/icons-material";
import {
    FormikMitarbeiterAutocomplete
} from "../../mitarbeiter/components/mitarbeiterAutocomplete/FormikMitarbeiterAutocomplete";
import {useField} from "formik";
import {FormikEuroInput} from "../../../components/formikInputs/FormikEuroInput";

interface ProjektMitarbeiterFormElementProps {
    // if 0 then is projektleiter
    projektMitarbeiterIndex: number;
    mitarbeiterIdFieldName: string;
    stundensatzFieldName: string;
    onRemove?: () => void;
    istPauschal?: boolean;
}

export const ProjektMitarbeiterFormElement: FC<ProjektMitarbeiterFormElementProps> = (props) => {

    const [, {value: projektLeiterId}] = useField<string>("projektLeiterId");

    const isProjektLeiter = props.projektMitarbeiterIndex === 0;

    const removeButton = (
        <IconButton onClick={props.onRemove}>
            <Delete/>
        </IconButton>
    );
    return (
        <ListItem
            sx={{pb: 1}}
            divider={true}
            secondaryAction={props.onRemove ? removeButton : undefined}
        >
            <Avatar sx={{bgcolor: isProjektLeiter ? "secondary.main" : undefined}}>
                {isProjektLeiter
                    ? <Engineering sx={{fontSize: "1.9rem"}}/>
                    : <Person sx={{fontSize: "2.0rem"}}/>}
            </Avatar>
            <Box sx={{pl: 2, display: "flex", flexDirection: "row"}}>
                <Box width={202} mr={2}>
                    <FormikMitarbeiterAutocomplete
                        variant="standard"
                        name={props.mitarbeiterIdFieldName}
                        label={isProjektLeiter ? "Projektleiter" : "Projektmitarbeiter"}
                        excludeIds={isProjektLeiter ? undefined : [projektLeiterId]}
                        required
                    />
                </Box>
                {!props.istPauschal && <FormikEuroInput
                    name={props.stundensatzFieldName}
                    textFieldProps={{
                        variant: "standard",
                        label: "Stundensatz",
                        fullWidth: true,
                        required: true
                    }}
                />}
            </Box>
        </ListItem>
    )
}
