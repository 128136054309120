import React, {FC} from "react";
import {useChangeOwnPasswordMutation} from "../../api/mitarbeiterApi";
import {usePasswordRequirements} from "./passwordCheckpoints.hook";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Stack,
    TextField
} from "@mui/material";
import {PasswordRequirementDisplay} from "./PasswordRequirementDisplay";

interface ChangeOwnPasswordDialogProps {
    open: boolean;
    onClose?: () => void;
    variant: "initial" | "non-initial"
}

export const ChangeOwnPasswordDialog: FC<ChangeOwnPasswordDialogProps> = (props) => {

    const [updateOwnPassword, {isLoading}] = useChangeOwnPasswordMutation();

    const [oldPassword, setOldPassword] = React.useState("");
    const [newPassword, setNewPassword] = React.useState("");
    const [confirmNewPassword, setNewPasswordConfirm] = React.useState("");
    const [error, setError] = React.useState<string | null>(null);
    const resetError = () => setError(null);

    const {score, requirementsColor, requirementsResults} = usePasswordRequirements(newPassword);

    const buttonDisabled = newPassword.length < 9 || confirmNewPassword.length < 9 || newPassword !== confirmNewPassword || !oldPassword;

    const handleChangePassword = () => {
        if (newPassword !== "" && confirmNewPassword !== "" && newPassword !== confirmNewPassword) {
            setError("Die Bestätigung des neuen Passwortes ist nicht gleich.");
        } else if (newPassword.length < 9) {
            setError("Das neue Passwort muss mindestens 9 Zeichen lang sein.");
        } else {
            updateOwnPassword({oldPassword, newPassword}).then(() => {
                if (props.onClose) {
                    props.onClose()
                }
            })
        }
    }

    const handleClose = () => {
        if (props.onClose) {
            setOldPassword("");
            setNewPassword("");
            setNewPasswordConfirm("");
            resetError();
            props.onClose();
        }
    }

    return (
        <Dialog
            open={props.open}
            onClose={handleClose}
            maxWidth="lg"
        >
            <DialogTitle>
                Passwort ändern
            </DialogTitle>
            <DialogContent sx={{position: "relative"}}>
                {isLoading && <LinearProgress sx={{width: "100%"}}/>}
                <Stack spacing={3}>
                    <DialogContentText>
                        {props.variant === "initial"
                            ? "Da Sie sich zum ersten mal einloggen oder Ihr Passwort zurückgesetzt wurde müssen Sie Ihr Passwort ändern."
                            : "Ändern Sie Ihr Passwort."
                        }
                    </DialogContentText>
                    <TextField
                        label="Altes Passwort"
                        type="password"
                        value={oldPassword}
                        onChange={({target}) => setOldPassword(target.value)}
                    />
                    <TextField
                        label="Neues Passwort"
                        placeholder="Mindestens 9 Zeichen"
                        type="password"
                        value={newPassword}
                        onChange={(e) => {
                            resetError();
                            setNewPassword(e.target.value);
                        }}
                        helperText={error ? error : `${newPassword.length} / 9`}
                        error={Boolean(error)}
                    />
                    <Box sx={{width: '100%', paddingX: "4px"}}>
                        {/*// TODO Mehrere Farben. Erst ab 9 Zeichen grün, davor orange.*/}
                        <LinearProgress variant="determinate" value={score} color={requirementsColor}/>
                    </Box>
                    <TextField
                        placeholder="Neues Passwort wiederholen"
                        type="password"
                        value={confirmNewPassword}
                        onChange={({target}) => {
                            resetError();
                            setNewPasswordConfirm(target.value);
                        }}
                        helperText={error && error}
                        error={Boolean(error)}
                    />
                    <Box>
                        <Stack spacing={2}>
                            {requirementsResults.map((rr) =>
                                <PasswordRequirementDisplay
                                    key={`requirement-indicator-${rr.label}`}
                                    isSatisfied={rr.satisfied}
                                    label={rr.label}
                                />
                            )}
                            <PasswordRequirementDisplay
                                isSatisfied={Boolean(newPassword && (newPassword === confirmNewPassword))}
                                label="Passwörter sind identisch"
                            />
                        </Stack>
                    </Box>
                </Stack>
            </DialogContent>
            <DialogActions>
                {props.onClose &&
                    <Button
                        sx={{color: "text.secondary"}}
                        onClick={handleClose}
                    >
                        Abbrechen
                    </Button>}
                <Button
                    disabled={buttonDisabled}
                    color="success"
                    onClick={handleChangePassword}
                >
                    Neues Passwort speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}

