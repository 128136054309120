import {createSlice, isRejectedWithValue} from "@reduxjs/toolkit";
import {RootState} from "../../state/store";

export interface Error {
    statusCode: number;
    error: string;
    message: string | string[];
}

const errorSlice = createSlice({
    name: "errors",
    initialState: {data: null as Error | null},
    reducers: {
        clearError(state) {
            state.data= null;
        }
    },
    extraReducers: (builder) =>
    builder.addMatcher((action) => isRejectedWithValue(action), (state, {payload}) => {
        const status = payload?.data?.statusCode as number | undefined;
        if (status !== 401) {
            state.data = payload.data;
        }
    })
});

export const selectError = (state: RootState)=> state.errors.data;

export const {clearError} = errorSlice.actions;

export default errorSlice.reducer;
