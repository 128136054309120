import * as yup from "yup";
import React, {FC} from "react";
import {useUpdateProjektMutation} from "../api/projektApi";
import {ProjektStammdatenFormElement} from "./ProjektStammdatenFormElement";
import {CreateProjektDTO, UpdateProjektDTO} from "../api/projekt.types";
import {FormikDialogForm} from "../../../components/formikDialogForm/components/FormikDialogForm";

export const projektValidationSchema = yup.object({
    name: yup.string().required("erforderlich"),
    rechnungszyklus: yup.string().required("erforderlich"),
    istPauschal: yup.boolean(),
    kurzbeschreibung: yup.string().nullable(),
    kundeId: yup.string().required("erforderlich"),
    bemerkung: yup.string().nullable()
});

export const UpdateProjektStammDatenDialogForm: FC = () => {

    const [updateProjekt, {isLoading: updateProjektLoading}] = useUpdateProjektMutation();

    return (
        <FormikDialogForm
            dialogId={"projektStammdaten"}
            title={"Projekt bearbeiten"}
            onSubmit={(dto) => updateProjekt(dto as UpdateProjektDTO).unwrap()}
            creationInitialValues={{} as CreateProjektDTO}
            validationSchema={projektValidationSchema}
            loading={updateProjektLoading}
            form={<ProjektStammdatenFormElement/>}
        />
    )
}
