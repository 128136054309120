import React, {FC} from "react";
import {useParams} from "react-router-dom";
import {Box, LinearProgress} from "@mui/material";
import {useFindKundeByIdQuery} from "../api/kundeApi";
import {KundeStammdatenCard} from "./KundeStammdatenCard";
import {PlainEntityDetailsHeader} from "../../../components/plainEntityDetailsHeader/PlainEntityDetailsHeader";
import {Assignment as ProjekteIcon, AssignmentInd as KundenIcon} from "@mui/icons-material";
import {ProjektTable} from "../../projekt/list/ProjektTable";
import {RouterTabs} from "../../../components/routerTabs/RouterTabs";

export const KundeViewScreen: FC = () => {
    const {id} = useParams();
    const {data, isLoading} = useFindKundeByIdQuery(id as string);

    return (
        <Box>
            {isLoading && <LinearProgress sx={{width: "100%"}}/>}
            {data &&
            <React.Fragment>
                <PlainEntityDetailsHeader
                    title={data.organisation}
                    createdAt={data.creationDate}
                    updatedAt={data.updateDate}
                />
                <RouterTabs
                    tabs={[
                        {
                            index: true,
                            path: "kunde",
                            label: "Kunde",
                            icon: <KundenIcon/>,
                            content: <Box width="50%"><KundeStammdatenCard kunde={data}/></Box>
                        },
                        {
                            path: "projekte",
                            label: "Projekte",
                            icon: <ProjekteIcon/>,
                            content: (
                                <ProjektTable
                                    initialFilterItems={{kundeId: {value: id, operator: "equal"}}}
                                />
                            )
                        }
                    ]}
                />
            </React.Fragment>}
        </Box>
    );
}
