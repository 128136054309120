import React, {FC} from "react";
import {useParams} from "react-router-dom";
import {Box, LinearProgress} from "@mui/material";
import {useFindProjektByIdQuery} from "../api/projektApi";
import {PlainEntityDetailsHeader} from "../../../components/plainEntityDetailsHeader/PlainEntityDetailsHeader";
import {Assignment as ProjekteIcon, AssignmentTurnedIn as AufwaendeIcon,} from "@mui/icons-material";
import {ProjektDetails} from "./details/ProjektDetails";
import {AufwandTable} from "../../aufwand/list/AufwandTable";
import {RouterTabs} from "../../../components/routerTabs/RouterTabs";

export const ProjektViewScreen: FC = () => {
    const {id} = useParams();
    const {data: projekt, isLoading} = useFindProjektByIdQuery(id as string);

    return (
        <Box>
            {isLoading && <LinearProgress sx={{width: "100%"}}/>}
            {projekt &&
            <React.Fragment>
                <PlainEntityDetailsHeader
                    title={projekt.name}
                    createdAt={projekt.creationDate}
                    updatedAt={projekt.updateDate}
                />
                <RouterTabs
                    tabs={[
                        {
                            index: true,
                            path: "projekt",
                            label: "Projekt",
                            icon: <ProjekteIcon/>,
                            content: <ProjektDetails projekt={projekt}/>
                        },
                        {
                            path: "aufwaende",
                            label: "Aufwände",
                            icon: <AufwaendeIcon/>,
                            content: (
                                <AufwandTable
                                    initialFilterItems={{projektId: {value: projekt.id, operator: "equal"}}}
                                    stackProps={{sx: {height: 500}}}
                                />
                            )
                        },
                    ]}
                />
            </React.Fragment>}
        </Box>
    );
}
