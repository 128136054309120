import React, {FC} from "react";
import logo from "../../logo.svg";
import {DcbApp} from "../../components/dcbApp/DcbApp";
import {AppFeature} from "../../components/dcbApp/types";
import {aufwandFeature} from "../aufwand/aufwandFeature";
import {projektFeature} from "../projekt/projektFeature";
import {kundeFeature} from "../kunde/kundeFeature";
import {mitarbeiterFeature} from "../mitarbeiter/mitarbeiterFeature";
import {PrivacyTip as DatenschutzIcon, Dashboard as DashboardIcon} from "@mui/icons-material";
import {Dashboard} from "../dashboard/Dashboard";
import {DatenschutzDialogForm} from "../datenschutz/DatenschutzDialogForm";
import {DatenschutzContainer} from "../datenschutz/DatenschutzContainer";
import {useNavigate} from "react-router-dom";
import {useMitarbeiterRole} from "../auth/api/auth.hook";
import {MitarbeiterRole} from "../auth/api/types";

export const features: AppFeature[] = [
    {
        path: "dashboard",
        label: "Dashboard",
        icon: <DashboardIcon sx={{color: "secondary.main"}}/>,
        view: <Dashboard/>
    },
    aufwandFeature,
    projektFeature,
    kundeFeature,
    mitarbeiterFeature,
    {
        path: "datenschutzhinweise",
        label: "Datenschutzhinweise",
        icon: <DatenschutzIcon sx={{color: "secondary.main"}}/>,
        view: <DatenschutzContainer/>,
        dialogs: [<DatenschutzDialogForm/>]
    }
];

const useGuardInternApp = () => {
    const navigate = useNavigate();
    const role = useMitarbeiterRole();

    React.useEffect(() => {
        if (role === MitarbeiterRole.EXTERN) {
            navigate('/extern');
        }
    })
};

export const InternApp: FC<{ hasAccessToken: boolean }> = ({hasAccessToken}) => {
    useGuardInternApp();
    return (
        <DcbApp
            loggedIn={hasAccessToken}
            logo={<img src={logo} alt="Logo" style={{height: "42px"}}/>}
            features={features}
        />
    )
}
