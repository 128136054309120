import React, {FC} from "react";
import {useField} from "formik";
import {MitarbeiterRole} from "../../features/auth/api/types";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from "@mui/material";

interface FormikUserRoleFieldProps {
    name: string;
    label: string;
}

export const FormikUserRoleField: FC<FormikUserRoleFieldProps> = (props) => {
    const [, meta, helpers] = useField<MitarbeiterRole>(props.name);

    // console.log("vv", meta.value);

    return (
        <FormControl fullWidth error={Boolean(meta.error && meta.touched)}>
            <InputLabel id="formik-role-field-label">{props.label}</InputLabel>
            <Select
                labelId="formik-role-field-label"
                id={`formik-role-field-${props.name}`}
                value={meta.value}
                label="Age"
                onChange={(e) => helpers.setValue(e.target.value as MitarbeiterRole)}
            >
                {Object.values(MitarbeiterRole).map((value) =>
                    <MenuItem
                        key={`menu-item-${value}`}
                        value={value}
                    >
                        {value}
                    </MenuItem>
                )}
            </Select>
            {meta.touched && meta.error &&
            <FormHelperText>
                {meta.error}
            </FormHelperText>}
        </FormControl>
    )
}
