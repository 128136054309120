import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Fade,
    FormControl,
    FormControlLabel,
    FormLabel,
    IconButton,
    Radio,
    RadioGroup,
    Stack,
    TextField,
    Tooltip
} from "@mui/material";
import {ProjektAutocomplete} from "../../projekt/components/projektAutocomplete/ProjektAutocomplete";
import {MitarbeiterAutocomplete} from "../../mitarbeiter/components/mitarbeiterAutocomplete/MitarbeiterAutocomplete";
import {DesktopDatePicker} from "@mui/lab";
import {ChevronLeft, Clear, FilterList} from "@mui/icons-material";
import {AufwandApiFilters} from "../api/aufwandApi";
import {endOfDay, startOfDay} from "date-fns";

interface FilterSidePanelProps {
    open: boolean;
    toggleOpen: () => void;
    apiFilters?: AufwandApiFilters;
    setApiFilters: (newApiFilters?: AufwandApiFilters) => void;
}

export function FilterSidePanel(props: FilterSidePanelProps) {

    const hasFilter = props.apiFilters && Object.keys(props.apiFilters).length > 0;

    const clearFilters = () => props.setApiFilters(undefined);

    return (
        <Box sx={{position: "relative", minWidth: 32, height: "100%"}}>
            <Fade in={!props.open} timeout={1}>
                <Tooltip title={"Filter zeigen"}>
                    <IconButton
                        size="small"
                        sx={{position: "absolute", top: 8, left: 0}}
                        onClick={props.toggleOpen}
                    >
                        <FilterList/>
                    </IconButton>
                </Tooltip>
            </Fade>
            <Collapse orientation="horizontal" in={props.open} sx={{height: "100%"}}>
                {hasFilter
                    ? (
                        <Button
                            fullWidth
                            variant="contained"
                            color="secondary"
                            startIcon={<Clear/>}
                            sx={{flex: 1, my: 1, whiteSpace: "nowrap"}}
                            onClick={clearFilters}
                        >
                            Alle Filter entfernen
                        </Button>
                    )
                    : <Box sx={{flex: 1, mb: 3, height: 36}}/>
                }
                <Card sx={{height: "100%", overflow: "auto"}}>
                    <CardContent>
                        <Stack spacing={2}>
                            <ProjektAutocomplete
                                label={"Projekt"}
                                value={props.apiFilters?.projektId?.value}
                                onChange={(projektId) =>
                                    props.setApiFilters(projektId
                                        ? {
                                            ...props.apiFilters,
                                            projektId: {operator: "equal", value: projektId}
                                        }
                                        : {
                                            ...props.apiFilters,
                                            projektId: undefined
                                        }
                                    )}
                            />
                            <MitarbeiterAutocomplete
                                label={"Mitarbeiter"}
                                value={props.apiFilters?.mitarbeiterId?.value}
                                onChange={(mitarbeiterId) =>
                                    props.setApiFilters(mitarbeiterId
                                        ? {
                                            ...props.apiFilters,
                                            mitarbeiterId: {operator: "equal", value: mitarbeiterId}
                                        }
                                        : {
                                            ...props.apiFilters,
                                            mitarbeiterId: undefined
                                        }
                                    )}
                            />
                            <TextField
                                label="TicketId"
                                value={props.apiFilters?.ticketId?.value}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (value) {
                                        props.setApiFilters({
                                            ...props.apiFilters,
                                            ticketId: {
                                                operator: "like",
                                                value
                                            }
                                        })
                                    } else {
                                        props.setApiFilters({
                                            ...props.apiFilters,
                                            ticketId: undefined
                                        })
                                    }
                                }}
                            />
                            <DesktopDatePicker
                                label="Von"
                                inputFormat="dd.MM.yyyy"
                                InputProps={{readOnly: true}}
                                // mask="__.__.____"
                                value={props.apiFilters?.datum?.from ? new Date(props.apiFilters?.datum?.from) : null}
                                onChange={(date) => {
                                    if (date) {
                                        props.setApiFilters({
                                            ...props.apiFilters,
                                            datum: {
                                                operator: "between",
                                                from: (startOfDay(date)).toISOString(),
                                                to: props.apiFilters?.datum?.to ?? ""
                                            }
                                        });
                                    } else {
                                        props.setApiFilters({
                                            ...props.apiFilters,
                                            datum: {
                                                operator: "between",
                                                from: "",
                                                to: props.apiFilters?.datum?.to ?? ""
                                            }
                                        });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                    />
                                )}
                            />
                            <DesktopDatePicker
                                label="Bis"
                                inputFormat="dd.MM.yyyy"
                                InputProps={{readOnly: true}}
                                value={props.apiFilters?.datum?.to ? new Date(props.apiFilters?.datum?.to) : null}
                                onChange={(date) => {
                                    if (date !== null) {
                                        props.setApiFilters({
                                            ...props.apiFilters,
                                            datum: {
                                                operator: "between",
                                                from: props.apiFilters?.datum?.from ?? "",
                                                to: (endOfDay(date)).toISOString()
                                            }
                                        });
                                    } else {
                                        props.setApiFilters({
                                            ...props.apiFilters,
                                            datum: {
                                                operator: "between",
                                                from: props.apiFilters?.datum?.from ?? "",
                                                to: ""
                                            }
                                        });
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                    />
                                )}
                            />
                            <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-around"}}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Fakturierbar</FormLabel>
                                    <RadioGroup
                                        sx={{pl: 1}}
                                        aria-label="istFakturierbar"
                                        name="istFakturierbar-radio-buttons-group"
                                        value={props?.apiFilters?.istFakturierbar?.value ?? ""}
                                        onChange={(e) => {
                                            const value = (e.target as HTMLInputElement).value as string | boolean;
                                            console.log("vvv", value);
                                            if (value === "true" || value === "false") {
                                                console.log("hier??")
                                                props.setApiFilters({
                                                    ...props.apiFilters,
                                                    istFakturierbar: {
                                                        operator: "equal",
                                                        value: value === "true"
                                                    }
                                                })
                                            } else {
                                                props.setApiFilters({
                                                    ...props.apiFilters,
                                                    istFakturierbar: undefined
                                                })
                                            }
                                        }}
                                    >
                                        <FormControlLabel value={""} control={<Radio/>} label="Alle"/>
                                        <FormControlLabel value={true} control={<Radio/>} label="Ja"/>
                                        <FormControlLabel value={false} control={<Radio/>} label="Nein"/>
                                    </RadioGroup>
                                </FormControl>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Abgerechnet</FormLabel>
                                    <RadioGroup
                                        sx={{pl: 1}}
                                        aria-label="rechnungsnummer"
                                        name="rechnungsnummer-radio-buttons-group"
                                        value={props.apiFilters?.rechnungsnummer?.operator ?? ""}
                                        onChange={(e) => {

                                            const value = e.target.value as "" | "isNull" | "isNotNull";

                                            if(value === "isNotNull" || value === "isNull") {
                                                props.setApiFilters({
                                                    ...props.apiFilters,
                                                    rechnungsnummer: {
                                                        operator: value
                                                    }
                                                })
                                            } else {
                                                props.setApiFilters({
                                                    ...props.apiFilters,
                                                    rechnungsnummer: undefined
                                                })
                                            }
                                        }}
                                    >
                                        <FormControlLabel value={""} control={<Radio/>} label="Alle"/>
                                        <FormControlLabel value={"isNotNull"} control={<Radio/>} label="Ja"/>
                                        <FormControlLabel value={"isNull"} control={<Radio/>} label="Nein"/>
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </Stack>
                        <Tooltip title={"Filter einklappen"}>
                            <IconButton
                                sx={{mt: 3}}
                                size="small"
                                onClick={props.toggleOpen}
                            >
                                <ChevronLeft/>
                            </IconButton>
                        </Tooltip>
                    </CardContent>
                </Card>
            </Collapse>
        </Box>
    )
}
