import {Kunde} from "../features/kunde/api/types";

interface NameInterface {
    anrede: string;
    vorname: string;
    nachname: string;
}

export function formatName<T extends NameInterface>(name: T , showAnrede = true) {
    return `${showAnrede ? `${name.anrede} ` : ""}${name.vorname} ${name.nachname}`
}

export function formatKundeAdresse(kunde: Kunde) {
   return `${kunde.strasse ?? ""} ${kunde.hausnummer ? `${kunde.hausnummer}, `: ""} ${kunde.adresszusatz ? `${kunde.adresszusatz},` : ""} ${kunde.postleitzahl ?? ""} ${kunde.stadt ?? ""}`
}

//TODO generisch machen
