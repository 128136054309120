import {createApi} from '@reduxjs/toolkit/query/react';
import {CreateKundeDTO, Kunde, UpdateKundeDTO} from "./types";
import {baseQueryWithReauth} from "../../auth/api/api.utils";

export const kundeApi = createApi({
    reducerPath: 'kundeApi',
    tagTypes: ["Kunde"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createKunde: builder.mutation<Kunde, CreateKundeDTO>({
            query(body) {
                return {
                    url: "kunde",
                    method: "POST",
                    body
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Kunde', id: 'LIST'}] : []
        }),
        updateKunde: builder.mutation<Kunde, UpdateKundeDTO>({
            query({id,...dto}) {
                return {
                    url: `kunde/${id}`,
                    method: "PATCH",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Kunde', id: 'LIST'}, {type: 'Kunde', id: result.id}] : []
        }),
        findKundeById: builder.query<Kunde, string>({
            query: (id) => `kunde/${id}`,
            providesTags: (_r, _e, id) => [{type: 'Kunde', id}]
        }),
        findAllKunde: builder.query<Kunde[], void>({
            query: () => `kunde`,
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Kunde', id} as const)),
                    {type: 'Kunde', id: 'LIST'},
                ]
                : [{type: 'Kunde', id: 'LIST'}],
        })
    }),
})

export const {
    useCreateKundeMutation,
    useFindKundeByIdQuery,
    useFindAllKundeQuery,
    useUpdateKundeMutation
} = kundeApi;
