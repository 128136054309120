import {createTheme} from "@mui/material/styles";
import {deDE as coreDeDe} from '@mui/material/locale';
import { deDE as tableDeDe } from '@mui/x-data-grid-pro';


export const theme = createTheme({
    palette: {
        primary: {
            main: "#69162A",
            light: "#9B4452",
            dark: "#3A0000"
        },
        secondary: {
            main: "#20304F",
            light: "#4B597B",
            dark: "#000627"
        },
        text: {
            primary: "#000000DE",
            secondary: "#00000099",
            disabled: "#00000061"
        }
    }
},
    coreDeDe,
    tableDeDe
);
