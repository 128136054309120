import React, {FC} from "react";
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import {Edit, MoreVert} from "@mui/icons-material";

interface CardTitleWithEditProps {
    title?: string;
    optionLabel?: string;
    onClickEdit?: () => void;
    optionIcon?: JSX.Element;
}

export const CardTitleWithEdit: FC<CardTitleWithEditProps> = ({title, optionIcon, optionLabel, onClickEdit}) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <Typography variant="h6" color="textSecondary">
                {title?? ''}
            </Typography>
            {typeof onClickEdit === "function" &&
                <React.Fragment>
                    <IconButton
                        id="card-menu-button"
                        aria-controls="card-menu"
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreVert/>
                    </IconButton>
                    <Menu
                        id="card-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'card-menu-button',
                        }}
                    >
                        <MenuItem
                            onClick={() => {
                                onClickEdit();
                                handleClose();
                            }}
                        >
                            <ListItemIcon>
                                {optionIcon ?? <Edit/>}
                            </ListItemIcon>
                            <ListItemText>
                                {optionLabel ?? "Bearbeiten"}
                            </ListItemText>
                        </MenuItem>
                    </Menu>
                </React.Fragment>}
        </Box>
    );
}
