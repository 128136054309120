import React, {FC, useCallback} from "react";
import {Aufwand} from "../api/types";
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    LinearProgress,
    TextField,
    Typography
} from "@mui/material";
import {useRechnungsnummerVergebenMutation} from "../api/aufwandApi";

function checkAufwaendeHasRechnungsNummer(aufwaende: Aufwand[]) {
    const aufwaendeMitRechnungsId = aufwaende.filter((a) => a.rechnungsnummer !== null);
    return aufwaendeMitRechnungsId.length > 0;
}

interface RechnungsNummerDialogProps {
    selectedAufwaende: Aufwand[];
}

export const RechnungsNummerDialogButton: FC<RechnungsNummerDialogProps> = ({selectedAufwaende}) => {

    const [open, setOpen] = React.useState(false);

    const [hasAufwaendeMitReNr, setHasAufwaendeMitReNr] = React.useState(false);

    const [confirmation, setConfirmation] = React.useState(false);

    React.useEffect(() => {
        if (open) {
            setHasAufwaendeMitReNr(checkAufwaendeHasRechnungsNummer(selectedAufwaende));
        }
    }, [selectedAufwaende, open, setHasAufwaendeMitReNr]);

    const [newRechnungsNummer, setNewRechnungsNummer] = React.useState<string | null>(null);

    const [rechnungsnummerVergeben, {isLoading}] = useRechnungsnummerVergebenMutation();

    const rechnungsNummerVergeben = useCallback(() => {
        if(newRechnungsNummer) {
            rechnungsnummerVergeben({
                aufwandIds: selectedAufwaende.map((a) => a.id),
                rechnungsnummer: newRechnungsNummer
            }).unwrap().then(() => setOpen(false))
        }
    }, [selectedAufwaende, newRechnungsNummer, rechnungsnummerVergeben])

    return (
        <React.Fragment>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(true)}
            >
                Re.-Nr. vergeben
            </Button>
            <Dialog open={open} onClose={() => setOpen(false)}>
                <DialogTitle>
                    Rechnungsnummer vergeben
                </DialogTitle>
                <DialogContent>
                    <Typography sx={{my: 2}}>Rechnungsnummer für ausgewählte Aufwände vergeben</Typography>
                    <TextField
                        label="Rechnungsnummer"
                        fullWidth
                        value={newRechnungsNummer}
                        onChange={(e) =>
                            setNewRechnungsNummer(e.target.value || null)
                        }
                    />
                    {hasAufwaendeMitReNr && (
                        <Box sx={{pl: 1, pt: 4,}}>
                            <Typography sx={{mb: 1}} color="error">
                                ACHTUNG! Einige ausgewählte Aufwände haben bereits eine Rechnungsnummer.
                                Sind Sie sicher, dass Sie diesen eine neue Rechnungsnummer geben möchten?
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={confirmation}
                                        onChange={(e) => setConfirmation(e.target.checked)}
                                    />
                                }
                                label={"Ja, ich bin mir sicher."}
                            />
                        </Box>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button
                        sx={{color: "text.secondary"}}
                        onClick={() => setOpen(false)}
                    >
                        Abbrechen
                    </Button>
                    <Button
                        disabled={!confirmation && hasAufwaendeMitReNr}
                        color="success"
                        onClick={rechnungsNummerVergeben}
                    >
                        Speichern
                    </Button>
                </DialogActions>
                {isLoading && <LinearProgress/>}
            </Dialog>
        </React.Fragment>
    )
}
