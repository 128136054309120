import React, {FC} from "react";
import {Link, Typography, TypographyProps} from "@mui/material";

export interface EmailDisplayProps {
    label?: string;
    value?: string | null;
    captionProps?: TypographyProps;
    displayProps?: TypographyProps;
}

export const EmailDisplay: FC<EmailDisplayProps> = ({label, value}) => (
    <Typography variant="caption" color="text.secondary">
        {label}
        <Typography>
            <Link href={`mailto:${value}`} variant="body2" color="secondary">
                {value}
            </Link>
        </Typography>
    </Typography>
);
