import React, {FC} from "react";
import {Autocomplete, CircularProgress, TextField} from "@mui/material";
import {useProjektAutoCompleteOptionsQuery} from "../../api/projektApi";
import {Projekt} from "../../api/projekt.types";

interface ProjektAutocompleteProps {
    label: string;
    value?: string | null;
    onChange: (projektId: string | null) => void;
    error?: string;
    required?: boolean;
}

export const ProjektAutocomplete: FC<ProjektAutocompleteProps> = ({value, onChange, label, error, required}) => {

    const {data, isFetching: isFetchingList} = useProjektAutoCompleteOptionsQuery();

    const projekte = data ?? [];

    return (
        <Autocomplete
            value={projekte.find((k) => k.id === value) ?? null}
            renderInput={(params) =>
                <TextField
                    onClick={(e)=>{e.stopPropagation()}}
                    {...params}
                    label={label}
                    variant="outlined"
                    error={Boolean(error)}
                    helperText={error}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isFetchingList && <CircularProgress color="inherit" size={20}/>}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            }
            options={projekte}
            getOptionLabel={(o) => `${o.kunde.organisation} - ${o.name}`}
            onChange={(_, value) => {
                const projekt: Projekt | null = value;
                if (projekt) {
                    /* setSelectedProjektId(projekt.id);*/
                    onChange(projekt.id);
                } else {
                    /*setSelectedProjektId(null);*/
                    onChange(null);
                }
            }}
            noOptionsText="Nicht gefunden"
        />
    )
}
