import React, {FC} from "react";
import {Cell, Pie, PieChart} from "recharts";
import {Box, Typography} from "@mui/material";

const RADIAN = Math.PI / 180;

interface DataItem {
    name: string;
    value: number;
    color: string;
}

const data: DataItem[] = [
    {name: 'A', value: 15, color: '#e57373'},
    {name: 'B', value: 15, color: '#fff176'},
    {name: 'C', value: 30, color: '#81c784'},
    {name: 'D', value: 35, color: '#388e3c'},
];

const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;

const needle = (
    value: number,
    data: DataItem[],
    cx: number,
    cy: number,
    iR: number,
    oR: number,
    color: string
) => {
    let total = 0;
    data.forEach((v) => {
        total += v.value;
    });
    const ang = 180.0 * (1 - value / total);
    const length = (iR + 2 * oR) / 3;
    const sin = Math.sin(-RADIAN * ang);
    const cos = Math.cos(-RADIAN * ang);
    const r = 5;
    const x0 = cx + 5;
    const y0 = cy + 5;
    const xba = x0 + r * sin;
    const yba = y0 - r * cos;
    const xbb = x0 - r * sin;
    const ybb = y0 + r * cos;
    const xp = x0 + length * cos;
    const yp = y0 + length * sin;

    return [
        <circle cx={x0} cy={y0} r={r} fill={color} stroke="none"/>,
        <path d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`} stroke="#none" fill={color}/>,
    ];
}


interface ChartDataProps {
    chartData: { name: string, value: number }
    key: number
}

export const FakturierbareAufwaendeTacho: FC<ChartDataProps> = ({chartData, key}) => {

    return (
        <Box key={key}>
            < PieChart width={300} height={200}>
                <Pie
                    dataKey="value"
                    startAngle={180}
                    endAngle={0}
                    data={data}
                    cx={cx}
                    cy={cy}
                    innerRadius={iR}
                    outerRadius={oR}
                    fill="#69162A"
                    stroke="none"
                >
                    {data.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={entry.color}/>
                        )
                    )
                    }
                </Pie>
                {
                    needle(chartData.value, data, cx, cy, iR, oR, '#69162A')
                }
            </PieChart>
            <Box display="flex" flexDirection="column" alignItems="center" mt={2}>
                <Typography variant="h6">{chartData.value}%</Typography>
                <Typography variant="h6">{chartData.name}</Typography>
            </Box>
        </Box>
    )
}
