import React, {FC} from "react";
import {useField} from "formik";
import {CreateProjektMitarbeiterArrayElement} from "../api/projekt.types";
import {IconButton, List, ListItem, Typography} from "@mui/material";
import {ProjektMitarbeiterFormElement} from "./ProjektMitarbeiterFormElement";
import {Add} from "@mui/icons-material";

export const BeteiligteMitarbeiterFormElement: FC = () => {
    const [,
        {value: beteiligteMitarbeiter},
        helpers,
    ] = useField<CreateProjektMitarbeiterArrayElement[]>("projektMitarbeiter");

    const [{value: istPauschal}] = useField<boolean>("istPauschal");

    const addEmptyProjektMitarbeiter = () => helpers.setValue([
        ...beteiligteMitarbeiter,
        {mitarbeiterId: "", stundenSatzInEuroCent: 0}
    ]);

    const removeProjektMitarbeiter = (projektMitarbeiterIndex: number) => helpers.setValue(
        beteiligteMitarbeiter.filter((_, i) => i !== projektMitarbeiterIndex)
    );

    return (
        <React.Fragment>
            <Typography>Beteiligte Mitarbeiter</Typography>
            <List dense>
                <ProjektMitarbeiterFormElement
                    key="projektleiter-item"
                    projektMitarbeiterIndex={0}
                    mitarbeiterIdFieldName="projektLeiterId"
                    stundensatzFieldName="projektLeiterStundenSatzInEuroCent"
                    istPauschal={istPauschal}
                />
                {beteiligteMitarbeiter.map((_, i) => {
                        const basePath = `projektMitarbeiter[${i}]`;
                        return (
                            <ProjektMitarbeiterFormElement
                                istPauschal={istPauschal}
                                key={`projekt-mitarbeiter-item-${i}`}
                                projektMitarbeiterIndex={i + 1}
                                mitarbeiterIdFieldName={`${basePath}.mitarbeiterId`}
                                stundensatzFieldName={`${basePath}.stundenSatzInEuroCent`}
                                onRemove={() => removeProjektMitarbeiter(i)}
                            />
                        )
                    }
                )}
                <ListItem key="add-item" sx={{pt: 2}}>
                    <IconButton onClick={addEmptyProjektMitarbeiter}>
                        <Add/>
                    </IconButton>
                </ListItem>
            </List>
        </React.Fragment>
    )
}
