import React from "react";
import {useField} from "formik";
import {FormControl, FormHelperText, InputLabel, MenuItem, Select, Tooltip} from "@mui/material";
import {InfoOutlined} from "@mui/icons-material";

interface FormikSelectProps {
    label: string;
    name: string;
    options: { label: string; value: string }[];
    toolTip?: string;
    required?: boolean;
}


export function FormikSelect(props: FormikSelectProps) {
    const [, meta, helpers] = useField<string>(props.name);

    return (
        <FormControl required={props.required} fullWidth error={Boolean(meta.error && meta.touched)}>
            <InputLabel style={{overflow: "visible"}} id="formik-select-label">{props.label}</InputLabel>
            <Select
                variant="outlined"
                labelId="formik-select-label"
                id={`formik-select-${props.name}`}
                value={meta.value}
                label={props.label}
                onChange={(e) => helpers.setValue(e.target.value)}
            >
                {props.options.map((o) =>
                    <MenuItem
                        key={`menu-item-${o.value}`}
                        value={o.value}
                    >
                        {o.label}
                    </MenuItem>
                )}
            </Select>
            {meta.touched && meta.error &&
                <FormHelperText>
                    {meta.error}
                </FormHelperText>}
            {props.toolTip &&
                <Tooltip title={props.toolTip} placement="left-start">
                    <InfoOutlined
                        sx={{
                            color: "warning.light",
                            bgcolor: "white",
                            position: "absolute",
                            top: -10,
                            right: -10
                        }}
                    />
                </Tooltip>}
        </FormControl>
    )
}
