import React, {FC} from "react";
import {useParams} from "react-router-dom";
import {MitarbeiterStammdatenCard} from "./MitarbeiterStammdatenCard";
import {Box, LinearProgress} from "@mui/material";
import {useFindMitarbeiterByIdQuery} from "../api/mitarbeiterApi";
import {PlainEntityDetailsHeader} from "../../../components/plainEntityDetailsHeader/PlainEntityDetailsHeader";
import {formatName} from "../../../components/formatUtils";
import {
    Assignment as ProjekteIcon,
    AssignmentTurnedIn as AufwaendeIcon,
    People as MitarbeiterIcon
} from "@mui/icons-material";
import {AufwandTable} from "../../aufwand/list/AufwandTable";
import {RouterTabs} from "../../../components/routerTabs/RouterTabs";
import {MitarbeiterRole} from "../../auth/api/types";
import {ProjektTableForMitarbeiter} from "../../projekt/list/ProjektTableForMitarbeiter";

export const MitarbeiterViewScreen: FC = () => {
    const {id} = useParams();
    const {data: mitarbeiter, isLoading} = useFindMitarbeiterByIdQuery(id as string);

    return (
        <Box>
            {isLoading && <LinearProgress sx={{width: "100%"}}/>}
            {mitarbeiter &&
            <React.Fragment>
                <PlainEntityDetailsHeader
                    title={`${formatName(mitarbeiter, false)}${mitarbeiter.role === MitarbeiterRole.ADMIN ? " (Admin)" : ""}` }
                    createdAt={mitarbeiter.creationDate}
                    updatedAt={mitarbeiter.updateDate}
                />
                <RouterTabs
                    tabs={[
                        {
                            index: true,
                            path: "mitarbeiter",
                            label: "Mitarbeiter",
                            icon: <MitarbeiterIcon/>,
                            content: <Box width="50%">
                                <MitarbeiterStammdatenCard mitarbeiter={mitarbeiter}/>
                            </Box>
                        },
                        {
                            path: "projekte",
                            label: "Projekte",
                            icon: <ProjekteIcon/>,
                            content: (
                                <ProjektTableForMitarbeiter mitarbeiterId={mitarbeiter.id}/>
                            )
                        },
                        {
                            path: "aufwaende",
                            label: "Aufwände",
                            icon: <AufwaendeIcon/>,
                            content: <AufwandTable
                                initialFilterItems={{mitarbeiterId: id ? {value: id, operator: "equal"} : undefined}}
                                stackProps={{sx: {height: 500}}}
                            />
                        },
                    ]}
                />
            </React.Fragment>}
        </Box>
    );
}
