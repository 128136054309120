import React, {FC} from "react";
import {useAufwaendeZusammenfassungQuery} from "../api/aufwandApi";
import {Dialog, DialogContent, DialogTitle} from "@mui/material";
import {GridColDef, GridToolbarContainer, GridToolbarExport, GridToolbarFilterButton} from "@mui/x-data-grid-pro";
import {AppDataGrid} from "../../../components/table/AppDataGrid";
import {EuroDisplay} from "../../../components/euroDisplay/EuroDisplay";

interface AufwanedeZusammenfassungProps {
    aufwaendeIds: string[];
    open: boolean;
    onClose: () => void;
}

const columns: GridColDef[] = [
    {
        field: 'mitarbeiterName',
        headerName: 'Mitarbeiter',
        flex: 3,
    },
    {
        field: 'projektName',
        headerName: 'Projekt',
        flex: 3,
    },
    {
        field: 'dauerInMillis',
        headerName: 'Dauer in Stunden',
        flex: 2,
        type: "number",
        valueFormatter: params => (params.value as number / 3600000).toLocaleString("de")
    },
    {
        field: 'stundenSatzInEuroCent',
        headerName: 'Stundensatz',
        flex: 2,
        type: "number",
        valueFormatter: params => (params.value as number / 100).toLocaleString("de"),
        renderCell: params => <EuroDisplay valueInEuroCent={params.value as number}/>
    },
    {
        field: 'summeInEuroCent',
        headerName: 'Summe',
        flex: 2,
        type: "number",
        valueFormatter: params => (params.value as number / 100).toLocaleString("de"),
        renderCell: params => <EuroDisplay valueInEuroCent={params.value as number} suffix=" €"/>
    },
];

export const AufwaendeZusammenfassungDialog: FC<AufwanedeZusammenfassungProps> = (props) => {

    const {
        data: zusammenfassung,
        isFetching
    } = useAufwaendeZusammenfassungQuery(
        {aufwaendeIds: props.aufwaendeIds},
        {skip: !props.open || props.aufwaendeIds.length === 0}
    );

    return (
        <Dialog open={props.open} onClose={props.onClose} maxWidth={"xl"}>
            <DialogTitle>Zusammenfassung</DialogTitle>
            <DialogContent>
                <AppDataGrid
                    onRowClick={() => {}}
                    style={{width: 800, height: 600}}
                    components={{
                        Toolbar: () => (
                            <GridToolbarContainer style={{paddingLeft: 8, paddingTop: 8, paddingBottom: 16}}>
                                <GridToolbarFilterButton/>
                                <GridToolbarExport
                                    csvOptions={{
                                        delimiter: ";",
                                        includeHeaders: true,
                                        fileName: "EffortLog-Zusammenfassung",
                                        utf8WithBom: true
                                    }}
                                />
                            </GridToolbarContainer>
                        )
                    }}
                    columns={columns}
                    rows={zusammenfassung ?? []}
                    loading={isFetching}
                />
            </DialogContent>
        </Dialog>
    )
}
