import {createApi} from '@reduxjs/toolkit/query/react'
import {CreateProjektDTO, Projekt, UpdateProjektDTO} from './projekt.types'
import {ListQueryArgs} from "../../../components/types";
import {baseQueryWithReauth} from "../../auth/api/api.utils";
import {CreateProjektMitarbeiterDTO, ProjektMitarbeiter, UpdateProjektMitarbeiterDTO} from "./projektMitarbeiter.types";
import {Kunde} from "../../kunde/api/types";

interface ProjektMitarbeiterListResult {
    data: ProjektMitarbeiter[];
    pagination: {
        count: number;
        pageSize: number;
        page: number;
    }
}

export interface ProjektListResult {
    data: Projekt[];
    pagination: {
        count: number;
        pageSize: number;
        page: number;
    }
}

interface ProjektAutCompleteOption extends Projekt {
    kunde: Kunde;
}

export type ProjektAutoCompleteOptionsResult = ProjektAutCompleteOption[];

export const projektApi = createApi({
    reducerPath: 'projektApi',
    tagTypes: ["Projekt", "ProjektMitarbeiter", "ProjektAutocompleteOption"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        // Projekt
        createProjekt: builder.mutation<Projekt, CreateProjektDTO>({
            query(body) {
                return {
                    url: "projekt",
                    method: "POST",
                    body
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Projekt', id: 'LIST'}] : []
        }),
        updateProjekt: builder.mutation<Projekt, UpdateProjektDTO>({
            query({id,...dto}) {
                return {
                    url: `projekt/${id}`,
                    method: "PATCH",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Projekt', id: 'LIST'}, {type: 'Projekt', id: result.id}] : []
        }),
        findProjektById: builder.query<Projekt, string>({
            query: (id) => `projekt/${id}`,
            providesTags: (_r, _e, id) => [{type: 'Projekt', id}]
        }),
        findAllOwnedProjekt: builder.query<ProjektListResult, void | ListQueryArgs<Projekt>>({
            query: (arg) => {
                if(!arg) {
                    return "projekt/owned";
                }

                const queryString = Object
                    .entries(arg)
                    .filter(([, v]) => v)
                    .map(([key, value], paramsIndex) =>
                        `${paramsIndex === 0 ? "?" : "&"}${key}=${JSON.stringify(value)}`
                    );

                return `projekt/owned${queryString.join("")}`
            },
            providesTags: (result) => result
                ? [
                    ...result.data.map(({id}) => ({type: 'Projekt', id} as const)),
                    {type: 'Projekt', id: 'LIST'},
                ]
                : [{type: 'Projekt', id: 'LIST'}],
        }),
        findAllProjektOfMitarbeiter: builder.query<{ data: Projekt[] }, {mitarbeiterId: string}>({
            query: (arg) => {
                return `projekt/of-mitarbeiter/${arg.mitarbeiterId}`
            },
            providesTags: (result) => result
                ? [
                    ...result.data.map(({id}) => ({type: 'Projekt', id} as const)),
                    {type: 'Projekt', id: 'LIST'},
                ]
                : [{type: 'Projekt', id: 'LIST'}],
        }),
        findAllProjekt: builder.query<ProjektListResult, void | ListQueryArgs<Projekt>>({
            query: (arg) => {
                if(!arg) {
                    return "projekt";
                }

                const queryString = Object
                    .entries(arg)
                    .filter(([, v]) => v)
                    .map(([key, value], paramsIndex) =>
                        `${paramsIndex === 0 ? "?" : "&"}${key}=${JSON.stringify(value)}`
                    );

                return `projekt${queryString.join("")}`
            },
            providesTags: (result) => result
                ? [
                    ...result.data.map(({id}) => ({type: 'Projekt', id} as const)),
                    {type: 'Projekt', id: 'LIST'},
                ]
                : [{type: 'Projekt', id: 'LIST'}],
        }),
        projektAutoCompleteOptions: builder.query<ProjektAutoCompleteOptionsResult, void>({
            query: () => "projekt/autocomplete",
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'ProjektAutocompleteOption', id} as const)),
                    {type: 'ProjektAutocompleteOption', id: 'LIST'},
                ]
                : [{type: 'ProjektAutocompleteOption', id: 'LIST'}],
        }),
        // ProjektMitarbeiter
        createProjektMitarbeiter: builder.mutation<ProjektMitarbeiter, CreateProjektMitarbeiterDTO>({
            query(body) {
                return {
                    url: "projekt/mitarbeiter",
                    method: "POST",
                    body
                }
            },
            invalidatesTags: (result) => result ? [{type: 'ProjektMitarbeiter', id: 'LIST'}] : []
        }),
        updateProjektMitarbeiter: builder.mutation<ProjektMitarbeiter, UpdateProjektMitarbeiterDTO>({
            query({id,...dto}) {
                return {
                    url: `projekt/mitarbeiter/${id}`,
                    method: "PATCH",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'ProjektMitarbeiter', id: 'LIST'}, {type: 'ProjektMitarbeiter', id: result.id}] : []
        }),
        deleteProjektMitarbeiter: builder.mutation<void, string>({
            query(projektMitarbeiterId) {
                return {
                    url: `projekt/mitarbeiter/${projektMitarbeiterId}`,
                    method: "DELETE"
                }
            },
            invalidatesTags: (result) => result ? [{type: 'ProjektMitarbeiter', id: 'LIST'}] : []
        }),
        findAllProjektMitarbeiter: builder.query<ProjektMitarbeiterListResult, void | ListQueryArgs<ProjektMitarbeiter>>({
            query: (arg) => {
                if(!arg) {
                    return "projekt/mitarbeiter";
                }

                const queryString = Object
                    .entries(arg)
                    .filter(([, v]) => v)
                    .map(([key, value], paramsIndex) =>
                        `${paramsIndex === 0 ? "?" : "&"}${key}=${JSON.stringify(value)}`
                    );

                return `projekt/mitarbeiter${queryString.join("")}`
            },
            providesTags: (result) => result
                ? [
                    ...result.data.map(({id}) => ({type: 'ProjektMitarbeiter', id} as const)),
                    {type: 'ProjektMitarbeiter', id: 'LIST'},
                ]
                : [{type: 'ProjektMitarbeiter', id: 'LIST'}],
        })
    }),
})

export const {
    // Projekt
    useCreateProjektMutation,
    useFindProjektByIdQuery,
    useFindAllProjektQuery,
    useUpdateProjektMutation,
    useFindAllOwnedProjektQuery,
    useFindAllProjektOfMitarbeiterQuery,
    useProjektAutoCompleteOptionsQuery,
    // ProjektMitarbeiter
    useCreateProjektMitarbeiterMutation,
    useFindAllProjektMitarbeiterQuery,
    useDeleteProjektMitarbeiterMutation,
    useUpdateProjektMitarbeiterMutation
} = projektApi;
