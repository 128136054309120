import {Box} from "@mui/material";
import {FakturierbareAufwaendeTacho} from "../DashboardAufwandTacho";
import React from "react";
import {useFindAllAktivFakturierbarAufwandMonthQuery} from "../../aufwand/api/aufwandApi";

export const TachoMonat = () => {

    const getDaysInMonth = (year: number, month: number) => new Date(year, month, 0).getDate();
    const date = new Date();
    const month = date.getMonth() + 1
    const year = date.getFullYear()
    const monthName = date.toLocaleString('default', {month: 'long'});
    const daysInMonth = getDaysInMonth(year, month)
    const day = date.getDate();
    
    const {data} = useFindAllAktivFakturierbarAufwandMonthQuery();

    const daten = data?.map(
        ({
             mitarbeiter_nachname,
             mitarbeiter_role,
             mitarbeiter_vorname,
             mitarbeiter_monatsarbeitsstunden,
             sum
         }) => (
            {
                name: `${mitarbeiter_vorname} ${mitarbeiter_nachname} (${mitarbeiter_role})`,
                value: Math.round((sum / 36000) / (mitarbeiter_monatsarbeitsstunden * (day / daysInMonth)))
            }
        ))

    return (
        <>
            <h2 style={{marginBottom: '-50px'}}>
                {monthName} {year} ({daysInMonth} Tage)
            </h2>
            <Box display="flex" justifyContent="space-between">
                {daten?.map((chartData, chartNumber) => (
                    <FakturierbareAufwaendeTacho
                        chartData={chartData}
                        key={chartNumber}
                    />
                ))}
            </Box></>
    )
}
