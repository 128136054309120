import {createApi} from "@reduxjs/toolkit/query/react";
import {baseQueryWithReauth} from "../auth/api/api.utils";

interface DatenschutzEntry {
    id: string;
    content: string;
}

export const datenschutzApi = createApi({
    reducerPath: 'datenschutzApi',
    tagTypes: ["Datenschutz"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        getAllDatenschutz: builder.query<DatenschutzEntry[], void>({
            query: () => '/datenschutz',
        }),
        getDefaultDatenschutz: builder.query<DatenschutzEntry, void>({
            query: () => 'datenschutz/7daaabdd-61a4-414b-a222-330cea9fd1f0',
            providesTags: () => [{type: 'Datenschutz', id: '7daaabdd-61a4-414b-a222-330cea9fd1f0'}]
        }),
        getDatenschutzById: builder.query<DatenschutzEntry, string>({
            query: (id) => `datenschutz/${id}`,
        }),
        updateDefaultDatenschutz: builder.mutation<DatenschutzEntry, string>({
            query: (entry) => ({
                url: 'datenschutz/7daaabdd-61a4-414b-a222-330cea9fd1f0',
                method: 'PUT',
                body: {content: entry}
            }),
            invalidatesTags: (result) => result ? [{type: 'Datenschutz', id: '7daaabdd-61a4-414b-a222-330cea9fd1f0'}] : []
        }),
        updateDatenschutzById: builder.mutation<void, DatenschutzEntry>({
            query: ({id, ...rest}) => ({
                url: `datenschutz/${id}`,
                method: 'PUT',
                body: rest,
            })
        })
    })
})

export const {
    useGetAllDatenschutzQuery,
    useGetDefaultDatenschutzQuery,
    useGetDatenschutzByIdQuery,
    useUpdateDefaultDatenschutzMutation
} = datenschutzApi;
