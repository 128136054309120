import React from "react";
import {useFindKundeByIdQuery} from "../api/kundeApi";
import {Card, CardContent, Popover, Stack, Typography} from "@mui/material";
import {TextDisplay} from "../../../components/TextDisplay/TextDisplay";
import {InfoOutlined} from "@mui/icons-material";
import {formatKundeAdresse} from "../../../components/formatUtils";

export function KundeDisplay({id, label}: { id: string; label?: string }) {
    const {data: kunde} = useFindKundeByIdQuery(id);

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <div>
            <Typography
                variant="caption"
                color="textSecondary"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {label}
                <Typography variant="body2" color="textPrimary">
                    {kunde?.organisation}
                    <InfoOutlined
                        fontSize="inherit"
                        color="secondary"
                        sx={{
                            textAlign: "center",
                            ml: 0.5
                        }}
                    />
                </Typography>
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {kunde &&
                    <Card>
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack direction="row" spacing={4}>
                                    <TextDisplay
                                        label="Organisation"
                                        value={kunde.organisation}
                                    />
                                    <TextDisplay
                                        label="Operativer Ansprechpartner"
                                        value={kunde.operativerAnsprechpartner}
                                    />
                                </Stack>
                                <TextDisplay
                                    label="Adresse"
                                    value={formatKundeAdresse(kunde)}
                                />
                            </Stack>
                        </CardContent>
                    </Card>
                }
            </Popover>
        </div>
    )
}
