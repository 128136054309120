import React, {FC} from "react";
import {useGetDefaultDatenschutzQuery} from "./datenschutzApi";
import {Card, CardContent, LinearProgress} from "@mui/material";
import {DatenschutzView} from "./DatenschutzView";

export const DatenschutzContainer: FC = () => {
    const {data: datenschutz, isFetching} = useGetDefaultDatenschutzQuery();

    return (
        <Card>
            <CardContent>
                {(isFetching || datenschutz === undefined)
                    ? <LinearProgress/>
                    : <DatenschutzView htmlString={datenschutz.content} isEditable={true}/>
                }
            </CardContent>
        </Card>
    )
}