import React, {FC} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {DashboardProjects} from "./DashboardProjects";
import {DashboardAufwaendeStats} from "./DashboardAufwaendeStats";
import {DashboardAufwaendeQuote} from "./DashboardAufwaendeQuote";
import {useMitarbeiterRole} from "../auth/api/auth.hook";
import {MitarbeiterRole} from "../auth/api/types";

export const Dashboard: FC = () => {

    const loggedInMitarbeiterRole = useMitarbeiterRole();

    return (
        <Box>
            <Typography variant="h4" sx={{mb: 3}}>Dashboard</Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <DashboardProjects/>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <DashboardAufwaendeStats/>
                </Grid>
                {(loggedInMitarbeiterRole === MitarbeiterRole.ADMIN) &&
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <DashboardAufwaendeQuote/>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}
