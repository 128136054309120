import React, {FC} from "react";
import {CreateProjektDTO, Projekt, RechnungsZyklus, UpdateProjektDTO} from "../api/projekt.types";
import {useCreateProjektMutation, useUpdateProjektMutation} from "../api/projektApi";
import * as yup from "yup";
import {BeteiligteMitarbeiterFormElement} from "./BeteiligteMitarbeiterFormElement";
import {ProjektStammdatenFormElement} from "./ProjektStammdatenFormElement";
import {Divider} from "@mui/material";
import {FormikDialogForm} from "../../../components/formikDialogForm/components/FormikDialogForm";
import {DialogMode} from "../../../components/formikDialogForm/types";
import {CreateProjektMitarbeiterDTO} from "../api/projektMitarbeiter.types";

const initialCreateProjektDTO: CreateProjektDTO = {
    name: "",
    istPauschal: false,
    kundeId: "",
    kurzbeschreibung: null,
    projektLeiterId: "",
    rechnungszyklus: RechnungsZyklus.MONATLICH,
    projektLeiterStundenSatzInEuroCent: 0,
    projektMitarbeiter: [],
    bemerkung: '',
}

const projektValidationSchema = yup.object({
    name: yup.string().required("erforderlich"),
    rechnungszyklus: yup.string().required("erforderlich"),
    istPauschal: yup.boolean(),
    kurzbeschreibung: yup.string().nullable(),
    kundeId: yup.string().required("erforderlich"),
    projektLeiterId: yup.string().required("erforderlich"),
    projektLeiterStundenSatzInEuroCent: yup.number().test(
        "pos wenn pauschal",
        "Muss größer 0 sein",
        function (value) {
            const istPauschal = this.parent.istPauschal;
            const projektLeiterStundenSatzInEuroCent = value as number;
            if (istPauschal) {
                return true;
            } else {
                return projektLeiterStundenSatzInEuroCent > 0.01;
            }

        }),
    projektMitarbeiter: yup.array().of(
        yup.object().shape({
            mitarbeiterId: yup.string().required("erforderlich"),
            stundenSatzInEuroCent: yup.number().required("erforderlich")
        })
    ).test(
        "pos wenn pauschal",
        "Muss größer 0 sein",
        function (value) {
            const istPauschal: boolean = this.parent.istPauschal;
            const projektMitarbeiter = value as unknown as CreateProjektMitarbeiterDTO[];
            if(!istPauschal) {
                let brokenIndex: null | number = null;
                const hasUnderPaidMitarbeiter = projektMitarbeiter.some(
                    (m, i) => {
                        brokenIndex = i;
                        return m.stundenSatzInEuroCent < 0.01
                    }
                );
                if(hasUnderPaidMitarbeiter && brokenIndex !== null) {
                    return this.createError({
                        path: `${this.path}[${brokenIndex}].stundenSatzInEuroCent`,
                        message: "Muss größer 0 sein"
                    })
                }
            }
            return true;
        })
});

export const CreateProjektDialogForm: FC = () => {

    const [createProjekt, {isLoading: createProjektLoading}] = useCreateProjektMutation();
    const [updateProjekt, {isLoading: updateProjektLoading}] = useUpdateProjektMutation();

    const loading = createProjektLoading || updateProjektLoading;

    const saveProjekt = (dto: CreateProjektDTO | UpdateProjektDTO, mode: DialogMode): Promise<Projekt> => {
        let savePromise;
        if (mode === "create") {
            savePromise = createProjekt(dto as CreateProjektDTO);
        } else {
            savePromise = updateProjekt(dto as UpdateProjektDTO);
        }
        return savePromise.unwrap()
    };

    return (
        <FormikDialogForm
            dialogId={"projekt"}
            title={(mode) => mode === "create" ? "Neues Projekt" : "Projekt bearbeiten"}
            onSubmit={saveProjekt}
            creationInitialValues={initialCreateProjektDTO}
            validationSchema={projektValidationSchema}
            loading={loading}
            form={
                <React.Fragment>
                    <ProjektStammdatenFormElement/>
                    <Divider sx={{my: 3}}/>
                    <BeteiligteMitarbeiterFormElement/>
                </React.Fragment>
            }
        />
    );
}
