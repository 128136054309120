import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {RootState} from "../../../state/store";
import {authApi} from "./authApi";
import {MitarbeiterRole} from "./types";

type AuthState = {
    userId: string | null;
    role: MitarbeiterRole | null;
    accessToken: string | null;
    refreshToken: string | null;
}

const setAuthToLocalStorage = (accessToken: string, refreshToken: string, userId: string, role: MitarbeiterRole) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refreshToken", refreshToken);
    localStorage.setItem("userId", userId);
    localStorage.setItem("role", role);
};

const clearAuthLocalStorage = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("userId");
    localStorage.removeItem("role");
}

const slice = createSlice({
    name: 'auth',
    initialState: {
        userId: localStorage.getItem("userId"),
        role: localStorage.getItem("role") as MitarbeiterRole | null,
        accessToken: localStorage.getItem("accessToken"),
        refreshToken: localStorage.getItem("refreshToken")
    } as AuthState,
    reducers: {
        setCredentials: (state, action: PayloadAction<{ accessToken: string; refreshToken: string; userId: string; role: MitarbeiterRole }>) => {
            setAuthToLocalStorage(
                action.payload.accessToken,
                action.payload.refreshToken,
                action.payload.userId,
                action.payload.role
            );
            state.userId = action.payload.userId;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
            state.role = action.payload.role;
        },
        logOut: (state) => {
            clearAuthLocalStorage();
            state.accessToken = null;
            state.refreshToken = null;
            state.userId = null;
            state.role = null;
        }
    },
    extraReducers: (builder) =>
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, (state, action) => {
                setAuthToLocalStorage(
                    action.payload.access_token,
                    action.payload.refresh_token,
                    action.payload.user_id,
                    action.payload.role
                );
                state.accessToken = action.payload.access_token;
                state.refreshToken = action.payload.refresh_token;
                state.userId = action.payload.user_id;
                state.role = action.payload.role;
            })
})

export const {logOut, setCredentials} = slice.actions

export default slice.reducer

export const selectCurrentAccessToken = (state: RootState) => state.auth.accessToken;

export const selectHasAccessToken = (state: RootState) => Boolean(state.auth.accessToken);

export const selectMitarbeiterRole = (state: RootState) => state.auth.role;

export const selectMitarbeiterId = (state: RootState) => state.auth.userId;
