type PasswordBarColor = 'error' | 'success' | 'warning';

const MIN_ERROR_SCORE = 40;
const MIN_WARNING_SCORE = 60;

export function getScoreColor(score: number): PasswordBarColor {
    switch (true) {
        case (score <= MIN_ERROR_SCORE):
            return "error"
        case (score < MIN_WARNING_SCORE):
            return "warning";
        default:
            return "success"
    }
}
