import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from "@mui/material/CssBaseline";
import {LicenseInfo} from '@mui/x-data-grid-pro';
import {Provider as StoreProvider} from "react-redux";
import {store} from "./state/store";
import {theme} from "./theme";

LicenseInfo.setLicenseKey(
    '5cf0cc25ef4758e5cea5256a5f554e1eT1JERVI6MzIyMjIsRVhQSVJZPTE2Njc5OTg4MzMwMDAsS0VZVkVSU0lPTj0x'
);

ReactDOM.render(
    <React.StrictMode>
        <StoreProvider store={store}>
            <ThemeProvider theme={theme}>
                <CssBaseline/>
                    <App/>
            </ThemeProvider>
        </StoreProvider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
