import React, {FC} from "react";
import {Box, Grid, Tooltip} from "@mui/material";
import {Aufwand, CreateAufwandDTO, UpdateAufwandDTO} from "../api/types";
import {useCreateAufwandMutation, useUpdateAufwandMutation} from "../api/aufwandApi";
import * as yup from "yup";
import {FormikDateField} from "../../../components/FormikDateField";
import {FormikProjektAutocomplete} from "../../projekt/components/projektAutocomplete/FormikMitarbeiterAutocomplete";
import {
    FormikMitarbeiterAutocomplete
} from "../../mitarbeiter/components/mitarbeiterAutocomplete/FormikMitarbeiterAutocomplete";
import {FormikCheckboxField} from "../../../components/FormikCheckboxField";
import {FormikDialogForm} from "../../../components/formikDialogForm/components/FormikDialogForm";
import {DialogMode} from "../../../components/formikDialogForm/types";
import {FormikTextField} from "../../../components/formikInputs/FormikTextField";
import {useMitarbeiterRole} from "../../auth/api/auth.hook";
import {MitarbeiterRole} from "../../auth/api/types";
import {FormikDurationInput} from "../../../components/formikInputs/FormikDurationInput";

export const createAufwandDTO: CreateAufwandDTO = {
    ticketId: null,
    dauerInMillis: 0,
    datum: new Date().toISOString(),
    istFakturierbar: true,
    mitarbeiterId: "",
    projektId: "",
    rechnungsnummer: null,
    taetigkeit: ""
}

export const aufwandValidationSchema = yup.object({
    ticketId: yup.string().nullable().max(24, "Ticked ID darf nicht länger als 24 Zeichen sein."),
    dauerInMillis: yup.number().test({
        name: "dauerInMillis",
        test: function (value) {
            if (value && value % 900000 !== 0) {
                return this.createError({
                    path: this.path,
                    message: "bitte Viertel Stunden"
                })
            }
            if (value === 0) {
                return this.createError({
                    path: this.path,
                    message: "Dauer muss min. 1/4 Std. sein."
                })
            }

            return true;
        }
    }),
    datum: yup.string().required("required"),
    mitarbeiterId: yup.string().required("required"),
    projektId: yup.string().required("required"),
    rechnungsnummer: yup.string().nullable(),
    taetigkeit: yup.string().required("required")
});

export const AufwandDialogForm: FC<{ forExtern?: boolean }> = ({forExtern}) => {

    const mitarbeiterRole = useMitarbeiterRole();

    const [createAufwand, {isLoading: createAufwandLoading}] = useCreateAufwandMutation();
    const [updateAufwand, {isLoading: updateAufwandLoading}] = useUpdateAufwandMutation();

    const loading = createAufwandLoading || updateAufwandLoading;

    const saveAufwand = (dto: CreateAufwandDTO | UpdateAufwandDTO, mode: DialogMode): Promise<Aufwand> => {
        let savePromise;

        if (mode === "create") {
            savePromise = createAufwand(dto as CreateAufwandDTO);
        } else {
            savePromise = updateAufwand(dto as UpdateAufwandDTO);
        }
        return savePromise.unwrap()
    };

    return (
        <FormikDialogForm
            dialogId={"aufwand"}
            title={(mode) => mode === "create" ? "Neuer Aufwand" : "Aufwand bearbeiten"}
            onSubmit={saveAufwand}
            creationInitialValues={createAufwandDTO}
            validationSchema={aufwandValidationSchema}
            loading={loading}
            form={
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                    <FormikTextField
                    name="taetigkeit"
                    textFieldProps={{
                        label: "Tätigkeit",
                        fullWidth: true,
                        required: true,
                        autoFocus: true
                    }}
                    />
                    </Grid>
                    <Grid item xs={6}>
                        <FormikDateField name={"datum"} label={"Datum"}/>
                    </Grid>
                    <Grid item xs={6}>
                        {/* <FormikNumberField
                            name="dauerInMillis"
                            textFieldProps={{label: "Dauer", fullWidth: true}}
                        />*/}
                        <FormikDurationInput
                            name="dauerInMillis"
                            textFieldProps={{label: "Dauer in Stunden", fullWidth: true, required: true}}
                        />
                    </Grid>
                    {!forExtern &&
                        <React.Fragment>
                            <Grid item xs={6}>
                                <FormikProjektAutocomplete name="projektId" label="Projekt" required/>
                            </Grid>
                            <Grid item xs={6}>
                                <FormikMitarbeiterAutocomplete
                                    disabled={mitarbeiterRole !== MitarbeiterRole.ADMIN}
                                    name="mitarbeiterId"
                                    label="Mitarbeiter"
                                />
                            </Grid>
                        </React.Fragment>}
                    <Grid item xs={6} sx={{display: "flex", alignItems: "center"}}>
                        <Tooltip title="Fakturierbar = Der Aufwand gehört zu einem bezahlten Kundenprojekt und wird dem Kunden in Rechnung gestellt.">
                        <Box pl={2}>
                            <FormikCheckboxField name={"istFakturierbar"} label={"Fakturierbar"}/>
                        </Box>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={6}>
                        <FormikTextField
                            name="ticketId"
                            textFieldProps={{label: "Ticket ID", fullWidth: true}}
                        />
                    </Grid>
                </Grid>
            }
        />
    );
}
