import React, {FC, useMemo} from "react";
import {GridColDef, GridFilterItem} from "@mui/x-data-grid-pro";
import {AppDataGrid} from "../../../components/table/AppDataGrid";
import {useFindAllProjektQuery} from "../api/projektApi";
import {KundeLink} from "../../kunde/components/KundeLink";
import {MitarbeiterLink} from "../../mitarbeiter/components/MitarbeiterLink";
import {ApiFilters, useTableApiQueryFilterState} from "../../../components/table/apiQueryFilterState.hook";
import {Projekt} from "../api/projekt.types";
import {PaginationState} from "../../aufwand/api/aufwandApi";
import _ from "lodash";
import {formatDate} from "../../../components/formatUtils/dateFormatUtils";
import {useNavigate} from "react-router-dom";

const projektTableColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 3
    },
    {
        field: 'kurzbeschreibung',
        headerName: 'Kurzbeschreibung',
        flex: 4,
        type: "string"
    },
    {
        field: 'kundeId',
        headerName: 'Kunde',
        flex: 2,
        renderCell: (params) => <KundeLink id={params.value}/>
    },
    {
        field: 'projektLeiterId',
        headerName: 'Projektleiter',
        flex: 2,
        renderCell: (params) => params.value && <MitarbeiterLink id={params.value}/>
    },
    {
        field: 'rechnungszyklus',
        headerName: 'Rechnungszyklus',
        flex: 2,
        type: "number"
    },
    {
        field: 'istPauschal',
        headerName: 'Pauschal',
        flex: 1,
        type: "boolean"
    },
    {
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

interface ProjektTableProps {
    initialFilterItems?: ApiFilters;
}

interface SortState {
    [key: string]: "asc" | "desc"
}

export const ProjektTable: FC<ProjektTableProps> = ({initialFilterItems}) => {

    const navigate = useNavigate();

    const columns = useMemo(() => {
        const initialFilterKeys: string[] = initialFilterItems ? Object.keys(initialFilterItems) : [];
        return projektTableColumns.map(ptc => !initialFilterKeys.includes(ptc.field) ? ({...ptc}) : ({
            ...ptc,
            hide: true
        }))
    }, [initialFilterItems]);

    const {
        gridApiRef,
        apiFilters,
        onFilterModelChange
    } = useTableApiQueryFilterState<Projekt>(initialFilterItems);

    const initialFiltersMapped: GridFilterItem[] = useMemo(() => initialFilterItems
            ? Object
                .entries(initialFilterItems)
                .map(([key, value]) => ({
                    columnField: key,
                    id: key,
                    value: value.value,
                    operatorValue: value.operator
                }))
            : []
        , [initialFilterItems]
    );

    console.log("init filter", initialFiltersMapped);

    const [paginationState, setPaginationState] = React.useState<PaginationState>({
        page: 0,
        pageSize: 25,
    });

    const [sortState, setSortState] = React.useState<SortState>();

    const {data, isFetching} = useFindAllProjektQuery({
        filter: apiFilters,
        pagination: paginationState,
        sort: sortState
    });

    const projekte = data?.data ?? [];

    return (
        <AppDataGrid
            onRowClick={params => navigate(`/intern/projekte/${params.id}`)}
            apiRef={gridApiRef}
            checkboxSelection={false}
            loading={isFetching}
            columns={columns}
            rows={projekte}
            filterMode="server"
            initialState={{
                filter: {
                    filterModel: {
                        items: initialFilterItems ? initialFiltersMapped : []
                    }
                }
            }}
            onFilterModelChange={onFilterModelChange}
            pagination
            paginationMode="server"
            rowCount={data?.pagination.count ?? 0}
            page={paginationState.page}
            pageSize={paginationState.pageSize}
            rowsPerPageOptions={[5, 25, 50, 100, 200]}
            onPageChange={(page) => setPaginationState((prev) => ({...prev, page}))}
            onPageSizeChange={(pageSize) =>
                setPaginationState((prev) => ({...prev, pageSize}))
            }
            sortingMode="server"
            onSortModelChange={model =>
                setSortState(model.reduce(
                        ((acc, cur) => {
                            acc[cur.field] = cur.sort ? _.upperCase(cur.sort) as "asc" | "desc" : "asc";
                            return acc;
                        }),
                        {} as SortState
                    )
                )
            }
        />
    );
};
