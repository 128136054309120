import {DataGridPro, DataGridProProps} from "@mui/x-data-grid-pro";
import {DataGridLoadingOverlay} from "./DataGridLoadingOverlay";
import React from "react";
import {Paper} from "@mui/material";
import {useNavigate} from "react-router-dom";

interface AppDataGridProps extends DataGridProProps {}

export function AppDataGrid(props: AppDataGridProps) {
    const navigate = useNavigate();
    return (
        <Paper sx={props.style}>
            <DataGridPro
                onRowClick={({id}) => navigate(id as string)}
                style={{height: 600}}
                components={{LoadingOverlay: DataGridLoadingOverlay, ...props.components}}
                {...props as DataGridProProps}
            />
        </Paper>
    )
}
