import React, {FC} from "react";
import {Box, TextField, TextFieldProps, Tooltip} from "@mui/material";
import {useField} from "formik";
import {InfoOutlined} from "@mui/icons-material";

interface FormikTextFieldProps {
    textFieldProps?: TextFieldProps,
    name: string;
    toolTip?: string;
}

export const FormikTextField: FC<FormikTextFieldProps> = ({name, toolTip, textFieldProps}) => {
    const [, meta, helpers] = useField<string | undefined | null>(name);

    // if(name === "email") {
    //     console.log("meta", meta.value);
    // }

    const hasError = Boolean(meta.touched && meta.error);

    return (
        <Box position="relative">
            <TextField
                variant="outlined"
                value={meta.value ?? ""}
                error={hasError}
                helperText={hasError && meta.error}
                onChange={e => helpers.setValue(e.target.value)}
                onClick={(e)=>{e.stopPropagation()}}
                {...textFieldProps}
            />
            {toolTip &&
                <Tooltip title={toolTip} placement="left-start">
                    <InfoOutlined
                        sx={{
                            color: "warning.light",
                            bgcolor: "white",
                            position: "absolute",
                            top: -10,
                            right: -10
                        }}
                    />
                </Tooltip>}
        </Box>

    )
}
