import {configureStore, ThunkAction, Action} from '@reduxjs/toolkit';
import {mitarbeiterApi} from "../features/mitarbeiter/api/mitarbeiterApi";
import errorSlice from "../components/error/errorSlice";
import {kundeApi} from "../features/kunde/api/kundeApi";
import {projektApi} from "../features/projekt/api/projektApi";
import {aufwandApi} from "../features/aufwand/api/aufwandApi";
import authSlice from "../features/auth/api/authSlice";
import {authApi} from "../features/auth/api/authApi";
import {datenschutzApi} from "../features/datenschutz/datenschutzApi";

export const store = configureStore({
    reducer: {
        [mitarbeiterApi.reducerPath]: mitarbeiterApi.reducer,
        [kundeApi.reducerPath]: kundeApi.reducer,
        [projektApi.reducerPath]: projektApi.reducer,
        [aufwandApi.reducerPath]: aufwandApi.reducer,
        [authApi.reducerPath]: aufwandApi.reducer,
        auth: authSlice,
        errors: errorSlice,
        [datenschutzApi.reducerPath]: datenschutzApi.reducer
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(mitarbeiterApi.middleware)
            .concat(projektApi.middleware)
            .concat(aufwandApi.middleware)
            .concat(kundeApi.middleware)
            .concat(authApi.middleware as any) // Todo Types Daniel :/ peil ich nicht,
            .concat(datenschutzApi.middleware)
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
