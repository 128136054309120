import React, {FC} from "react";
import ReactQuill from "react-quill";
import {useField} from "formik";
import 'react-quill/dist/quill.snow.css';

interface FormikWysiwygProps {
    fieldName: string;
}

export const FormikQuill: FC<FormikWysiwygProps> = (props) => {

    const [, {value}, helpers] = useField<string>(props.fieldName);

    return (
        <ReactQuill
            value={value}
            onChange={(content => helpers.setValue(content))}
        />
    )
}