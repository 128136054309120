import React, {FC} from "react";
import {Box, TextField, TextFieldProps, Tooltip} from "@mui/material";
import {useField} from "formik";
import {InfoOutlined} from "@mui/icons-material";

interface FormikNumberFieldProps {
    textFieldProps?: TextFieldProps,
    name: string;
    toolTip?: string;
}

export const FormikNumberField: FC<FormikNumberFieldProps> = ({name, toolTip, textFieldProps}) => {
    const [, meta, helpers] = useField<number | undefined | null>(name);

    const hasError = Boolean(meta.touched && meta.error);

    return (
        <Box position="relative">
            <TextField
                type="number"
                variant="outlined"
                // value={meta.value ?? ""}
                value={meta.value ?? undefined}
                error={hasError}
                helperText={hasError && meta.error}
                onChange={e => helpers.setValue(Number(e.target.value))}
                {...textFieldProps}
            />
            {toolTip &&
            <Tooltip title={toolTip} placement="left-start">
                <InfoOutlined
                    sx={{
                        color: "warning.light",
                        bgcolor: "white",
                        position: "absolute",
                        top: -10,
                        right: -10
                    }}
                />
            </Tooltip>}
        </Box>
    )
}
