import React, {FC} from "react";
import {useField} from "formik";
import {Checkbox, FormControlLabel} from "@mui/material";

export const FormikCheckboxField: FC<{ name: string; label: string }> = (props) => {
    const [, meta, helpers] = useField<boolean | undefined | null>(props.name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(event.target.checked);
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    checked={Boolean(meta.value)}
                    onChange={handleChange}
                />
            }
            label={props.label}
        />
    )
}
