import React, {FC} from "react";
import {useField} from "formik";
import {ProjektAutocomplete} from "./ProjektAutocomplete";

interface FormikProjektAutocompleteProps {
    name: string,
    label: string
    required?: boolean;
}

export const FormikProjektAutocomplete: FC<FormikProjektAutocompleteProps> = ({name, label, required}) => {
    const [, meta, helpers] = useField<string | null | undefined>(name);

    return (
        <ProjektAutocomplete
            value={meta.value}
            onChange={helpers.setValue}
            label={label}
            error={meta.touched && meta.error ? meta.error : undefined}
            required={required}
        />
    )
}
