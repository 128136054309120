import React, {FC} from "react";
import {Projekt} from "../../api/projekt.types";
import {ProjektStammdatenCard} from "./ProjektStammdatenCard";
import {ProjektMitarbeiterCard} from "./projektMitarbeiterCard/ProjektMitarbeiterCard";
import {Grid} from "@mui/material";

export const ProjektDetails: FC<{ projekt: Projekt }> = ({projekt}) => (
    <Grid container spacing={2} sx={{width: "50%"}}>
        <Grid item md={12} lg={12}>
            <ProjektStammdatenCard projekt={projekt}/>
        </Grid>
        <Grid item md={12} lg={12}>
            <ProjektMitarbeiterCard projekt={projekt}/>
        </Grid>
    </Grid>
)
