import React from 'react';
import {ErrorSnackbar} from "./components/error/ErrorSnackbar";
import {LocalizationProvider} from "@mui/lab";
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {useAppSelector} from "./state/redux.hooks";
import {selectHasAccessToken} from "./features/auth/api/authSlice";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {InternApp} from "./features/internApp/InternApp";
import {LoginScreen} from "./features/login/LoginScreen";
import {ExternApp} from "./externApp/ExternApp";
import {BaseRedirect} from "./components/BaseRedirect";
import deLocale from 'date-fns/locale/de';
import {Box} from "@mui/material";

function App() {
    const hasAccessToken = useAppSelector(selectHasAccessToken);

    return (
        <Box>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={deLocale}>
                <ErrorSnackbar/>
                <BrowserRouter>
                    <Routes>
                        <Route path="/" element={<BaseRedirect/>}/>
                        <Route path="/login" element={<LoginScreen/>}/>
                        <Route path="/password-reset" element={<div>pw reset</div>}/>
                        <Route
                            path="/intern/*"
                            element={<InternApp hasAccessToken={hasAccessToken}/>}
                        />
                        <Route
                            path="/extern/*"
                            element={<ExternApp hasAccessToken={hasAccessToken}/>}
                        />
                    </Routes>
                </BrowserRouter>
            </LocalizationProvider>
        </Box>
    );
}

export default App;
