import React, {FC} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    LinearProgress,
    Link
} from "@mui/material";
import {useGetDefaultDatenschutzQuery} from "./datenschutzApi";
import {DatenschutzView} from "./DatenschutzView";

export const DatenschutzLoginDialog: FC = () => {

    const {data: datenschutz, isFetching} = useGetDefaultDatenschutzQuery();


    const [open, setOpen] = React.useState(false);

    const handleClickOpen = (): void => {
        setOpen(true);
    };

    const handleClose = (): void => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Link
                sx={{cursor: "pointer"}}
                onClick={handleClickOpen}
                underline='none'
            >
                Datenschutz
            </Link>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'xl'}
            >
                <DialogContent>
                    {(isFetching || datenschutz === undefined)
                        ? <LinearProgress/>
                        : <DatenschutzView htmlString={datenschutz.content} isEditable={false}/>
                    }
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Verstanden</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    )
}
