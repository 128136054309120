import React, {FC} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    TextField
} from "@mui/material";
import {useDeleteManyAufwaendeMutation} from "../api/aufwandApi";

interface DeleteAufwandDialogProps {
    open: boolean,
    aufwaendeIds: string[];
    onClose: () => void;
}

export const DeleteAufandDialog: FC<DeleteAufwandDialogProps> = ({open, aufwaendeIds, onClose}) => {

    const [deleteAufwaende, {isLoading: deleteAufwaendeLoading}] = useDeleteManyAufwaendeMutation();

    const handleDeleteAufwaende = () => {
        deleteAufwaende({aufwaendeIds})
            .then(onClose)
            .catch(() => console.log("delete aufwaende error"))
    };

    const [confirmation, setConfirmation] = React.useState("");

    const handleClose = () => {
        setConfirmation("");
        onClose();
    }

    return (
        <Dialog open={open}>
            <DialogTitle>
                Aufwand Löschen.
            </DialogTitle>
            <DialogContent sx={{position: "relative"}}>
                {deleteAufwaendeLoading && <LinearProgress sx={{width: "100%"}}/>}
                <DialogContentText sx={{mb: 3}}>
                    Sind Sie sicher das Sie {aufwaendeIds.length} ausgewählte Aufwände löschen wollen.
                </DialogContentText>
                <TextField
                    fullWidth
                    placeholder='Tippen Sie "Löschen" um fortzufahren'
                    onChange={(e) => setConfirmation(e.target.value)}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{color: "text.secondary"}}
                    onClick={handleClose}
                >
                    Abbrechen
                </Button>
                <Button
                    disabled={confirmation !== "Löschen"}
                    onClick={handleDeleteAufwaende}
                    color="error"
                >
                    Löschen
                </Button>
            </DialogActions>
        </Dialog>
    )
}
