import React, {FC} from "react";
import {useFindProjektByIdQuery} from "../../api/projektApi";
import {Box, Grid, LinearProgress, Typography} from "@mui/material";
import {ProjektStammdatenCard} from "./ProjektStammdatenCard";
import {PaginationState, useFindAllOwnedAufwandQuery} from "../../../aufwand/api/aufwandApi";
import {AppDataGrid} from "../../../../components/table/AppDataGrid";
import {
    GridActionsCellItem,
    GridActionsColDef,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport
} from "@mui/x-data-grid-pro";
import {formatDate} from "../../../../components/formatUtils/dateFormatUtils";
import {Aufwand} from "../../../aufwand/api/types";
import {Edit} from "@mui/icons-material";
import {useNavigateDialogForm} from "../../../../components/formikDialogForm/dialogForm.hook";
import _ from "lodash";

const aufwandTableColumns: GridColDef[] = [
    {
        field: 'taetigkeit',
        headerName: 'Taetigkeit',
        flex: 3,
    },
    {
        field: 'ticketId',
        headerName: 'Ticket Id',
        flex: 2,
    },
    {
        field: 'dauerInMillis',
        headerName: 'Dauer in Std.',
        flex: 1,
        type: "number",
        valueFormatter: params => (params.value as number / 3600000).toLocaleString("de")
    },
    {
        field: 'datum',
        headerName: 'Datum',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    },
];

export const ExternalProjektDetails: FC<{ id: string }> = ({id}) => {

    const navigateToEditAufwand = useNavigateDialogForm<Aufwand>("aufwand", "edit");

    const columns = React.useMemo(() => [
            ...aufwandTableColumns,
            {
                field: "edit",
                type: "actions",
                getActions: (params) => [
                    <GridActionsCellItem
                        icon={<Edit/>}
                        label="Edit"
                        onClick={() => navigateToEditAufwand(params.row as Aufwand)}
                    />
                ]
            } as GridActionsColDef
        ],
        [navigateToEditAufwand]
    );

    // @ts-ignore
    const [sortState, setSortState] = React.useState<SortState>({datum: "DESC"});

    interface SortState {
        [key: string]: "asc" | "desc"
    }

    const [paginationState, setPaginationState] = React.useState<PaginationState>({
        page: 0,
        pageSize: 25,
    });

    const {data: projekt, isFetching} = useFindProjektByIdQuery(id);
    const {data: aufwaende, isFetching: isFetchingAufwaende} = useFindAllOwnedAufwandQuery({
        projektId: id,
        sort: sortState,
        pagination: paginationState
    });

    return (
        <Box height="100%" display="flex" flexDirection="column" position="relative">
            {isFetching &&
                <LinearProgress sx={{position: "absolute", top: 0, width: "100%"}}/>
            }
            {projekt &&
                <React.Fragment>
                    <Typography variant={"h5"} m={1} color="textSecondary">
                        Projekt ({projekt?.name})
                    </Typography>
                    <ProjektStammdatenCard
                        projekt={projekt}
                        withKundePopover
                    />
                </React.Fragment>
            }
            <Typography variant={"h5"} mx={1} mt={2} mb={1} color="textSecondary">
                Aufwände
            </Typography>
            <AppDataGrid
                style={{flexGrow: 1}}
                loading={isFetchingAufwaende}
                onRowClick={() => {
                }}
                pagination
                paginationMode="server"
                rowCount={aufwaende?.pagination.count ?? 0}
                page={paginationState.page}
                pageSize={paginationState.pageSize}
                rowsPerPageOptions={[5, 25, 50, 100, 200]}
                onPageChange={(page) => setPaginationState((prev) => ({...prev, page}))}
                onPageSizeChange={(pageSize) =>
                    setPaginationState((prev) => ({...prev, pageSize}))
                }

                columns={columns}
                rows={aufwaende?.data ?? []}
                sortingMode="server"
                onSortModelChange={model =>
                    setSortState(model.reduce(
                            ((acc, cur) => {
                                acc[cur.field] = cur.sort ? _.upperCase(cur.sort) as "asc" | "desc" : "asc";
                                return acc;
                            }),
                            {} as SortState
                        )
                    )
                }
                initialState={{
                    sorting: {sortModel: [{field: "datum", sort: "desc"}]}
                }}
                components={{
                    Toolbar: () => <GridToolbarContainer>
                        <Grid container justifyContent="flex-end">
                            <GridToolbarExport
                                variant="contained"
                                color="secondary"
                                csvOptions={{
                                    includeHeaders: true,
                                    delimiter: ";",
                                    fileName: "EffortLog-Aufwände",
                                    utf8WithBom: true
                                }}
                            />
                        </Grid>
                    </GridToolbarContainer>
                }}
                checkboxSelection
            />
        </Box>
    )
}
