import React from "react";
import {Fade, Link as MuiLink, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {useFindProjektByIdQuery} from "../api/projektApi";

export function ProjektLink({id, label}: { id: string; label?: string }) {
    const {data: projekt} = useFindProjektByIdQuery(id);

    const linkElement = (
        <Fade in={Boolean(projekt)}>
            <MuiLink
                component={RouterLink}
                to={`/intern/projekte/${id}`}
                color="secondary"
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            >
                {projekt?.name}
            </MuiLink>
        </Fade>
    );

    return (
        label
            ? (
                <Typography variant="caption" color="textSecondary">
                    {label}
                    <Typography variant="body1" color="textPrimary">{linkElement}</Typography>
                </Typography>
            )
            : linkElement
    )
}
