import {AssignmentInd as KundenIcon} from "@mui/icons-material";
import {KundeListScreen} from "./list/KundeListScreen";
import {KundeViewScreen} from "./view/KundeViewScreen";
import {KundeDialogForm} from "./save/KundeDialogForm";
import React from "react";
import {AppFeature} from "../../components/dcbApp/types";

export const kundeFeature: AppFeature = {
    path: "kunde",
    label: "Kunden",
    icon: <KundenIcon sx={{color: "secondary.main"}}/>,
    list: <KundeListScreen/>,
    view: <KundeViewScreen/>,
    dialogs: [<KundeDialogForm/>]
}
