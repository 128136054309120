import React, {FC} from "react";
import {Card, CardContent, Stack} from "@mui/material";
import {Kunde} from "../api/types";
import {CardTitleWithEdit} from "../../../components/cardTitleWithEdit/CardTitleWithEdit";
import {entityToDTO} from "../../../components/editUtils";
import {useNavigateDialogForm} from "../../../components/formikDialogForm/dialogForm.hook";
import {TextDisplay} from "../../../components/TextDisplay/TextDisplay";
import {useMitarbeiterRole} from "../../auth/api/auth.hook";
import {MitarbeiterRole} from "../../auth/api/types";
import {formatKundeAdresse} from "../../../components/formatUtils";

export const KundeStammdatenCard: FC<{ kunde: Kunde }> = ({kunde}) => {

    const role = useMitarbeiterRole();
    const navigateDialog = useNavigateDialogForm("kunde", "edit", entityToDTO(kunde));

    return (
        <Card>
            <CardContent>
                <CardTitleWithEdit
                    title={"Stammdaten"}
                    onClickEdit={role === MitarbeiterRole.ADMIN ? navigateDialog : undefined}
                />
                <Stack spacing={2}>
                    <Stack direction="row" spacing={4}>
                        <TextDisplay
                            label="Organisation"
                            value={kunde.organisation}
                        />
                        <TextDisplay
                            label="Operativer Ansprechpartner"
                            value={kunde.operativerAnsprechpartner}
                        />
                    </Stack>
                    <TextDisplay
                        label="Adresse"
                        value={formatKundeAdresse(kunde)}
                    />
                </Stack>
            </CardContent>
        </Card>
    );
};
