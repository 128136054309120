import React, {FC} from "react";
import {useGetAufwandStatisticQuery} from "../aufwand/api/aufwandApi";
import {add, endOfMonth, format, startOfMonth, sub} from "date-fns";
import {
    Box,
    Card,
    CardContent,
    Collapse,
    Fade,
    IconButton,
    LinearProgress,
    ToggleButton,
    ToggleButtonGroup,
    Typography
} from "@mui/material";
import {TransitionGroup} from 'react-transition-group';
import {ChevronLeft, ChevronRight} from "@mui/icons-material";
import {BetaChip} from "../../components/chips/BetaChip";
import {de} from "date-fns/locale";

const getInitialFrom = () => {
    return startOfMonth(new Date())
}

const getInitialTo = () => {
    return endOfMonth(new Date())
}

export const DashboardAufwaendeStats: FC = () => {

    const [einheit, setEinheit] = React.useState('Monat');

    const handleChange = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setEinheit(newAlignment);
    };

    const [from, setFrom] = React.useState<Date>(getInitialFrom);
    const [to, setTo] = React.useState<Date>(getInitialTo);

    const {data: stats, isFetching} = useGetAufwandStatisticQuery({
        from: from.toDateString(),
        to: to.toDateString()
    });

    const onClickLeft = () => {
        if (einheit === "Monat") {
            setFrom((p) => sub(p, {months: 1})); // Wo / wie wird p reingegeben
            setTo((p) => endOfMonth(sub(p, {months: 1})));
        } else {
            setFrom((p) => sub(p, {years: 1}));
            setTo((p) => endOfMonth(sub(p, {years: 1})));
        }
    }

    const onClickRight = () => {
        if (einheit === "Monat") {
            setFrom((p) => add(p, {months: 1}));
            setTo((p) => endOfMonth(add(p, {months: 1})));
        } else {
            setFrom((p) => add(p, {years: 1}));
            setTo((p) => endOfMonth(add(p, {years: 1})));
        }
    }

    const monatHatAufwaende = stats && stats?.allAufwaendeCount !== 0

    return (
        <Box sx={{position: "relative"}}>
            <Typography variant="h6" color="textSecondary">Statistik</Typography>
            <Card sx={{width: "100%", height: 400}}>
                <CardContent sx={{display: "flex"}}>
                    <Box sx={{flexGrow: 1, position: "relative"}}>
                        <TransitionGroup appear={true}>
                            <Box sx={{position: "absolute", top: 4, left: 8}}>
                                <Fade in={!monatHatAufwaende}
                                      timeout={!monatHatAufwaende ? 3000 : 300}>
                                    <Typography color="text.secondary">
                                        Bisher keine Daten vorhanden
                                    </Typography></Fade>
                            </Box>
                            {stats && monatHatAufwaende &&
                                <Collapse orientation="horizontal" timeout={1000}>
                                    <Typography
                                        noWrap
                                        sx={{color: (theme) => theme.palette.getContrastText(theme.palette.primary.contrastText)}}
                                    >
                                        Auriscon's Aufwände
                                    </Typography>
                                </Collapse>
                            }
                            {stats && monatHatAufwaende &&
                                <Collapse orientation="horizontal" timeout={1000}
                                          sx={{mb: 2}}>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            pl: 1,
                                            borderRadius: 1,
                                            width: monatHatAufwaende ? 500 : 0,
                                            height: 40,
                                            bgcolor: "primary.light",
                                            mr: 2
                                        }}>
                                        </Box>
                                        <Typography variant="h6">{stats?.allAufwaendeCount}</Typography>
                                    </Box>
                                </Collapse>
                            }
                            {stats && stats.mitarbeiterAufwaendeCount > 0 &&
                                <Collapse orientation="horizontal" timeout={1000}>
                                    <Typography
                                        noWrap
                                        sx={{color: (theme) => theme.palette.getContrastText(theme.palette.primary.contrastText)}}
                                    >
                                        Deine Aufwände 🎉
                                    </Typography>
                                </Collapse>
                            }
                            {stats && stats.mitarbeiterAufwaendeCount > 0 &&
                                <Collapse orientation="horizontal" timeout={1000}>
                                    <Box sx={{display: "flex", alignItems: "center"}}>
                                        <Box sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            /*justifyContent: "center",*/
                                            pl: 1,
                                            borderRadius: 1,
                                            width: !monatHatAufwaende ? 0 : 500 * (stats.mitarbeiterAufwaendeCount / stats.allAufwaendeCount),
                                            height: 40,
                                            bgcolor: "secondary.light",
                                            mr: 2
                                        }}>
                                        </Box>
                                        {stats.mitarbeiterAufwaendeCount > 0 &&
                                            <Typography variant="h6">{stats.mitarbeiterAufwaendeCount}</Typography>}
                                    </Box>
                                </Collapse>
                            }
                        </TransitionGroup>
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                        <ToggleButtonGroup
                            size="small"
                            color="primary"
                            value={einheit}
                            exclusive
                            sx={{height: 28}}
                            onChange={handleChange}
                        >
                            <ToggleButton size="small" value="Monat">Monat</ToggleButton>
                            <ToggleButton size="small" value="Jahr">Jahr</ToggleButton>
                        </ToggleButtonGroup>
                        <Box sx={{display: "flex", alignItems: "center"}}>
                            <IconButton size="small" onClick={onClickLeft}>
                                <ChevronLeft fontSize="small"/>
                            </IconButton>
                            <Typography>{format(from, "MMMM yyyy", {locale: de})}</Typography>
                            <IconButton size="small" onClick={onClickRight}>
                                <ChevronRight fontSize="small"/>
                            </IconButton>
                        </Box>
                        {isFetching && <LinearProgress sx={{width: "69%", px: 2}}/>}
                    </Box>
                </CardContent>
                <BetaChip size="small" sx={{position: "absolute", bottom: 8, right: 8}}/>
            </Card>
        </Box>
    )
}
