import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import {AppDrawer, AppDrawerProps} from "./AppDrawer";
import {Outlet} from "react-router-dom";
import {AppBar} from "./AppBar";
import {drawerWidth} from "./utils";

interface MainFrameProps extends AppDrawerProps {
    logo: JSX.Element;
}

export default function MainFrame(props: MainFrameProps) {
    return (
        <Box sx={{
            display: 'flex',
            minHeight: "100vh",
            maxWidth: '100vw'
        }}>
            <AppBar logo={props.logo}/>
            <AppDrawer {...props}/>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    paddingLeft: 3,
                    paddingTop: 3,
                    background: (theme) => theme.palette.grey["50"],
                    width: window.innerWidth - drawerWidth
                }}>
                <Toolbar/>
                <Outlet/>
            </Box>
        </Box>
    );
}
