import React, {FC} from "react";
import {Grid, Typography} from "@mui/material";
import {CreateKundeDTO, Kunde, UpdateKundeDTO} from "../api/types";
import {useCreateKundeMutation, useUpdateKundeMutation} from "../api/kundeApi";
import * as yup from "yup";
import {FormikTextField} from "../../../components/formikInputs/FormikTextField";
import {FormikDialogForm} from "../../../components/formikDialogForm/components/FormikDialogForm";
import {DialogMode} from "../../../components/formikDialogForm/types";

export const createKundeDTO: CreateKundeDTO = {
    organisation: "",
    stadt: null,
    postleitzahl: null,
    strasse: null,
    hausnummer: null,
    adresszusatz: null,
    operativerAnsprechpartner: null
}

export const kundeValidationSchema = yup.object({
    organisation: yup.string().required("erforderlich"),
    stadt: yup.string().nullable().notRequired(),
    postleitzahl: yup.string().nullable().notRequired(),
    strasse: yup.string().nullable().notRequired(),
    hausnummer: yup.string().nullable().notRequired(),
    adresszusatz: yup.string().nullable().notRequired(),
    operativerAnsprechpartner: yup.string().nullable().notRequired()
});

export const KundeDialogForm: FC = () => {

    const [createKunde, {isLoading: createKundeLoading}] = useCreateKundeMutation();
    const [updateKunde, {isLoading: updateKundeLoading}] = useUpdateKundeMutation();

    const loading = createKundeLoading || updateKundeLoading;

    const saveKunde = (dto: CreateKundeDTO | UpdateKundeDTO, mode: DialogMode): Promise<Kunde> => {
        let savePromise;
        if (mode === "create") {
            savePromise = createKunde(dto as CreateKundeDTO);
        } else {
            savePromise = updateKunde(dto as UpdateKundeDTO);
        }
        return savePromise.unwrap()
    };

    return (
        <FormikDialogForm
            dialogId={"kunde"}
            title={(mode) => mode === "create" ? "Neuer Kunde" : "Kunde bearbeiten"}
            onSubmit={saveKunde}
            creationInitialValues={createKundeDTO}
            validationSchema={kundeValidationSchema}
            loading={loading}
            form={
                <div>
                    <Typography sx={{mb: 2}}>Stammdaten</Typography>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <FormikTextField
                                name="organisation"
                                textFieldProps={{label: "Organisation", fullWidth: true, required: true}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                name="operativerAnsprechpartner"
                                textFieldProps={{label: "Operativer Ansprechpartner", fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                name="strasse"
                                textFieldProps={{label: "Straße", fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikTextField
                                name="hausnummer"
                                textFieldProps={{label: "Hausnummer", fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <FormikTextField
                                name="adresszusatz"
                                textFieldProps={{label: "Adresszusatz", fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                name="postleitzahl"
                                textFieldProps={{label: "PLZ", fullWidth: true}}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <FormikTextField
                                name="stadt"
                                textFieldProps={{label: "Stadt", fullWidth: true}}
                            />
                        </Grid>
                    </Grid>
                </div>
            }
        />
    );
}
