import React, {FC} from "react";
import {useMitarbeiterRole} from "../features/auth/api/auth.hook";
import {MitarbeiterRole} from "../features/auth/api/types";
import {Navigate} from "react-router-dom";

export const BaseRedirect: FC = () => {

    const role = useMitarbeiterRole();

    if(role === MitarbeiterRole.ADMIN || role === MitarbeiterRole.INTERN) {
        return (
            <Navigate to={"/intern"}/>
        )
    }
    if(role === MitarbeiterRole.EXTERN) {
        return (
            <Navigate to={"/extern"}/>
        )
    }
    return (
        <Navigate to={"/login"}/>
    )
}
