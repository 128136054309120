import * as React from 'react';
import {FC} from 'react';
import {NumericFormat} from 'react-number-format';
import TextField from '@mui/material/TextField';
import {useField} from "formik";
import {IconButton, InputAdornment, Stack, TextFieldProps, Tooltip} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                isAllowed={val => {
                    return !val.value.includes(".") || ["00", "20", "25", "50", "70", "75"].includes(val.value.split(".")[1])
                }}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                allowNegative={false}
                decimalSeparator=","
                suffix={" Std."}
                decimalScale={2}
                fixedDecimalScale={true}
                valueIsNumericString
            />
        );
    },
);

const DurationButtons = ({name}: { name: string }) => {
    const [, meta, helpers] = useField(name);
    return (
        <InputAdornment position={'end'}>
            <Stack spacing={-0.8}>
            <Tooltip title="Addiert 0,25 Std.">
                <IconButton onClick={() => helpers.setValue(meta.value + 900000)}>
                    <AddIcon style={{ fontSize: 18 }}/>
                </IconButton>
            </Tooltip>
            <Tooltip title="Subtrahiert 0,25 Std.">
                            <IconButton onClick={() => helpers.setValue(meta.value - 900000)}>
                    <RemoveIcon style={{ fontSize: 18 }}/>
                </IconButton>
            </Tooltip>
        
            </Stack>
        </InputAdornment>
    )
}

interface FormikDurationInputProps {
    name: string;
    textFieldProps?: TextFieldProps;
}

export const FormikDurationInput: FC<FormikDurationInputProps> = (props) => {

    const [, meta, helpers] = useField(props.name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(Number(event.target.value) * 3600000);
    };

    return (
        <TextField
            value={meta.value / 3600000}
            onChange={handleChange}
            id="formatted-numberformat-input"
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                endAdornment: <DurationButtons name={props.name}/>
            }}
            variant="outlined"
            {...props.textFieldProps}
            error={Boolean(meta.touched) && Boolean(meta.error)}
            helperText={Boolean(meta.touched) && Boolean(meta.error) && meta.error}
        />
    )
}
