import React, {FC} from "react";
import {useFindAllOwnedProjektQuery} from "../features/projekt/api/projektApi";
import {AppDataGrid} from "../components/table/AppDataGrid";
import {GridColDef} from "@mui/x-data-grid-pro";
import {formatDate} from "../components/formatUtils/dateFormatUtils";
import {KundeDisplay} from "../features/kunde/components/KundeDisplay";
import {Box, Fab, Grid, Typography} from "@mui/material";
import {useParams} from "react-router-dom";
import {ExternalProjektDetails} from "../features/projekt/view/details/ExternalProjektDetails";
import {Add} from "@mui/icons-material";
import {useNavigateDialogForm} from "../components/formikDialogForm/dialogForm.hook";
import {Aufwand} from "../features/aufwand/api/types";
import {useAppSelector} from "../state/redux.hooks";
import {selectMitarbeiterId} from "../features/auth/api/authSlice";

const projektTableColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 3
    },
    {
        field: 'kurzbeschreibung',
        headerName: 'Kurzbeschreibung',
        flex: 4,
        type: "string"
    },
    {
        field: 'kundeId',
        headerName: 'Kunde',
        flex: 2,
        renderCell: (params) => <KundeDisplay id={params.value}/>
    },
    {
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

export const ExternAppProjekte: FC = () => {
    const {data: projekte} = useFindAllOwnedProjektQuery();

    const params = useParams();

    const mitarbeiterId = useAppSelector(selectMitarbeiterId) ?? undefined;

    const selectedProjektId = params['*'];

    const currentDate = new Date();

    const navigateToCreateAufwand = useNavigateDialogForm<Aufwand>("aufwand", "create", {
        projektId: selectedProjektId,
        mitarbeiterId,
        datum: currentDate.toISOString()
    });

    return (
        <Box component="main" px={2} pt={3} height="90vh" position="relative">
            <Grid height="100%" container spacing={3}>
                <Grid item md={6} xs={12} height="100%" display="flex" flexDirection="column">
                    <Typography variant={"h5"} m={1} color="textSecondary">
                        Meine Projekte
                    </Typography>
                    <AppDataGrid
                        style={{flexGrow: 1}}
                        hideFooter
                        disableMultipleSelection
                        columns={projektTableColumns}
                        rows={projekte?.data ?? []}
                    />
                </Grid>
                <Grid item md={6} xs={12} height={"100%"}>
                    {selectedProjektId
                        ? <ExternalProjektDetails id={selectedProjektId}/>
                        : (
                            <Box sx={{display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", height: "30%"}}>
                                <Typography variant="h4" sx={{textAlign: "center", mb: 2}}>
                                    Willkommen
                                </Typography>
                                <Typography color="textSecondary" sx={{textAlign: "center", mb: 2}}>
                                    Wähle ein Projekt aus der Liste und erstelle mit dem Plus, unten rechts, Aufwände.
                                </Typography>
                                <Typography color="textSecondary" sx={{textAlign: "center"}}>
                                   Viel Spaß!
                                </Typography>
                            </Box>
                        )
                    }
                </Grid>
            </Grid>
            <Fab
                color="primary"
                disabled={!selectedProjektId}
                sx={{position: "absolute", bottom: -20, right: 8}}
                onClick={() => navigateToCreateAufwand()}
            >
                <Add/>
            </Fab>
        </Box>
    );
}
