import React, {FC} from "react";
import {useFindAllOwnedProjektQuery} from "../projekt/api/projektApi";
import {useNavigate} from "react-router-dom";
import {GridColDef} from "@mui/x-data-grid-pro";
import {KundeLink} from "../kunde/components/KundeLink";
import {MitarbeiterLink} from "../mitarbeiter/components/MitarbeiterLink";
import {formatDate} from "../../components/formatUtils/dateFormatUtils";
import {Box, BoxProps, Typography} from "@mui/material";
import {AppDataGrid} from "../../components/table/AppDataGrid";

const projektTableColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 3
    },
    {
        field: 'kurzbeschreibung',
        headerName: 'Kurzbeschreibung',
        flex: 4,
        type: "string"
    },
    {
        field: 'kundeId',
        headerName: 'Kunde',
        flex: 2,
        renderCell: (params) => <KundeLink id={params.value}/>
    },
    {
        field: 'projektLeiterId',
        headerName: 'Projektleiter',
        flex: 2,
        hide: true,
        renderCell: (params) => params.value && <MitarbeiterLink id={params.value}/>
    },
    {
        field: 'rechnungszyklus',
        headerName: 'Rechnungszyklus',
        flex: 2,
        hide: true,
        type: "number"
    },
    {
        field: 'istPauschal',
        headerName: 'Pauschal',
        flex: 1,
        hide: true,
        type: "boolean"
    },
    {
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

export const DashboardProjects: FC<{containerProps?: BoxProps}> = (props) => {
    const {data, isFetching} = useFindAllOwnedProjektQuery();

    const navigate = useNavigate();

    const projekte = data?.data ?? [];

    return (
        <Box {...props.containerProps}>
            <Typography variant="h6" color="textSecondary">Meine Projekte</Typography>
            <AppDataGrid
                loading={isFetching}
                style={{height: 400}}
                onRowClick={params => navigate(`/intern/projekte/${params.id}`)}
                columns={projektTableColumns}
                rows={projekte}
            />
        </Box>
    )
}
