import React, {FC} from "react";
import {Card, CardContent, CardProps, Grid} from "@mui/material";
import {CardTitleWithEdit} from "../../../../components/cardTitleWithEdit/CardTitleWithEdit";
import {entityToDTO} from "../../../../components/editUtils";
import {Projekt} from "../../api/projekt.types";
import {KundeLink} from "../../../kunde/components/KundeLink";
import {useNavigateDialogForm} from "../../../../components/formikDialogForm/dialogForm.hook";
import {TextDisplay} from "../../../../components/TextDisplay/TextDisplay";
import {useMitarbeiterRole} from "../../../auth/api/auth.hook";
import {MitarbeiterRole} from "../../../auth/api/types";
import {KundeDisplay} from "../../../kunde/components/KundeDisplay";

interface ProjektStammdatenCardProps {
    projekt: Projekt;
    cardProps?: CardProps;
    withKundePopover?: boolean;
}

export const ProjektStammdatenCard: FC<ProjektStammdatenCardProps> = ({projekt, cardProps, withKundePopover}) => {
    const navigateDialog = useNavigateDialogForm("projektStammdaten", "edit", entityToDTO(projekt));
    const userRole = useMitarbeiterRole();
    return (
        <Card {...cardProps}>
            <CardContent>
                <CardTitleWithEdit
                    title={"Stammdaten"}
                    optionLabel={"Stammdaten bearbeiten"}
                    onClickEdit={userRole === MitarbeiterRole.ADMIN ? navigateDialog : undefined}
                />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <TextDisplay
                            label="Projektname"
                            value={projekt.name}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        {withKundePopover
                            ? <KundeDisplay id={projekt.kundeId} label="Kunde"/>
                            : <KundeLink id={projekt.kundeId} label="Kunde"/>}
                    </Grid>
                    <Grid item xs={6}>
                        <TextDisplay
                            label="Kurzbeschreibung"
                            value={projekt.kurzbeschreibung}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextDisplay
                            label="Rechnungszyklus"
                            value={projekt.rechnungszyklus as unknown as string}
                        />
                    </Grid>
                    {projekt.bemerkung &&
                        <Grid item xs={12}>
                            <TextDisplay
                                label='Bemerkung'
                                value={projekt.bemerkung}
                            />
                        </Grid>}
                    <Grid item xs={12}>
                        <TextDisplay
                            label='Pauschal'
                            value={projekt.istPauschal ? "Ja" : "Nein"}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
};
