import React from "react";
import {useFindKundeByIdQuery} from "../api/kundeApi";
import {Fade, Link as MuiLink, Typography} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";

export function KundeLink({id, label}: { id: string; label?: string }) {
    const {data: kunde} = useFindKundeByIdQuery(id);

    const linkElement = (
        <Fade in={Boolean(kunde)}>
            <MuiLink
                component={RouterLink}
                to={`/intern/kunde/${id}`}
                color="secondary"
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            >
                {kunde?.organisation}
            </MuiLink>
        </Fade>
    );

    return (
        label
            ? (
                <Typography variant="caption" color="textSecondary">
                    {label}
                    <Typography variant="body1" color="textPrimary">{linkElement}</Typography>
                </Typography>
            )
            : linkElement
    )
}
