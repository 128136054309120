import React, {FC, useMemo} from "react";
import {
    GridActionsCellItem,
    GridActionsColDef,
    GridColDef,
    GridToolbarContainer,
    GridToolbarExport
} from "@mui/x-data-grid-pro";
import {AufwandApiFilters, PaginationState, useFindAllAufwandQuery} from "../api/aufwandApi";
import {AppDataGrid} from "../../../components/table/AppDataGrid";
import {MitarbeiterLink} from "../../mitarbeiter/components/MitarbeiterLink";
import {ProjektLink} from "../../projekt/components/ProjektLink";
import {Box, Button, Stack, StackProps} from "@mui/material";
import {Aufwand} from "../api/types";
import {Add, Edit} from "@mui/icons-material";
import {FilterSidePanel} from "./FilterSidePanel";
import _ from "lodash";
import {RechnungsNummerDialogButton} from "./RechnungsNummerDialogButton";
import {formatDate} from "../../../components/formatUtils/dateFormatUtils";
import {useNavigateDialogForm} from "../../../components/formikDialogForm/dialogForm.hook";
import {useAppSelector} from "../../../state/redux.hooks";
import {selectMitarbeiterId} from "../../auth/api/authSlice";
import {useMitarbeiterRole} from "../../auth/api/auth.hook";
import {MitarbeiterRole} from "../../auth/api/types";
import {DeleteAufandDialog} from "../delete/DeleteAufandDialog";
import {formatName} from "../../../components/formatUtils";
import {Mitarbeiter} from "../../mitarbeiter/api/types";
import {Projekt} from "../../projekt/api/projekt.types";
import {endOfYear, startOfDay} from "date-fns";
import {AufwaendeZusammenfassungDialog} from "./AufwaendeZusammenfassungDialog";

const aufwandTableColumns: GridColDef[] = [
    {
        field: 'id',
        headerName: 'id',
        flex: 3,
        hide: true,
        sortable: false
    },
    {
        field: 'taetigkeit',
        headerName: 'Taetigkeit',
        flex: 6,
        sortable: false,
        type: "string"
    },
    {
        field: 'mitarbeiter',
        headerName: 'Mitarbeiter',
        flex: 3,
        sortable: false,
        renderCell: (params) => params.value && <MitarbeiterLink id={params.value.id}/>,
        valueFormatter: (params) => formatName(params.value as Mitarbeiter)
    },
    {
        field: 'projekt',
        headerName: 'Projekt',
        flex: 3,
        sortable: false,
        renderCell: (params) => params.value && <ProjektLink id={params.value.id}/>,
        valueFormatter: (params) => (params.value as Projekt).name
    },
    {
        field: 'rechnungsnummer',
        headerName: 'Rechnungsnummer',
        sortable: false,
        flex: 3,
    },
    {
        field: 'ticketId',
        headerName: 'Ticket Id',
        sortable: false,
        flex: 2,
    },
    {
        field: 'istFakturierbar',
        headerName: 'Fakturierbar',
        sortable: false,
        flex: 2,
        type: "boolean"
    },
    {
        field: 'dauerInMillis',
        headerName: 'Dauer in Stunden',
        flex: 2,
        type: "number",
        valueFormatter: params => (params.value as number / 3600000).toLocaleString("de")
    },
    {
        field: 'datum',
        headerName: 'Datum',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    },
    {
        hide: true,
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

interface AufwandTableProps {
    initialFilterItems?: AufwandApiFilters;
    stackProps?: StackProps;
}

interface SortState {
    [key: string]: "asc" | "desc"
}

export const AufwandTable: FC<AufwandTableProps> = ({initialFilterItems, stackProps}) => {

    const mitarbeiterId = useAppSelector(selectMitarbeiterId);
    const mitarbeiterRole = useMitarbeiterRole();

    const [deleteAufwaendeDialogOpen, setDeleteAufwaendeDialogOpen] = React.useState(false);

    const navigateToEditAufwand = useNavigateDialogForm<Aufwand>("aufwand", "edit");

    const navigateDialog = useNavigateDialogForm<Aufwand>(
        "aufwand",
        "create",
        initialFilterItems && initialFilterItems.projektId
            ? {
                mitarbeiterId: mitarbeiterId ?? "",
                projektId: initialFilterItems.projektId.value,
                datum: (new Date()).toISOString()
            }
            : {
                mitarbeiterId: mitarbeiterId ?? "",
                datum: (new Date()).toISOString()
            }
    );

    const columns = useMemo(() => {
        const initialFilterKeys: string[] = initialFilterItems ? Object.keys(initialFilterItems) : [];
        const defaultColumns = aufwandTableColumns.map(atc => !initialFilterKeys.includes(atc.field) ? ({...atc}) : ({
            ...atc,
            hide: true
        }));
        const actionColumn: GridActionsColDef = {
            field: "edit",
            type: "actions",
            getActions: (params) => {
                const aufwand = params.row as Aufwand;
                if (mitarbeiterRole === MitarbeiterRole.ADMIN || aufwand.mitarbeiterId === mitarbeiterId) {
                    return [
                        <GridActionsCellItem
                            icon={<Edit/>}
                            label="Edit"
                            onClick={() => navigateToEditAufwand(aufwand)}
                        />
                    ]
                }
                return [];
            },
        };
        return [...defaultColumns, actionColumn]
    }, [initialFilterItems, mitarbeiterId, mitarbeiterRole, navigateToEditAufwand])

    const [showSidePanel, setShowSidePanel] = React.useState(false);

    const [paginationState, setPaginationState] = React.useState<PaginationState>({
        page: 0,
        pageSize: 25,
    });

    // @ts-ignore
    const [sortState, setSortState] = React.useState<SortState>({datum: "DESC"});

    const [apiFilters, setApiFilters] = React.useState<AufwandApiFilters | undefined>({
        datum: {
            operator: "between",
            from: startOfDay(new Date()).toISOString(),
            to: endOfYear(new Date()).toISOString()
        },
        ...initialFilterItems
    });

    const {data, isFetching} = useFindAllAufwandQuery({
        filter: apiFilters,
        pagination: paginationState,
        sort: sortState
    });

    const [showZusammenfassungDialog, setShowZusammenfassungDialog] = React.useState(false);
    const toggleZusammenfassungDialog = () => setShowZusammenfassungDialog(p => !p);

    const [selection, setSelection] = React.useState<Aufwand[] | null>(null);

    const aufwaende = data?.data ?? [];

    const selectedAufwaendeIds = selection?.map((a) => a.id) ?? [];

    return (
        <React.Fragment>
            <AufwaendeZusammenfassungDialog
                aufwaendeIds={selection?.map((a) => a.id) ?? []}
                open={showZusammenfassungDialog}
                onClose={toggleZusammenfassungDialog}
            />
            <DeleteAufandDialog
                open={deleteAufwaendeDialogOpen}
                aufwaendeIds={selectedAufwaendeIds}
                onClose={() => setDeleteAufwaendeDialogOpen(false)}
            />
            <Stack direction="row" spacing={2} {...stackProps}>
                <FilterSidePanel
                    open={showSidePanel}
                    toggleOpen={() => setShowSidePanel(prev => !prev)}
                    apiFilters={apiFilters}
                    setApiFilters={setApiFilters}
                />
                <Box flexGrow={1}>
                    <AppDataGrid
                        style={{height: 600}}
                        components={{
                            Toolbar: () => <GridToolbarContainer
                                style={{paddingLeft: 8, paddingTop: 8, paddingBottom: 16}}>
                                {!selection
                                    ? (
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            startIcon={<Add/>}
                                            onClick={() => navigateDialog()}
                                        >
                                            Neuer Aufwand
                                        </Button>
                                    )
                                    : (
                                        <Stack direction="row" spacing={2}>
                                            {mitarbeiterRole !== MitarbeiterRole.EXTERN &&
                                            <RechnungsNummerDialogButton selectedAufwaende={selection}/>}
                                            <Button
                                                variant="contained"
                                                color="secondary"
                                                onClick={toggleZusammenfassungDialog}
                                            >
                                                Zusammenfassung
                                            </Button>
                                            <GridToolbarExport
                                                variant="contained"
                                                color="secondary"
                                                csvOptions={{
                                                    includeHeaders: true,
                                                    delimiter: ";",
                                                    fileName: "EffortLog-Aufwände",
                                                    utf8WithBom: true
                                                }}
                                            />
                                            {mitarbeiterRole === MitarbeiterRole.ADMIN &&
                                            <Button
                                                onClick={() => setDeleteAufwaendeDialogOpen(true)}
                                                variant="contained"
                                                color="secondary"
                                            >
                                                Löschen
                                            </Button>}
                                        </Stack>
                                    )
                                }
                            </GridToolbarContainer>
                        }}
                        filterMode="server"
                        initialState={{
                            sorting: {sortModel: [{field: "datum", sort: "desc"}]}
                        }}
                        disableColumnFilter={true}
                        onRowClick={() => {
                        }}
                        checkboxSelection={true}
                        loading={isFetching}
                        columns={columns}
                        rows={aufwaende}
                        pagination
                        paginationMode="server"
                        rowCount={data?.pagination.count ?? 0}
                        page={paginationState.page}
                        pageSize={paginationState.pageSize}
                        rowsPerPageOptions={[25, 50, 100, 200, 1000, 3000]}
                        onPageChange={(page) => setPaginationState((prev) => ({...prev, page}))}
                        onPageSizeChange={(pageSize) =>
                            setPaginationState((prev) => ({...prev, pageSize}))
                        }
                        sortingMode="server"
                        onSortModelChange={model =>
                            setSortState(model.reduce(
                                    ((acc, cur) => {
                                        acc[cur.field] = cur.sort ? _.upperCase(cur.sort) as "asc" | "desc" : "asc";
                                        return acc;
                                    }),
                                    {} as SortState
                                )
                            )
                        }
                        onSelectionModelChange={(_, s) => {
                            const aufwandIterator = s.api?.getSelectedRows().values() as IterableIterator<Aufwand> | undefined;
                            if (aufwandIterator) {
                                const aufwandArray = Array.from(aufwandIterator);
                                setSelection(aufwandArray.length === 0 ? null : aufwandArray)
                            }
                        }}
                    />
                </Box>
            </Stack>
        </React.Fragment>
    );
};
