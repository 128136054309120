import React, {FC} from "react";
import {useField} from "formik";
import {MitarbeiterAutocomplete} from "./MitarbeiterAutocomplete";
import {TextFieldProps} from "@mui/material";

interface FormikMitarbeiterAutocompleteProps {
    name: string,
    label: string
    required?: boolean;
    disableClearable?: boolean;
    variant?: TextFieldProps["variant"];
    excludeIds?: string[];
    disabled?: boolean;

}

export const FormikMitarbeiterAutocomplete: FC<FormikMitarbeiterAutocompleteProps> = ({
                                                                                          disableClearable,
                                                                                          name,
                                                                                          label,
                                                                                          required,
                                                                                          variant,
                                                                                          excludeIds,
                                                                                          disabled
                                                                                      }) => {
    const [, meta, helpers] = useField<string | null | undefined>(name);

    return (
        <MitarbeiterAutocomplete
            value={meta.value}
            onChange={helpers.setValue}
            label={label}
            error={meta.touched && meta.error ? meta.error : undefined}
            required={required}
            disableClearable={disableClearable}
            variant={variant}
            excludeIds={excludeIds}
            disabled={disabled}
        />
    )
}
