import {
    BaseQueryFn,
    FetchArgs,
    fetchBaseQuery,
    FetchBaseQueryError,
} from '@reduxjs/toolkit/query'
import {setCredentials, logOut} from './authSlice'
import {RootState} from "../../../state/store";
import {MitarbeiterRole} from "./types";

export function createBaseQueryWithAuthToken(type: "accessToken" | "refreshToken" = "accessToken") {
    return fetchBaseQuery({
        baseUrl: window.API_BASE_URL,
        prepareHeaders: (headers, {getState}) => {
            const token = (getState() as RootState).auth[type];
            if (token) {
                headers.set('Authorization', `Bearer ${token}`)
            }
            return headers
        },
    })
}

const baseQueryWithAccessToken = createBaseQueryWithAuthToken("accessToken");
const baseQueryWithRefreshToken = createBaseQueryWithAuthToken("refreshToken");

export const baseQueryWithReauth: BaseQueryFn<string | FetchArgs,
    unknown,
    FetchBaseQueryError> = async (args, api, extraOptions) => {
    let result = await baseQueryWithAccessToken(args, api, extraOptions);

    if (result.error && result.error.status === 401) {

        const refreshResult = await baseQueryWithRefreshToken(
            {method: "POST", url: 'auth/refresh'},
            api,
            extraOptions
        ) as { data?: { access_token: string; refresh_token: string; user_id: string; role: MitarbeiterRole } };

        if (refreshResult.data) {
            // store the new token
            api.dispatch(setCredentials({
                accessToken: refreshResult.data.access_token,
                refreshToken: refreshResult.data.refresh_token,
                userId: refreshResult.data.user_id,
                role: refreshResult.data.role
            }))
            // retry the initial query
            result = await baseQueryWithAccessToken(args, api, extraOptions);
        } else {
            api.dispatch(logOut());
        }
    }
    return result;
}
