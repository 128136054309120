import React, {FC} from "react";
import Toolbar from "@mui/material/Toolbar";
import MuiAppBar from "@mui/material/AppBar";
import AccountMenu from "./AccountMenu";
import {Box} from "@mui/material";

interface AppBarProps {
    logo: JSX.Element;
    forExtern?: boolean;
}

export const AppBar: FC<AppBarProps> = ({logo, forExtern}) => (
    <MuiAppBar
        position="fixed"
        sx={(theme) => ({
            zIndex: theme.zIndex.drawer + 1,
            background: `linear-gradient(90deg, ${theme.palette.primary.main} 0%, ${theme.palette.secondary.main} 100%)`
        })}
    >
        <Toolbar>
            <Box mr="auto">
                {logo}
            </Box>
            <p style={{fontFamily: 'Cinzel', fontSize: 20}}>
                EffortLog
            </p>
            <AccountMenu extern={forExtern}/>
        </Toolbar>
    </MuiAppBar>
)
