import React, {FC} from "react";
import {useField} from "formik";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import {TextField} from "@mui/material";

export const FormikDateField: FC<{ name: string; label: string }> = (props) => {
    const [, meta, helpers] = useField<string | undefined | null>(props.name);

    const handleChange = (newValue: Date | null) => {
        if (newValue && newValue instanceof Date && !isNaN(newValue as any)) {
            helpers.setValue(newValue.toISOString());
        }
    };

    return (
        <DesktopDatePicker
            label="Datum"
            inputFormat="dd.MM.yyyy"
            mask="__.__.____"
            value={meta.value ?? null}
            onChange={handleChange}
            renderInput={(params) => (
                <TextField
                    fullWidth
                    error={Boolean(meta.touched) && Boolean(meta.error)}
                    helperText={params.helperText ?? meta.error}
                    {...params}
                />
            )}
        />
    )
};
