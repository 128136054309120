import React, {FC} from "react";
import {NumericFormat} from "react-number-format";
import {TextFieldProps} from "@mui/material";
import {useField} from "formik";
import TextField from "@mui/material/TextField";


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

const NumberFormatCustom = React.forwardRef<typeof NumericFormat, CustomProps>(
    function NumberFormatCustom(props, ref) {
        const {onChange, ...other} = props;

        return (
            <NumericFormat
                {...other}
                getInputRef={ref}
                onValueChange={(values) => {
                    onChange({
                        target: {
                            name: props.name,
                            value: values.value,
                        },
                    });
                }}
                fixedDecimalScale={true}
                decimalScale={2}
                allowLeadingZeros={true}
                decimalSeparator={","}
                suffix=" €/h"
                valueIsNumericString
            />
        );
    },
);

interface FormikEuroInputProps {
    name: string;
    textFieldProps?: TextFieldProps;
}

export const FormikEuroInput: FC<FormikEuroInputProps> = (props) => {

    const [, meta, helpers] = useField(props.name);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        helpers.setValue(Number(event.target.value) * 100);
    };

    return (
        <TextField
            value={meta.value / 100}
            onChange={handleChange}
            id="formatted-numberformat-input"
            InputProps={{
                inputComponent: NumberFormatCustom as any,
            }}
            variant="outlined"
            {...props.textFieldProps}
            error={Boolean(meta.touched) && Boolean(meta.error)}
            helperText={Boolean(meta.touched) && Boolean(meta.error) && meta.error}
        />
    )
}
