import React, {FC, useState} from 'react';
import {Box, Card, CardContent, ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';
import {TachoMonat} from "./tacho/DashboardAufwaendeTachoMonat";
import {TachoTage} from "./tacho/DashboardAufwaendeTachoTage";

export const DashboardAufwaendeQuote: FC = () => {

    const [einheit, setEinheit] = useState('Monat')

    const handleChange = (event: React.MouseEvent<HTMLElement>, value: any) => {
        console.log(value)
        setEinheit(value)
    };

    return (
        <Box sx={{position: "relative"}}>
            <Typography variant="h6" color="textSecondary">Fakturierungsquoten</Typography>
            <Card>
                <CardContent style={{overflowX: "auto"}}>
                    <div style={{display: "flex", width: "100%", justifyContent: "flex-end"}}>
                        <ToggleButtonGroup
                            value={einheit}
                            onChange={handleChange}
                            exclusive
                            size="small"
                            color="primary"
                            sx={{height: "28px"}}>
                            <ToggleButton value='Monat'>Monatlich</ToggleButton>
                            <ToggleButton value='Tage'>letzten 30 Tage</ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                    {einheit === 'Monat'
                        ? <TachoMonat/>
                        : <TachoTage/>
                    }
                </CardContent>
            </Card>
        </Box>
    );
};
