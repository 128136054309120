import React, {FC} from "react";
import {Autocomplete, Button, CircularProgress, TextField} from "@mui/material";
import {useCreateKundeMutation, useFindAllKundeQuery} from "../../api/kundeApi";
import {Kunde} from "../../api/types";

interface KundeAutocompleteProps {
    value?: string | null;
    onChange: (kundeId: string | null) => void;
    error?: string;
    required?: boolean;
}

export const KundeAutocomplete: FC<KundeAutocompleteProps> = ({value, onChange, error, required}) => {

    const {data: kunden = [], isFetching: isFetchingList} = useFindAllKundeQuery();
    const [createKunde, {isLoading: isCreatingKunde}] = useCreateKundeMutation();

    const [inputValue, setInputValue] = React.useState("");

    const handleCreateKunde = () =>
        //createKunde({organisation: inputValue, operativerAnsprechpartner: null, adresse: null})
        createKunde({organisation: inputValue, operativerAnsprechpartner: null, stadt: null, postleitzahl: null, strasse: null, hausnummer: null, adresszusatz: null})
            .unwrap()
            .then((kunde) => {
                onChange(kunde.id);
            });

    return (
        <Autocomplete
            value={kunden.find((k) => k.id === value) ?? null}
            renderInput={(params) =>
                <TextField
                    {...params}
                    label="Kunde"
                    variant="outlined"
                    error={Boolean(error)}
                    helperText={error}
                    required={required}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isFetchingList || isCreatingKunde
                                    ? <CircularProgress color="inherit" size={20}/>
                                    : null
                                }
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            }
            options={kunden}
            getOptionLabel={(o) => o.organisation}
            inputValue={inputValue}
            onInputChange={(_, v) => {
                setInputValue(v);
            }}
            onChange={(_, value) => {
                const kunde: Kunde | null = value;
                if(kunde) {
                    onChange(kunde.id);
                } else {
                    onChange(null);
                }
            }}
            noOptionsText={
                inputValue !== ""
                    ? (
                        <Button
                            disabled={isCreatingKunde || isFetchingList}
                            color="primary"
                            variant="contained"
                            style={{ textTransform: "none" }}
                            onMouseDown={handleCreateKunde}
                        >
                            Neuer Kunde "{inputValue}"
                        </Button>
                    )
                    : ""
            }
        />
    )
}
