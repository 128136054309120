import React, {FC} from "react";
import {Typography, TypographyProps} from "@mui/material";

export interface TextDisplayProps {
    label?: string;
    value?: string | null;
    captionProps?: TypographyProps;
    displayProps?: TypographyProps;
}

export const TextDisplay: FC<TextDisplayProps> = ({label, value, captionProps, displayProps}) => (
    <Typography variant="caption" color="text.secondary" {...captionProps}>
        {label}
        <Typography
            sx={{
                wordWrap: "break-word",
                whiteSpace: 'pre-line'
        }}
            color="text.primary"
            {...displayProps}
        >
            {value}
        </Typography>
    </Typography>
);
