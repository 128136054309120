import {getDefaultGridFilterModel, GridFilterModel, useGridApiRef} from "@mui/x-data-grid-pro";
import React from "react";

// Mapping of Material Ui GridFilterItem's operator value to typeorms
const findOperatorsMap: {[GridFilterItemOperatorValue: string]: string} = {
    is: "equal",
    gridOperator5: "in",
    isEmpty: "isNull",
    isNotEmpty: "isNotNull",
    after: "moreThan",
    onOrAfter: "moreThanOrEqual",
    before: "lessThan",
    onOrBefore: "lessThanOrEqual",
    contains: "like",
    startsWith: "like",
    endsWith: "like",
    // Todo more mappings und mit Daniel das ganze System besprechen
    gridOperator9: "lessThanOrEqual",
    gridOperator10: "moreThan",
    gridOperator11: "moreThanOrEqual"
};

export interface ApiFilters {
    [key: string]: {
        value: any,
        operator: string;
    }
}

export function useTableApiQueryFilterState<T extends {[key: string]: any}>(initialFilterState?: ApiFilters) {

    const gridApiRef = useGridApiRef();

    const [apiFilters, setApiFilters] = React.useState<ApiFilters | undefined>(initialFilterState);

    const onFilterModelChange = React.useCallback((filterModel: GridFilterModel) => {
        console.log("filterModel", filterModel);
        if (filterModel.items.length > 0) {
            const newApiFilters = filterModel.items.reduce((acc: ApiFilters, curr) => {
                /*if(curr.columnField === "datum") {
                    console.log("ddd", new Date(curr.value).toString())
                }*/
                acc[curr.columnField] = {
                    value: curr.value,
                    operator: curr.operatorValue ? findOperatorsMap[curr.operatorValue] ?? "equal" : "equal"
                };
                return acc;
            }, {});

            setApiFilters(newApiFilters);
        } else {
            setApiFilters(undefined);
        }
    }, []);

    const onFilterInputChange = React.useCallback((entityField: keyof T, value: any | null | undefined, operator?: string) => {
        const columnField = entityField as string;
        if (gridApiRef.current) {
            if (value !== undefined && value !== null) {
                gridApiRef.current.upsertFilterItem({columnField, value: value, id: columnField, operatorValue: operator ?? "is"});
            } else {
                gridApiRef.current.deleteFilterItem({columnField, id: columnField})
            }
        }
    }, [gridApiRef]);

    const clearFilters = () => {
        if (gridApiRef.current) {
            gridApiRef.current.setFilterModel(getDefaultGridFilterModel());
        }
    }

    return {
        gridApiRef,
        apiFilters,
        onFilterModelChange,
        onFilterInputChange,
        clearFilters
    }
}
