import {MitarbeiterRole} from "../../auth/api/types";

export enum Aktivitaetsstatus {
    AKTIV = 'aktiv',
    INAKTIV = 'inaktiv',
}

export interface Mitarbeiter {
    id: string;
    anrede: string;
    vorname: string;
    nachname: string;
    kuerzel: string;
    creationDate: string;
    updateDate: string;
    email: string;
    role: MitarbeiterRole;
    confirmed: boolean;
    monatsarbeitsstunden: number;
    aktivitaetsstatus: Aktivitaetsstatus;
}

export interface CreateMitarbeiterDTO extends Omit<Mitarbeiter, "id" | "creationDate" | "updateDate" | "aktivitaetsstatus"> {
    temporaryPassword: string;
    role: MitarbeiterRole;
}

export interface UpdateMitarbeiterDTO extends Partial<Omit<Mitarbeiter, "creationDate" | "updateDate" | "user">> {
    email: string;
    role: MitarbeiterRole;
}

export interface ChangeOwnPasswordDto {
    oldPassword: string;
    newPassword: string;
}

export interface ChangeMitarbeiterPasswordDto {
    newPassword: string;
    mitarbeiterId: string;
}
