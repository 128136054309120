import React, {FC, useCallback} from "react";
import {Card, CardContent, Stack} from "@mui/material";
import {Mitarbeiter} from "../api/types";
import {useNavigateDialogForm} from "../../../components/formikDialogForm/dialogForm.hook";
import {TextDisplay} from "../../../components/TextDisplay/TextDisplay";
import {EmailDisplay} from "../../../components/emailDisplay/EmailDisplay";
import {entityToDTO} from "../../../components/editUtils";
import {CardTitleWithOptions} from "../../../components/cardTitleWithOptions/CardTitleWithOptions";
import {Edit, Password} from "@mui/icons-material";
import {useAppSelector} from "../../../state/redux.hooks";
import {selectMitarbeiterId} from "../../auth/api/authSlice";
import {useMitarbeiterRole} from "../../auth/api/auth.hook";
import {MitarbeiterRole} from "../../auth/api/types";
import {ChangeMitarbeiterPasswordDialog} from "../save/ChangeMitarbeiterPasswordDialog";
import {ChangeOwnPasswordDialog} from "../save/ChangeOwnPasswordDialog/ChangeOwnPasswordDialog";

export const MitarbeiterStammdatenCard: FC<{ mitarbeiter: Mitarbeiter }> = ({mitarbeiter}) => {


    const loggedInMitarbeiterId = useAppSelector(selectMitarbeiterId);
    const loggedInMitarbeiterRole = useMitarbeiterRole();

    const isSelfOrAdmin = loggedInMitarbeiterRole === MitarbeiterRole.ADMIN || mitarbeiter.id === loggedInMitarbeiterRole;
    const navigateEditDialog = useNavigateDialogForm("mitarbeiter", "edit", entityToDTO(mitarbeiter));

    const [changeOwnPwDialogOpen, setChangeOwnPwDialogOpen] = React.useState(false);
    const [changeMitarbeiterPwDialogOpen, setChangeMitarbeiterPwDialogOpen] = React.useState(false);

    const handleClickChangePassword = useCallback(() => {
        if (mitarbeiter.id === loggedInMitarbeiterId) {
            setChangeOwnPwDialogOpen(true);
        } else {
            setChangeMitarbeiterPwDialogOpen(true);
        }
    }, [setChangeOwnPwDialogOpen, mitarbeiter, loggedInMitarbeiterId]);

    return (
        <React.Fragment>
            <ChangeOwnPasswordDialog
                open={changeOwnPwDialogOpen}
                onClose={() => setChangeOwnPwDialogOpen(false)}
                variant="non-initial"
            />
            <ChangeMitarbeiterPasswordDialog
                mitarbeiter={mitarbeiter}
                open={changeMitarbeiterPwDialogOpen}
                onClose={() => setChangeMitarbeiterPwDialogOpen(false)}
            />
            <Card>
                <CardContent>
                    <CardTitleWithOptions
                        title={"Stammdaten"}
                        options={isSelfOrAdmin
                            ? [
                                {
                                    label: "Bearbeiten",
                                    icon: <Edit/>,
                                    onClick: navigateEditDialog
                                },
                                {
                                    label: "Passwort ändern",
                                    icon: <Password/>,
                                    onClick: handleClickChangePassword
                                }
                            ]
                            : undefined
                        }
                    />
                    <Stack spacing={2}>
                        <Stack spacing={2} direction="row">
                            <TextDisplay
                                label="Anrede"
                                value={mitarbeiter.anrede}
                            />
                            <TextDisplay
                                label="Vorname"
                                value={mitarbeiter.vorname}
                            />
                            <TextDisplay
                                label="Nachname"
                                value={mitarbeiter.nachname}
                            />
                        </Stack>
                        <TextDisplay
                            label="Kürzel"
                            value={mitarbeiter.kuerzel}
                        />
                        <EmailDisplay
                            label="Email"
                            value={mitarbeiter.email}
                        />
                        <TextDisplay
                            label="Arbeitsstunden pro Monat"
                            value={mitarbeiter.monatsarbeitsstunden?.toString()}
                        />
                        <TextDisplay
                            label="Aktivitätsstatus"
                            value={mitarbeiter.aktivitaetsstatus}
                        />
                    </Stack>
                </CardContent>
            </Card>
        </React.Fragment>
    )
};
