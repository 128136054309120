import React, {FC} from "react";
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    LinearProgress,
    Stack,
    TextField
} from "@mui/material";
import {useChangeMitarbeiterPasswordMutation} from "../api/mitarbeiterApi";
import {Mitarbeiter} from "../api/types";
import {formatName} from "../../../components/formatUtils";

interface ChangeMitarbeiterPasswordDialogProps {
    mitarbeiter: Mitarbeiter;
    open: boolean;
    onClose: () => void;
}

export const ChangeMitarbeiterPasswordDialog: FC<ChangeMitarbeiterPasswordDialogProps> = (props) => {

    const [updateMitarbeiterPassword, {isLoading}] = useChangeMitarbeiterPasswordMutation();

    const [newPassword, setNewPassword] = React.useState("");
    const [confirmNewPassword, setNewPasswordConfirm] = React.useState("");
    const [error, setError] = React.useState<string | null>(null);
    const resetError = () => setError(null);

    const buttonDisabled = newPassword.length < 9 || confirmNewPassword.length < 9 || newPassword !== confirmNewPassword;

    const handleClose = () => {
        setNewPassword("");
        setNewPasswordConfirm("");
        resetError();
        props.onClose();
    }

    const handleChangePassword = () => {
        if (newPassword !== "" && confirmNewPassword !== "" && newPassword !== confirmNewPassword) {
            setError("Die Bestätigung des neuen Passwortes ist nicht gleich.");
        } else if (newPassword.length < 9) {
            setError("Das neue Passwort muss mindestens 9 Zeichen lang sein.");
        } else {
            updateMitarbeiterPassword({
                mitarbeiterId: props.mitarbeiter.id,
                newPassword
            }).then(() => {
                if (props.onClose) {
                    props.onClose()
                }
            })
        }
    }

    return (
        <Dialog open={props.open} onClose={handleClose} maxWidth="sm">
            <DialogTitle>
                Passwort ändern von {formatName(props.mitarbeiter)}
            </DialogTitle>
            <DialogContent sx={{position: "relative"}}>
                {isLoading && <LinearProgress sx={{width: "100%"}}/>}
                <Stack spacing={3}>
                    <DialogContentText>
                        Merken Sie sich das neue Passwort und teilen Sie dies dem Mitarbeiter mit.
                        Dieser wird beim nächsten Login aufgefordert sein Passwort erneut zu ändern.
                    </DialogContentText>
                    <TextField
                        label="Neues Passwort"
                        placeholder="Mindestens 9 Zeichen"
                        type="password"
                        value={newPassword}
                        onChange={(e) => {
                            resetError();
                            setNewPassword(e.target.value);
                        }}
                        helperText={error ? error : `${newPassword.length} / 9`}
                        error={Boolean(error)}
                    />
                    <TextField
                        placeholder="Neues Passwort wiederholen"
                        type="password"
                        value={confirmNewPassword}
                        onChange={({target}) => {
                            resetError();
                            setNewPasswordConfirm(target.value);
                        }}
                        helperText={error && error}
                        error={Boolean(error)}
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button
                    sx={{color: "text.secondary"}}
                    onClick={handleClose}
                >
                    Abbrechen
                </Button>
                <Button
                    disabled={buttonDisabled}
                    color="success"
                    onClick={handleChangePassword}
                >
                    Neues Passwort speichern
                </Button>
            </DialogActions>
        </Dialog>
    )
}
