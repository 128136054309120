import {MitarbeiterRole} from "../api/types";
import React, {FC} from "react";
import {useMitarbeiterRole} from "../api/auth.hook";

interface VisibleIfRoleProps {
    equals: MitarbeiterRole;
}

export const VisibleIfRole: FC<VisibleIfRoleProps> = (props) => {
    const userRole = useMitarbeiterRole();
    return (
        <React.Fragment>
            {props.equals === userRole && props.children}
        </React.Fragment>
    )
}
