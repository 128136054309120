import React, {FC} from "react";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useFindAllMitarbeiterQuery} from "../api/mitarbeiterApi";
import {AppDataGrid} from "../../../components/table/AppDataGrid";
import {formatName} from "../../../components/formatUtils";
import {Mitarbeiter} from "../api/types";
import {Link} from "@mui/material";
import {formatDate} from "../../../components/formatUtils/dateFormatUtils";

export const mitarbeiterTableColumns: GridColDef[] = [
    {
        field: 'kuerzel',
        headerName: 'Kürzel',
        flex: 1,
    },
    {
        field: 'name',
        headerName: 'Name',
        flex: 3,
        valueGetter: (params) => formatName(params.row as Mitarbeiter)
    },
    {
        field: 'user.email',
        headerName: 'Email',
        flex: 3,
        valueGetter: (params) => (params.row as Mitarbeiter).email,
        renderCell: (params) => (
            <Link
                onClick={(e) => e.stopPropagation()}
                href={`mailto:${(params.row as Mitarbeiter).email}`}>
                {(params.row as Mitarbeiter).email}
            </Link>
        )
    },
    {
        field: 'user.role',
        headerName: 'Rolle',
        flex: 1,
        valueGetter: (params) => (params.row as Mitarbeiter).role
    },
    {
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

export const MitarbeiterTable: FC = () => {
    const {data: mitarbeiter = [], isFetching} = useFindAllMitarbeiterQuery();

    return (
        <AppDataGrid
            checkboxSelection={true}
            loading={isFetching}
            columns={mitarbeiterTableColumns}
            rows={mitarbeiter}
        />
    );
};
