import React, {FC} from "react";
import {KundeAutocomplete} from "./KundeAutocomplete";
import {useField} from "formik";

export const FormikKundeAutocomplete: FC<{name: string; required?: boolean;}> = ({name, required}) => {
    const [, meta, helpers] = useField<string | null | undefined>(name);

    return (
        <KundeAutocomplete
            value={meta.value}
            onChange={helpers.setValue}
            error={meta.touched && meta.error ? meta.error : undefined}
            required={required}
        />
    )
}
