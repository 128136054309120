export enum RechnungsZyklus {
    MONATLICH = 'monatlich',
    EINMALIG = 'einmalig',
    QUARTALSWEISE = 'quartalsweise',
    JAEHRLICH = 'jaehrlich',
}

export interface Projekt {
    id: string;
    name: string;
    rechnungszyklus: RechnungsZyklus;
    istPauschal: boolean;
    kurzbeschreibung: string | null;
    kundeId: string;
    projektLeiterId: string;
    creationDate: string;
    updateDate: string;
    bemerkung: string | null
}

export interface CreateProjektMitarbeiterArrayElement {
    mitarbeiterId: string;
    stundenSatzInEuroCent: number;
}

export interface CreateProjektDTO extends Omit<Projekt, "id" | "creationDate" | "updateDate"> {
    projektLeiterStundenSatzInEuroCent: number;
    projektMitarbeiter: CreateProjektMitarbeiterArrayElement[];
}

export interface UpdateProjektDTO extends Partial<Omit<Projekt, "creationDate" | "updateDate">> {
}
