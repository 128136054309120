import React from "react";
import {useFindMitarbeiterByIdQuery} from "../api/mitarbeiterApi";
import {Fade, Link as MuiLink, Typography, TypographyProps} from "@mui/material";
import {Link as RouterLink} from "react-router-dom";
import {formatName} from "../../../components/formatUtils";

export function MitarbeiterLink({id, label, captionProps}: { id: string; label?: string; captionProps?: TypographyProps }) {
    const {data: mitarbeiter, isLoading} = useFindMitarbeiterByIdQuery(id);

    const linkElement = (
        <Fade in={!isLoading}>
            <MuiLink
                component={RouterLink}
                to={`/intern/mitarbeiter/${id}`}
                color="secondary"
                onClick={(e: React.SyntheticEvent) => e.stopPropagation()}
            >
                {mitarbeiter && formatName(mitarbeiter, false)}
            </MuiLink>
        </Fade>
    );

    return (
        label
            ? (
                <Typography variant="caption" color="textSecondary" {...captionProps}>
                    {label}
                    <Typography variant="body1" color="textPrimary">{linkElement}</Typography>
                </Typography>
            )
            : linkElement
    )
}
