import React, {FC} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {FormikKundeAutocomplete} from "../../kunde/components/kundeAutocomplete/FormikKundeAutocomplete";
import {FormikCheckboxField} from "../../../components/FormikCheckboxField";
import {FormikTextField} from "../../../components/formikInputs/FormikTextField";
import {FormikSelect} from "../../../components/formikInputs/FormikSelect";
import {RechnungsZyklus} from "../api/projekt.types";

export const ProjektStammdatenFormElement: FC = () => {
    return (
        <React.Fragment>
            <Typography sx={{mb: 2}}>Stammdaten</Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <FormikTextField
                        name="name"
                        textFieldProps={{label: "Projektname", fullWidth: true, required: true}}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormikKundeAutocomplete
                        name={"kundeId"}
                        required
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextField
                        name="kurzbeschreibung"
                        textFieldProps={{label: "Kurzbeschreibung", fullWidth: true}}
                    />
                </Grid>
                <Grid item xs={6} sx={{display: "flex", alignItems: "center"}}>
                    <Box sx={{pl: 2}}>
                        <FormikCheckboxField name={"istPauschal"} label={"Pauschal"}/>
                    </Box>
                </Grid>
                <Grid item xs={6}>

                    <FormikSelect
                        label={"Rechnungszyklus"}
                        name={"rechnungszyklus"}
                        options={[
                            {value: RechnungsZyklus.EINMALIG, label: "Einmalig"},
                            {value: RechnungsZyklus.MONATLICH, label: "Monatlich"},
                            {value: RechnungsZyklus.QUARTALSWEISE, label: "Quartalsweise"},
                            {value: RechnungsZyklus.JAEHRLICH, label: "Jährlich"},
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormikTextField
                        name='bemerkung'
                        textFieldProps={{label: 'Bemerkung', fullWidth: true, multiline: true}}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    )
}
