import React, {FC} from "react";
import {Box, CardContent} from "@mui/material";
import {theme} from "../../theme";
import {useNavigateDialogForm} from "../../components/formikDialogForm/dialogForm.hook";
import {CardTitleWithEdit} from "../../components/cardTitleWithEdit/CardTitleWithEdit";

interface DatenschutzViewProps {
    htmlString: string;
    isEditable: boolean;

}

export const DatenschutzView: FC<DatenschutzViewProps> = (props) => {
    const navigateToDialog = useNavigateDialogForm("datenschutz", "edit");

    return (
        <CardContent>
            <CardTitleWithEdit
                title={'Datenschutz'}
                onClickEdit={props.isEditable? () => navigateToDialog({content: props.htmlString}) : undefined}
            />
            <Box sx={{
                "*": {
                    color: theme.palette.grey[700],
                    marginBlock: 0,
                }
            }}>
                <div dangerouslySetInnerHTML={{__html: props.htmlString}}/>
            </Box>
        </CardContent>
    )
}