import React, {useEffect} from "react";
import {getScoreColor} from "./utils";

const COMPLEXITY_BASE_SCORE = 50;
const LENGTH_BASE_SCORE = 50;

interface PasswordRequirement {
    label: string;
    requirementCheckerFunction: (newPassword: string) => boolean;
}

interface PasswordRequirementResult {
    label: string;
    satisfied: boolean;
}

interface RequirementsState {
    score: number;
    requirementsResults: PasswordRequirementResult[];
}

export function getDefaultRequirements(): PasswordRequirement[] {
    return [
        {
            label: "Passwort ist mindestens 9 Zeichen lang",
            requirementCheckerFunction: (pw) => {
                const regexLength: RegExp = /^.{9,}/ //checks length of pw (>= 9 ok);
                return regexLength.test(pw)
            }
        },
        {
            label: "Passwort enthält Kleinbuchstaben",
            requirementCheckerFunction: (pw) => {
                const regexAlphaLow: RegExp = /[a-zaöüß]+/ // checks for Lowercase alphabet chars
                return regexAlphaLow.test(pw)
            }
        },
        {
            label: "Passwort enthält Großbuchstaben",
            requirementCheckerFunction: (pw) => {
                const regexAlphaUp: RegExp = /[A-ZÄÖÜß]+/ // checks for Uppercase alphabet chars
                return regexAlphaUp.test(pw)
            }
        },
        {
            label: "Passwort enthält Zahlen",
            requirementCheckerFunction: (pw) => {
                const regexNum: RegExp = /[0-9]+/ //checks for Numbers (123... ok)
                return regexNum.test(pw)
            }
        },
        {
            label: "Passwort enthält Sonderzeichen",
            requirementCheckerFunction: (pw) => {
                const regexSonder: RegExp = /[^a-zA-Z0-9ßäÄöÖüÜ_]/ //checks everything except the Cases above
                return regexSonder.test(pw)
            }
        }
    ];
}

export function usePasswordRequirements(newPassword: string, requirements?: PasswordRequirement[]) {

    const [requirementsState, setRequirementsState] = React.useState<RequirementsState>({
        score: 0,
        requirementsResults: []
    });

    useEffect(() => {

        const requirementsResults: PasswordRequirementResult[] = [];

        const currentRequirements = requirements ?? getDefaultRequirements();

        const computedComplexityScore = COMPLEXITY_BASE_SCORE / currentRequirements.length;

        const passwordLengthTotalScore = Math.min(newPassword.length, 9) * (LENGTH_BASE_SCORE / 9);

        /**
         Geht jedes requirement im requirements array durch.
         Pro element wird die requirementCheckerfunction ausgeführt.
         Wenn diese true returnt dann ist Zwischenergebnis 16 sonst 0.
         Jeder so ausgerechnete Zwischenwert wird nachdem er ausgerechnet wurde
         auf den Startwert (0) oder den vorhereigen errechneten Wert drauf rerechnet.
         **/
        const score = currentRequirements.reduce(
            (acc, requirement) => {
                const satisfied = requirement.requirementCheckerFunction(newPassword);
                requirementsResults.push({label: requirement.label, satisfied})
                return satisfied ? acc + computedComplexityScore : acc;
            },
            passwordLengthTotalScore
        );
        setRequirementsState(({score, requirementsResults}));
    }, [newPassword, requirements]);

    return {...requirementsState, requirementsColor: getScoreColor(requirementsState.score)};
}
