import React from "react";
import {useCreateMitarbeiterMutation, useUpdateMitarbeiterMutation} from "../api/mitarbeiterApi";
import {Aktivitaetsstatus, CreateMitarbeiterDTO, Mitarbeiter, UpdateMitarbeiterDTO} from "../api/types";
import * as yup from "yup";
import {Grid, Typography} from "@mui/material";
import {FormikDialogForm} from "../../../components/formikDialogForm/components/FormikDialogForm";
import {DialogMode} from "../../../components/formikDialogForm/types";
import {FormikSelect} from "../../../components/formikInputs/FormikSelect";
import {FormikTextField} from "../../../components/formikInputs/FormikTextField";
import {FormikUserRoleField} from "../../../components/formikInputs/FormikUserRoleField";
import {MitarbeiterRole} from "../../auth/api/types";
import {VisibleIfAdmin} from "../../auth/components/VisibleIfAdmin";
import {FormikNumberField} from "../../../components/FormikNumberField";

export const createMitarbeiterDTO: CreateMitarbeiterDTO = {
    vorname: "",
    anrede: "",
    nachname: "",
    kuerzel: "",
    email: "",
    temporaryPassword: "",
    role: MitarbeiterRole.INTERN,
    confirmed: false,
    monatsarbeitsstunden: 0
}

export const createMitarbeiterValidationSchema = yup.object({
    anrede: yup.string().required("erforderlich"),
    vorname: yup.string().required("erforderlich"),
    nachname: yup.string().required("erforderlich"),
    kuerzel: yup.string().notRequired(),
    email: yup.string().email("muss Email sein").required("erforderlich"),
    temporaryPassword: yup.string().required("erforderlich"),
});

export const updateMitarbeiterValidationSchema = yup.object({
    anrede: yup.string().required("erforderlich"),
    vorname: yup.string().required("erforderlich"),
    nachname: yup.string().required("erforderlich"),
    kuerzel: yup.string().notRequired(),
    email: yup.string().email("muss Email sein").required("erforderlich"),
    monatsarbeitsstunden: yup.number().min(0, "Stunden pro Monat muss mindestens 0 sein")
});

export const MitarbeiterDialogForm = () => {

    const [createMitarbeiter, {isLoading: createMitarbeiterLoading}] = useCreateMitarbeiterMutation();
    const [updateMitarbeiter, {isLoading: updateMitarbeiterLoading}] = useUpdateMitarbeiterMutation();

    const loading = createMitarbeiterLoading || updateMitarbeiterLoading;

    const saveMitarbeiter = (dto: CreateMitarbeiterDTO | UpdateMitarbeiterDTO, mode: DialogMode): Promise<Mitarbeiter> => {
        let savePromise;
        if (mode === "create") {
            savePromise = createMitarbeiter(dto as CreateMitarbeiterDTO);
        } else {
            savePromise = updateMitarbeiter(dto as UpdateMitarbeiterDTO);
        }
        return savePromise.unwrap()
    };

    return (
        <FormikDialogForm
            dialogId={"mitarbeiter"}
            title={(mode) => mode === "create" ? "Neuer Mitarbeiter" : "Mitarbeiter bearbeiten"}
            onSubmit={saveMitarbeiter}
            loading={loading}
            creationInitialValues={createMitarbeiterDTO}
            validationSchema={(mode) => mode === "create" ? createMitarbeiterValidationSchema : updateMitarbeiterValidationSchema}
            form={
                (mode) => (
                    <div>
                        <Typography sx={{mb: 2}}>Stammdaten</Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>
                                <FormikSelect
                                    label={"Anrede"}
                                    name={"anrede"}
                                    required
                                    options={[
                                        {value: "Herr", label: "Herr"},
                                        {value: "Frau", label: "Frau"},
                                    ]}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <FormikTextField name="vorname" textFieldProps={{label: "Vorname", required: true}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <FormikTextField name="nachname" textFieldProps={{label: "Nachname", required: true}}/>
                            </Grid>
                            <Grid item xs={4}>
                                <FormikTextField name="kuerzel" textFieldProps={{label: "Kürzel", fullWidth: true}}/>
                            </Grid>
                            <Grid item xs={8}>
                                <VisibleIfAdmin>
                                    <FormikUserRoleField name="role" label="Rolle"/>
                                </VisibleIfAdmin>
                            </Grid>
                            <Grid item xs={12}>
                                <FormikTextField
                                    name="email"
                                    textFieldProps={{label: "Email", fullWidth: true, required: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikNumberField
                                    name="monatsarbeitsstunden"
                                    textFieldProps={{label: "Stunden pro Monat", fullWidth: true}}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FormikSelect
                                    label="Aktivitätsstatus"
                                    name="aktivitaetsstatus"
                                    options={[
                                        {label: Aktivitaetsstatus.AKTIV, value: Aktivitaetsstatus.AKTIV},
                                        {label: Aktivitaetsstatus.INAKTIV, value: Aktivitaetsstatus.INAKTIV}
                                    ]}/>
                            </Grid>
                            {mode === "create" &&
                                <Grid item xs={12}>
                                    <FormikTextField
                                        name="temporaryPassword"
                                        textFieldProps={{
                                            label: "Vorläufiges Passwort",
                                            fullWidth: true,
                                            required: true
                                        }}
                                    />
                                </Grid>}
                        </Grid>
                    </div>
                )
            }
        />
    );
}
