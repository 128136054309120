import {createApi} from '@reduxjs/toolkit/query/react'
import {
    ChangeMitarbeiterPasswordDto,
    ChangeOwnPasswordDto,
    CreateMitarbeiterDTO,
    Mitarbeiter,
    UpdateMitarbeiterDTO
} from './types'
import {baseQueryWithReauth} from "../../auth/api/api.utils";

export const mitarbeiterApi = createApi({
    reducerPath: 'mitarbeiterApi',
    tagTypes: ["Mitarbeiter"],
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createMitarbeiter: builder.mutation<Mitarbeiter, CreateMitarbeiterDTO>({
            query(body) {
                return {
                    url: "mitarbeiter",
                    method: "POST",
                    body
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Mitarbeiter', id: 'LIST'}] : []
        }),
        updateMitarbeiter: builder.mutation<Mitarbeiter, UpdateMitarbeiterDTO>({
            query({id, ...dto}) {
                return {
                    url: `mitarbeiter/${id}`,
                    method: "PATCH",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Mitarbeiter', id: 'LIST'}, {
                type: 'Mitarbeiter',
                id: result.id
            }, {
                type: 'Mitarbeiter',
                id: 'aktiv'
            }] : []
        }),
        findMitarbeiterById: builder.query<Mitarbeiter, string>({
            query: (id) => `mitarbeiter/${id}`,
            providesTags: (_r, _e, id) => [{type: 'Mitarbeiter', id}]
        }),
        findMitarbeiterByEmail: builder.query<Mitarbeiter, string>({
            query: (userId) => `mitarbeiter/by-userid/${userId}`,
            providesTags: (_r, _e, id) => [{type: 'Mitarbeiter', id}]
        }),
        findAllMitarbeiter: builder.query<Mitarbeiter[], void>({
            query: () => `mitarbeiter`,
            providesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Mitarbeiter', id} as const)),
                    {type: 'Mitarbeiter', id: 'LIST'},
                ]
                : [{type: 'Mitarbeiter', id: 'LIST'}],
        }),
        findAllActiveMitarbeiter: builder.query<Mitarbeiter[], void>({
            query: () => 'mitarbeiter/active',
            providesTags: result => result
                ? [...result.map(({id}) => ({type: 'Mitarbeiter', id} as const)),
                    {type: 'Mitarbeiter', id: 'aktiv'}]
                : [{type: 'Mitarbeiter', id: 'aktiv'}],
        }),
        changeOwnPassword: builder.mutation<Mitarbeiter, ChangeOwnPasswordDto>({
            query(dto) {
                return {
                    url: `mitarbeiter/change-own-password`,
                    method: "POST",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Mitarbeiter', id: result.id}] : []
        }),
        changeMitarbeiterPassword: builder.mutation<Mitarbeiter, ChangeMitarbeiterPasswordDto>({
            query(dto) {
                return {
                    url: `mitarbeiter/change-password`,
                    method: "POST",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Mitarbeiter', id: result.id}] : []
        })
    }),
})

export const {
    useCreateMitarbeiterMutation,
    useFindMitarbeiterByIdQuery,
    useFindMitarbeiterByEmailQuery,
    useFindAllMitarbeiterQuery,
    useFindAllActiveMitarbeiterQuery,
    useUpdateMitarbeiterMutation,
    useChangeOwnPasswordMutation,
    useChangeMitarbeiterPasswordMutation,
} = mitarbeiterApi
