import {Box} from "@mui/material";
import {FakturierbareAufwaendeTacho} from "../DashboardAufwandTacho";
import React from "react";
import {
    useFindAllAktivFakturierbarAufwandDaysQuery,
} from "../../aufwand/api/aufwandApi";

export const TachoTage = () => {

    const {data} = useFindAllAktivFakturierbarAufwandDaysQuery();
    const daten = data?.map(
        ({
             mitarbeiter_nachname,
             mitarbeiter_role,
             mitarbeiter_vorname,
             mitarbeiter_monatsarbeitsstunden,
             sum
         }) => (
            {
                name: `${mitarbeiter_vorname} ${mitarbeiter_nachname} (${mitarbeiter_role})`,
                value: Math.round((sum / 36000) / mitarbeiter_monatsarbeitsstunden)
            }
        ))

    const dateToday = new Date();
    dateToday.setDate(dateToday.getDate() - 30);
    const today30DaysAgo = dateToday.toLocaleString('de-DE', {
        day: "numeric",
        month: "long",
        year: "numeric"
    })

    const date = new Date();
    const today = date.toLocaleString('de-DE', {day: "numeric", month: "long", year: "numeric"});

    console.log(daten)

    return (
        <>
            <h2 style={{marginBottom: '-50px'}}>
                {today30DaysAgo} - {today}
            </h2>
            <Box display="flex" justifyContent="space-between">
                {daten?.map((chartData, chartNumber) => (
                    <FakturierbareAufwaendeTacho
                        chartData={chartData}
                        key={chartNumber}
                    />
                ))}
            </Box></>
    )
}
