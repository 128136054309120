import React, {FC} from "react";
import {Autocomplete, CircularProgress, TextField, TextFieldProps} from "@mui/material";
import {useFindAllMitarbeiterQuery} from "../../api/mitarbeiterApi";
import {Mitarbeiter} from "../../api/types";
import {formatName} from "../../../../components/formatUtils";

interface MitarbeiterAutocompleteProps {
    label: string;
    value?: string | null;
    onChange: (mitarbeiterId: string | null) => void;
    error?: string;
    required?: boolean;
    disableClearable?: boolean;
    variant?: TextFieldProps["variant"];
    excludeIds?: string[];
    disabled?: boolean;
}

export const MitarbeiterAutocomplete: FC<MitarbeiterAutocompleteProps> = ({
                                                                              disableClearable,
                                                                              value,
                                                                              onChange,
                                                                              label,
                                                                              error,
                                                                              required,
                                                                              variant,
                                                                              excludeIds,
                                                                              disabled
                                                                          }) => {


    const {data = [], isFetching: isFetchingList} = useFindAllMitarbeiterQuery();

    // TODO exclude ids mit api filter
    const mitarbeiter = data.filter((m) => !excludeIds?.includes(m.id))

    return (
        <Autocomplete
            disabled={disabled}
            disableClearable={disableClearable}
            value={mitarbeiter.find((k) => k.id === value) ?? null}
            renderInput={(params) =>
                <TextField
                    onClick={(e)=>{e.stopPropagation()}}
                    {...params}
                    label={label}
                    variant={variant ?? "outlined"}
                    error={Boolean(error)}
                    helperText={error}
                    required={required}
                    disabled={disabled}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isFetchingList && <CircularProgress color="inherit" size={20}/>}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        )
                    }}
                />
            }
            options={mitarbeiter}
            getOptionLabel={(o) => formatName(o, false)}
            onChange={(_, value) => {
                const mitarbeiter: Mitarbeiter | null = value;
                if (mitarbeiter) {
                    onChange(mitarbeiter.id);
                } else {
                    onChange(null);
                }
            }}
            noOptionsText="Nicht gefunden"
        />
    )
}
