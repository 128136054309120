import React, {FC, useCallback, useMemo} from "react";
import {Avatar, Box, CircularProgress, IconButton, ListItem, ListItemAvatar} from "@mui/material";
import * as yup from "yup";
import {
    CreateProjektMitarbeiterDTO,
    ProjektMitarbeiter,
    UpdateProjektMitarbeiterDTO
} from "../../../api/projektMitarbeiter.types";
import {Form, Formik} from "formik";
import {
    FormikMitarbeiterAutocomplete
} from "../../../../mitarbeiter/components/mitarbeiterAutocomplete/FormikMitarbeiterAutocomplete";
import {Check, Close, Engineering, Person} from "@mui/icons-material";
import {MitarbeiterLink} from "../../../../mitarbeiter/components/MitarbeiterLink";
import {useCreateProjektMitarbeiterMutation, useUpdateProjektMitarbeiterMutation} from "../../../api/projektApi";
import {FormikEuroInput} from "../../../../../components/formikInputs/FormikEuroInput";

const projektMitarbeiterValidationSchema = yup.object({
    mitarbeiterId: yup.string().required("notwendig"),
    projektId: yup.string().required("notwendig"),
    stundenSatzInEuroCent: yup.number().min(1, "notwendig").required("notwendig")
});

const emptyCreateMitarbeiterDTOInitialValues: CreateProjektMitarbeiterDTO = {
    mitarbeiterId: "",
    stundenSatzInEuroCent: 10000,
    projektId: "",
}

interface ProjectMitarbeiterFormProps {
    istPauschal: boolean;
    excludeMitarbeiterIdsFromAutocomplete?: string[];
    onClose: () => void;
    saveMode: "create" | "edit";
    isProjektleiter?: boolean;
    updateProjektMitarbeiterDTOinitialValues?: CreateProjektMitarbeiterDTO & { id: string };
    createProjektMitarbeiterDTOinitialValues?: Partial<CreateProjektMitarbeiterDTO>
}

export const ProjektMitarbeiterForm: FC<ProjectMitarbeiterFormProps> = ({
                                                                            istPauschal,
                                                                            saveMode,
                                                                            isProjektleiter,
                                                                            excludeMitarbeiterIdsFromAutocomplete,
                                                                            createProjektMitarbeiterDTOinitialValues,
                                                                            onClose,
                                                                            updateProjektMitarbeiterDTOinitialValues
                                                                        }) => {

    const [createProjektMitarbeiter, {isLoading: createMitarbeiterLoading}] = useCreateProjektMitarbeiterMutation();
    const [updateProjektMitarbeiter, {isLoading: updateMitarbeiterLoading}] = useUpdateProjektMitarbeiterMutation();

    const loading = createMitarbeiterLoading || updateMitarbeiterLoading;
    const modeIsCreate = saveMode === "create";

    const handleSave = useCallback(
        (dto: CreateProjektMitarbeiterDTO | UpdateProjektMitarbeiterDTO): Promise<ProjektMitarbeiter> =>
            modeIsCreate
                ? createProjektMitarbeiter(dto as CreateProjektMitarbeiterDTO).unwrap()
                : updateProjektMitarbeiter(dto as UpdateProjektMitarbeiterDTO).unwrap()
        , [createProjektMitarbeiter, updateProjektMitarbeiter, modeIsCreate]
    );

    const initialValues: CreateProjektMitarbeiterDTO | UpdateProjektMitarbeiterDTO = useMemo(
        () =>
            modeIsCreate
                ? {...emptyCreateMitarbeiterDTOInitialValues, ...createProjektMitarbeiterDTOinitialValues}
                : {...updateProjektMitarbeiterDTOinitialValues},
        [createProjektMitarbeiterDTOinitialValues, updateProjektMitarbeiterDTOinitialValues, modeIsCreate]
    )

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={projektMitarbeiterValidationSchema}
            onSubmit={(dto) => handleSave(dto).then(onClose)}
        >
            {({submitForm}) => (
                <Form>
                    <ListItem
                        sx={{pb: 3, pt: !modeIsCreate ? 3 : undefined}}
                        divider={true}
                        secondaryAction={
                            loading
                                ? <CircularProgress/>
                                : (
                                    <React.Fragment>
                                        <IconButton onClick={submitForm}>
                                            <Check color="success"/>
                                        </IconButton>
                                        <IconButton onClick={onClose} edge="end">
                                            <Close color="error"/>
                                        </IconButton>
                                    </React.Fragment>
                                )}
                    >
                        <ListItemAvatar>
                            <Avatar sx={{bgcolor: isProjektleiter ? "secondary.main" : undefined}}>
                                {isProjektleiter
                                    ? <Engineering sx={{fontSize: "1.9rem"}}/>
                                    : <Person sx={{fontSize: "2.0rem"}}/>}
                            </Avatar>
                        </ListItemAvatar>
                        <Box sx={{pl: 4, display: "flex", flexDirection: "row"}}>
                            <Box width={224} mr={2}>
                                {modeIsCreate
                                    ? (
                                        <FormikMitarbeiterAutocomplete
                                            variant="standard"
                                            name="mitarbeiterId"
                                            label="Mitarbeiter"
                                            excludeIds={excludeMitarbeiterIdsFromAutocomplete}
                                        />
                                    )
                                    : (
                                        <MitarbeiterLink
                                            id={updateProjektMitarbeiterDTOinitialValues?.mitarbeiterId ?? ""}
                                            label={isProjektleiter ? "Projektleiter" : "Mitarbeiter"}
                                        />
                                    )
                                }
                            </Box>
                            {!istPauschal &&
                                <FormikEuroInput
                                    name="stundenSatzInEuroCent"
                                    textFieldProps={{
                                        variant: "standard",
                                        label: "Stundensatz",
                                        fullWidth: true,
                                        required: true
                                    }}
                                />}
                        </Box>
                    </ListItem>
                </Form>
            )}
        </Formik>
    )
}
