import React, {FC} from "react";
import {Box, BoxProps, Stack, Typography} from "@mui/material";
import {AppDate, formatDate} from "../formatUtils/dateFormatUtils";

export interface PlainEntityDetailsHeaderProps {
    title: React.ReactNode;
    createdAt: AppDate;
    updatedAt: AppDate;
    containerBoxProps?: BoxProps;
}

export const PlainEntityDetailsHeader: FC<PlainEntityDetailsHeaderProps> = (props) => (
    <Box sx={{display: "flex", justifyContent: "space-between"}} {...props.containerBoxProps}>
        <Typography variant="h4" sx={{mb: 1}}>{props.title}</Typography>
        <Box>
            <Stack direction="row" alignItems="center">
                <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    sx={{width: 150}}
                >
                    angelegt am
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="textSecondary"
                >
                    {formatDate(props.createdAt)}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="center">
                <Typography
                    variant="subtitle2"
                    color="textSecondary"
                    sx={{width: 150}}
                >
                    zuletzt geändert am
                </Typography>
                <Typography
                    variant="subtitle1"
                    color="textSecondary"
                >
                    {formatDate(props.updatedAt)}
                </Typography>
            </Stack>
        </Box>
    </Box>
);
