import React, {FC} from "react";
import {
    Avatar,
    Box,
    CircularProgress,
    IconButton,
    ListItem,
    ListItemAvatar,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem
} from "@mui/material";
import {Delete, Edit, Engineering, MoreVert, Person} from "@mui/icons-material";
import {MitarbeiterLink} from "../../../../mitarbeiter/components/MitarbeiterLink";
import {ProjektMitarbeiter} from "../../../api/projektMitarbeiter.types";
import {ProjektMitarbeiterForm} from "./ProjektMitarbeiterForm";
import {useDeleteProjektMitarbeiterMutation, useUpdateProjektMutation} from "../../../api/projektApi";
import {VisibleIfAdmin} from "../../../../auth/components/VisibleIfAdmin";
import {EuroDisplay} from "../../../../../components/euroDisplay/EuroDisplay";

interface ProjektMitarbeiterListItemProps {
    projektMitarbeiter: ProjektMitarbeiter;
    isProjektleiter: boolean;
    istPauschal: boolean;
}

export const ProjektMitarbeiterListItem: FC<ProjektMitarbeiterListItemProps> = ({
                                                                                    projektMitarbeiter,
                                                                                    isProjektleiter,
                                                                                    istPauschal
                                                                                }) => {

    const {mitarbeiter, creationDate, updateDate, ...pureProjektMitarbeiter} = projektMitarbeiter;

    const [updateProjekt, {isLoading: updateProjektLoading}] = useUpdateProjektMutation();
    const [deleteProjektMitarbeiter, {isLoading: deleteProjektMitarbeiterLoading}] = useDeleteProjektMitarbeiterMutation();

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(menuAnchorEl);
    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setMenuAnchorEl(null);

    const [showEditStundensatz, setShowEditStundensatz] = React.useState(false);

    return (
        showEditStundensatz
            ? <ProjektMitarbeiterForm
                istPauschal={istPauschal}
                saveMode="edit"
                isProjektleiter={isProjektleiter}
                updateProjektMitarbeiterDTOinitialValues={pureProjektMitarbeiter}
                onClose={() => setShowEditStundensatz(false)}
            />
            : (
                <ListItem
                    divider={true}
                    sx={(theme) => ({
                        bgcolor: isProjektleiter ? theme.palette.grey["50"] : undefined,
                        paddingRight: 0
                    })}
                    secondaryAction={
                        deleteProjektMitarbeiterLoading || updateProjektLoading
                            ? <CircularProgress/>
                            : (
                                <VisibleIfAdmin>
                                    {((!istPauschal) || !isProjektleiter) && <IconButton
                                        edge="end"
                                        id="projekt-mitarbeiter-menu-button"
                                        aria-controls="projekt-mitarbeiter-menu"
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                        onClick={handleOpenMenu}
                                    >
                                        <MoreVert/>
                                    </IconButton>}
                                    <Menu
                                        id="projekt-mitarbeiter-menu"
                                        anchorEl={menuAnchorEl}
                                        open={open}
                                        onClose={handleCloseMenu}
                                        anchorOrigin={{horizontal: "left", vertical: "bottom"}}
                                        MenuListProps={{
                                            'aria-labelledby': 'projekt-mitarbeiter-menu-button',
                                        }}
                                    >
                                        {!istPauschal &&
                                            <MenuItem
                                                onClick={() => {
                                                    setShowEditStundensatz(true);
                                                    handleCloseMenu();
                                                }}
                                            >
                                                <ListItemIcon>
                                                    <Edit/>
                                                </ListItemIcon>

                                                <ListItemText>
                                                    Bearbeiten
                                                </ListItemText>
                                            </MenuItem>}
                                        {!isProjektleiter &&
                                            <React.Fragment>
                                                <MenuItem
                                                    onClick={() => {
                                                        deleteProjektMitarbeiter(projektMitarbeiter.id);
                                                        handleCloseMenu();
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <Delete/>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Entfernen
                                                    </ListItemText>
                                                </MenuItem>
                                                <MenuItem
                                                    onClick={() => {
                                                        updateProjekt({
                                                            id: projektMitarbeiter.projektId,
                                                            projektLeiterId: projektMitarbeiter.mitarbeiterId
                                                        })
                                                        handleCloseMenu();
                                                    }}
                                                >
                                                    <ListItemIcon>
                                                        <Engineering/>
                                                    </ListItemIcon>
                                                    <ListItemText>
                                                        Zu Projektleiter machen
                                                    </ListItemText>
                                                </MenuItem>
                                            </React.Fragment>}
                                    </Menu>
                                </VisibleIfAdmin>
                            )
                    }
                >
                    <ListItemAvatar>
                        <Avatar sx={{bgcolor: isProjektleiter ? "secondary.main" : undefined}}>
                            {isProjektleiter
                                ? <Engineering sx={{fontSize: "1.9rem"}}/>
                                : <Person sx={{fontSize: "2.0rem"}}/>}
                        </Avatar>
                    </ListItemAvatar>
                    <Box sx={{pl: 4, display: "flex", flexDirection: "row"}}>
                        <Box width={240}>
                            <MitarbeiterLink
                                id={projektMitarbeiter.mitarbeiterId}
                                label={isProjektleiter ? "Projektleiter" : "Projektmitarbeiter"}
                                captionProps={{sx: {color: isProjektleiter ? "secondary.main" : "text.secondary"}}}
                            />
                        </Box>
                    </Box>
                    {!istPauschal &&
                        <EuroDisplay
                            valueInEuroCent={projektMitarbeiter.stundenSatzInEuroCent}
                        />
                    }
                </ListItem>
            )
    )
}
