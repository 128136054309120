import React, {FC} from "react";
import {RequireAuth} from "../components/dcbApp/RequreAuth";
import {ExternAppProjekte} from "./ExternAppProjekte";
import {AppBar} from "../components/mainFrame/AppBar";
import logo from "../logo.svg";
import {Box, Toolbar} from "@mui/material";
import {AufwandDialogForm} from "../features/aufwand/save/AufwandDialogForm";
import {MitarbeiterDialogForm} from "../features/mitarbeiter/save/MitarbeiterDialogForm";
import {useNavigate} from "react-router-dom";
import {useMitarbeiterRole} from "../features/auth/api/auth.hook";
import {MitarbeiterRole} from "../features/auth/api/types";


const useGuardExternApp = () => {
    const navigate = useNavigate();
    const role = useMitarbeiterRole();

    React.useEffect(() => {
        if (role === MitarbeiterRole.ADMIN || role === MitarbeiterRole.INTERN) {
            console.log("NAV TO INT");
            navigate('/intern');
        }
    })
};

export const ExternApp: FC<{ hasAccessToken: boolean }> = ({hasAccessToken}) => {
    useGuardExternApp()
    return (
        <RequireAuth loggedIn={hasAccessToken}>
            <Box height="100vh" sx={{background: (theme) => theme.palette.grey["50"]}}>
                <AufwandDialogForm forExtern/>
                <MitarbeiterDialogForm/>
                <AppBar forExtern logo={<img src={logo} alt="Logo" style={{height: "42px"}}/>}/>
                <Toolbar/>
                <ExternAppProjekte/>
            </Box>
        </RequireAuth>
    )
}
