import React, {FC} from "react";
import {Button, Typography} from "@mui/material";
import {Add} from "@mui/icons-material";
import {ProjektTable} from "./ProjektTable";
import {useNavigateDialogForm} from "../../../components/formikDialogForm/dialogForm.hook";
import {VisibleIfAdmin} from "../../auth/components/VisibleIfAdmin";

export const ProjektListScreen: FC = () => {
    const navigateDialog = useNavigateDialogForm("projekt", "create");

    return (
        <div>
            <Typography variant="h4" sx={{mb: 3}}>Projekte</Typography>
            <VisibleIfAdmin>
                <Button
                    variant="contained"
                    color="secondary"
                    startIcon={<Add/>}
                    onClick={() => navigateDialog()}
                    sx={{mb: 3}}
                >
                    Neues Projekt
                </Button>
            </VisibleIfAdmin>
            <ProjektTable/>
        </div>
    )
}
