import React, {FC} from "react";
import {useFindAllProjektOfMitarbeiterQuery} from "../api/projektApi";
import {AppDataGrid} from "../../../components/table/AppDataGrid";
import {GridColDef} from "@mui/x-data-grid-pro";
import {KundeLink} from "../../kunde/components/KundeLink";
import {MitarbeiterLink} from "../../mitarbeiter/components/MitarbeiterLink";
import {formatDate} from "../../../components/formatUtils/dateFormatUtils";

const projektTableColumns: GridColDef[] = [
    {
        field: 'name',
        headerName: 'Name',
        flex: 3
    },
    {
        field: 'kurzbeschreibung',
        headerName: 'Kurzbeschreibung',
        flex: 4,
        type: "string"
    },
    {
        field: 'kundeId',
        headerName: 'Kunde',
        flex: 2,
        renderCell: (params) => <KundeLink id={params.value}/>
    },
    {
        field: 'projektLeiterId',
        headerName: 'Projektleiter',
        flex: 2,
        renderCell: (params) => params.value && <MitarbeiterLink id={params.value}/>
    },
    {
        field: 'rechnungszyklus',
        headerName: 'Rechnungszyklus',
        flex: 2,
        type: "number"
    },
    {
        field: 'istPauschal',
        headerName: 'Pauschal',
        flex: 1,
        type: "boolean"
    },
    {
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

interface ProjektTableForMitarbeiterProps {
    mitarbeiterId: string;
}

export const ProjektTableForMitarbeiter: FC<ProjektTableForMitarbeiterProps> = ({mitarbeiterId}) => {

    const {data, isFetching} = useFindAllProjektOfMitarbeiterQuery({mitarbeiterId});

    return (
        <AppDataGrid loading={isFetching} columns={projektTableColumns} rows={data?.data ?? []}/>
    )
}
