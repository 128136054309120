import {createApi} from '@reduxjs/toolkit/query/react';
import {
    AufwaendeZusammenfassungDTO,
    Aufwand,
    CreateAufwandDTO,
    DeleteManyAufwaendeDTO,
    GetAufwandStatisticDTO, RechnungsnummerVergebenDTO,
    UpdateAufwandDTO,
    UpdateManyAufwaendeDTO
} from "./types";
import {ListQueryArgs} from "../../../components/types";
import {baseQueryWithReauth} from "../../auth/api/api.utils";
import {MitarbeiterRole} from "../../auth/api/types";

export interface PaginationState {
    page: number;
    pageSize: number;
}

export interface AufwandListResult {
    data: Aufwand[];
    pagination: {
        count: number;
        pageSize: number;
        page: number;
    }
}

export interface AufwandApiFilters {
    projektId?: {
        operator: "equal";
        value: string;
    },
    mitarbeiterId?: {
        operator: "equal";
        value: string;
    },
    ticketId?: {
        operator: "like";
        value: string;
    },
    datum?: {
        operator: "between";
        from: string;
        to: string;
    },
    istFakturierbar?: {
        operator: "equal";
        value: boolean;
    },
    rechnungsnummer?: {
        operator: "isNull" | "isNotNull";
    },
}

export interface AufwandApiQueryParams {
    filter?: AufwandApiFilters;
    pagination?: PaginationState;
    sort?: { [key: string]: "asc" | "desc" };
}

export interface DeleteManyAufwandResult {
    affected: number;
}

export interface AufwaendeZusammenfassungResult {
    projektId: string;
    mitarbeiterId: string;
    dauerInMillis: number;
    stundenSatzInEuroCent: number;
    summeInEuroCent: number;
}

export interface AufwaendeAktivFakturierbar {
    aufwand_mitarbeiterId: string;
    mitarbeiter_nachname: string;
    mitarbeiter_role: MitarbeiterRole;
    mitarbeiter_vorname: string;
    mitarbeiter_monatsarbeitsstunden: number;
    sum: number;
}

export interface GetAufwandStatisticResult {
    allAufwaendeCount: number;
    mitarbeiterAufwaendeCount: number;
}

function isValidDate(d: string) {
    const date = new Date(d)
    return date instanceof Date && !isNaN(date as any);
}

export interface AufwandListResult {
    data: Aufwand[];
    pagination: {
        count: number;
        pageSize: number;
        page: number;
    }
}

export const aufwandApi = createApi({
    reducerPath: 'aufwandApi',
    tagTypes: ["Aufwand", "AufwaendeStats", "AufwaendeZusammenfassung"] as const,
    baseQuery: baseQueryWithReauth,
    endpoints: (builder) => ({
        createAufwand: builder.mutation<Aufwand, CreateAufwandDTO>({
            query(body) {
                return {
                    url: "aufwand",
                    method: "POST",
                    body
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Aufwand' as const, id: 'LIST'}] : []
        }),
        updateAufwand: builder.mutation<Aufwand, UpdateAufwandDTO>({
            query({id, ...dto}) {
                return {
                    url: `aufwand/${id}`,
                    method: "PATCH",
                    body: dto
                }
            },
            invalidatesTags: (result) => result ? [{type: 'Aufwand', id: 'LIST'}, {type: 'Aufwand', id: result.id}] : []
        }),
        updateManyAufwaende: builder.mutation<Aufwand[], UpdateManyAufwaendeDTO>({
            query(dto) {
                return {
                    url: `aufwand/update-many`,
                    method: "POST",
                    body: dto
                }
            },
            invalidatesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Aufwand', id} as const)),
                    {type: 'Aufwand', id: 'LIST'},
                ]
                : [{type: 'Aufwand', id: 'LIST'}],
        }),
        rechnungsnummerVergeben: builder.mutation<Aufwand[], RechnungsnummerVergebenDTO>({
            query(dto) {
                return {
                    url: `aufwand/rechnungsnummer-vergeben`,
                    method: "POST",
                    body: dto
                }
            },
            invalidatesTags: (result) => result
                ? [
                    ...result.map(({id}) => ({type: 'Aufwand', id} as const)),
                    {type: 'Aufwand', id: 'LIST'},
                ]
                : [{type: 'Aufwand', id: 'LIST'}],
        }),
        deleteManyAufwaende: builder.mutation<DeleteManyAufwandResult, DeleteManyAufwaendeDTO>({
            query(dto) {
                return {
                    url: `aufwand/delete-many`,
                    method: "POST",
                    body: dto
                }
            },
            invalidatesTags: () => [{type: 'Aufwand', id: 'LIST'}],
        }),
        aufwaendeZusammenfassung: builder.query<AufwaendeZusammenfassungResult[], AufwaendeZusammenfassungDTO>({
            query(dto) {
                return {
                    url: `aufwand/zusammenfassung`,
                    method: "POST",
                    body: dto
                }
            },
            providesTags: () => [{type: 'AufwaendeZusammenfassung', id: 'LIST'}],
        }),
        findAufwandById: builder.query<Aufwand, string>({
            query: (id) => `aufwand/${id}`,
            providesTags: (_r, _e, id) => [{type: 'Aufwand', id}]
        }),
        findAllAktivFakturierbarAufwandDays: builder.query<AufwaendeAktivFakturierbar[], void>({
            query: () => `aufwand/fakturierbar-days`
        }),
        findAllAktivFakturierbarAufwandMonth: builder.query<AufwaendeAktivFakturierbar[], void>({
            query: () => `aufwand/fakturierbar-month`
        }),
        findAllAufwand: builder.query<AufwandListResult, void | AufwandApiQueryParams>({
            query: (arg) => {
                if (!arg) {
                    return "aufwand";
                }

                const mutableArg = JSON.parse(JSON.stringify(arg)) as AufwandApiQueryParams;
                if (
                    mutableArg.filter &&
                    mutableArg.filter.datum &&
                    (!isValidDate(mutableArg.filter.datum.from) || !isValidDate(mutableArg.filter.datum.from))
                ) {
                    delete mutableArg.filter.datum;
                }

                const queryString = Object
                    .entries(mutableArg)
                    .filter(([, v]) => v)
                    .map(([key, value], paramsIndex) =>
                        `${paramsIndex === 0 ? "?" : "&"}${key}=${encodeURIComponent(JSON.stringify(value))}`
                    );

                return `aufwand${queryString.join("")}`
            },
            providesTags: (result) => result
                ? [
                    ...result.data.map(({id}) => ({type: 'Aufwand', id} as const)),
                    {type: 'Aufwand', id: 'LIST'},
                ]
                : [{type: 'Aufwand', id: 'LIST'}],
        }),
        findAllOwnedAufwand: builder.query<AufwandListResult, ({ projektId: string } & ListQueryArgs<Aufwand>)>({
            query: ({projektId, ...filterArgs}) => {
                const queryString = Object
                    .entries(filterArgs)
                    .filter(([, v]) => v)
                    .map(([key, value], paramsIndex) =>
                        `${paramsIndex === 0 ? "?" : "&"}${key}=${JSON.stringify(value)}`
                    );
                return `aufwand/owned/${projektId}${queryString.join("")}`
            },
            providesTags: (result) => result
                ? [
                    ...result.data?.map(({id}) => ({type: 'Aufwand', id} as const)),
                    {type: 'Aufwand', id: 'LIST'},
                ]
                : [{type: 'Aufwand', id: 'LIST'}],
        }),
        getAufwandStatistic: builder.query<GetAufwandStatisticResult, GetAufwandStatisticDTO>({
            query: (dto) => `aufwand/statistics?from=${dto.from}&to=${dto.to}`,
            providesTags: (result) => result
                ? [
                    {type: 'AufwaendeStats', id: 'stats'},
                ]
                : [{type: 'AufwaendeStats', id: 'stats'}],
        })
    }),
})

export const {
    useCreateAufwandMutation,
    useFindAufwandByIdQuery,
    useFindAllAufwandQuery,
    useUpdateAufwandMutation,
    useUpdateManyAufwaendeMutation,
    useRechnungsnummerVergebenMutation,
    useFindAllOwnedAufwandQuery,
    useDeleteManyAufwaendeMutation,
    useGetAufwandStatisticQuery,
    useAufwaendeZusammenfassungQuery,
    useFindAllAktivFakturierbarAufwandMonthQuery,
    useFindAllAktivFakturierbarAufwandDaysQuery
} = aufwandApi;
