import React, {FC} from "react";
import {GridColDef} from "@mui/x-data-grid-pro";
import {useFindAllKundeQuery} from "../api/kundeApi";
import {AppDataGrid} from "../../../components/table/AppDataGrid";
import {formatDate} from "../../../components/formatUtils/dateFormatUtils";
import {Kunde} from "../api/types";
import {formatKundeAdresse} from "../../../components/formatUtils";

export const kundeTableColumns: GridColDef[] = [
    {
        field: 'organisation',
        headerName: 'Organisation',
        flex: 2,
    },
    {
        field: 'operativerAnsprechpartner',
        headerName: 'Operativer Ansprechpartner',
        flex: 2
    },
    {
        field: 'strasse',
        headerName: 'Strasse',
        flex: 1,
        hide: true
    },
    {
        field: 'hausnummer',
        headerName: 'Hausnummer',
        flex: 1,
        hide: true
    },
    {
        field: 'adresszusatz',
        headerName: 'Adresszusatz',
        flex: 1,
        hide: true
    },
    {
        field: 'postleitzahl',
        headerName: 'Postleitzahl',
        flex: 1,
        hide: true
    },
    {
        field: 'stadt',
        headerName: 'Stadt',
        flex: 1,
        hide: true
    },
    {
        field: 'adresse',
        headerName: 'Adresse',
        flex: 1,
        valueGetter: params => formatKundeAdresse(params.row as Kunde)
    },
    {
        field: 'creationDate',
        headerName: 'Angelegt am',
        flex: 1,
        type: "date",
        valueFormatter: params => formatDate(params.value as string)
    }
];

export const KundeTable: FC = () => {
    const {data: kunden = [], isFetching} = useFindAllKundeQuery();

    return (
        <AppDataGrid
            checkboxSelection={true}
            loading={isFetching}
            columns={kundeTableColumns}
            rows={kunden}
        />
    );
};
