import React, {FC} from "react";
import {DatenschutzEntity, UpdateDatenschutzDTO} from "./types";
import {FormikDialogForm} from "../../components/formikDialogForm/components/FormikDialogForm";
import {useUpdateDefaultDatenschutzMutation} from "./datenschutzApi";
import {FormikQuill} from "../../components/FormikQuill";
import {Box} from "@mui/material";
import * as yup from "yup";

export const DatenschutzDialogForm: FC = () => {

    const [updateDatenschutz, {isLoading: updateIsLoading}] = useUpdateDefaultDatenschutzMutation();

    const saveDatenschutzText = (dto: UpdateDatenschutzDTO): Promise<DatenschutzEntity> => {
        return updateDatenschutz(dto.content ?? "").unwrap();
    };

    return (
        <FormikDialogForm
            dialogId={"datenschutz"}
            title={"Datenschutz Editor"}
            onSubmit={saveDatenschutzText}
            creationInitialValues={{content: ""}}
            validationSchema={yup.object({content: yup.string().required()})}
            loading={updateIsLoading}
            form={
                <Box>
                    <FormikQuill fieldName={"content"}/>
                </Box>
            }
        />
    )
}