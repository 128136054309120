import React, {FC} from "react";
import {Typography, TypographyProps} from "@mui/material";
import {NumericFormat} from "react-number-format";

interface EuroDisplayProps {
    label?: string;
    valueInEuroCent: number;
    captionProps?: TypographyProps;
    displayProps?: TypographyProps;
    suffix?: string;
}

export const EuroDisplay: FC<EuroDisplayProps> = (props) => {
    return (
        <Typography variant="caption" color="text.secondary">
            {props.label}
            <Typography color="text.primary">
                <NumericFormat
                    value={props.valueInEuroCent / 100}
                    displayType="text"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    allowLeadingZeros={true}
                    decimalSeparator={","}
                    suffix={props.suffix ?? " €/h"}
                />
            </Typography>
        </Typography>
    )
}
