import React, {FC} from "react";
import {Projekt} from "../../../api/projekt.types";
import {Card, CardContent, Collapse, List} from "@mui/material";
import {CardTitleWithEdit} from "../../../../../components/cardTitleWithEdit/CardTitleWithEdit";
import {ProjektMitarbeiterForm} from "./ProjektMitarbeiterForm";
import _ from "lodash";
import {ProjektMitarbeiterListItem} from "./ProjektMitarbeiterListItem";
import {Add} from "@mui/icons-material";
import {useMitarbeiterRole} from "../../../../auth/api/auth.hook";
import {MitarbeiterRole} from "../../../../auth/api/types";
import {useFindAllProjektMitarbeiterQuery} from "../../../api/projektApi";

export const ProjektMitarbeiterCard: FC<{ projekt: Projekt }> = ({projekt}) => {

    const userRole = useMitarbeiterRole();

    const {data} = useFindAllProjektMitarbeiterQuery({
        filter: {
            projektId: {
                value: projekt.id,
                operator: "equal"
            }
        }
    });

    const [showAddMitarbeiterForm, setShowAddMitarbeiterForm] = React.useState(false);
    const toggleMitarbeiterForm = () => setShowAddMitarbeiterForm(p => !p);

    const excludeMitarbeiterIds = data?.data.map(({mitarbeiterId}) => mitarbeiterId) ?? [];

    return (
        <Card>
            <CardContent>
                <CardTitleWithEdit
                    title={"Projektmitarbeiter"}
                    optionLabel={"Projektmitarbeiter hinzufügen"}
                    optionIcon={<Add/>}
                    onClickEdit={userRole === MitarbeiterRole.ADMIN ? toggleMitarbeiterForm : undefined}
                />
                <List dense>
                    <Collapse in={showAddMitarbeiterForm}>
                        <ProjektMitarbeiterForm
                            istPauschal={projekt.istPauschal}
                            key={"projekt-mitarbeiter-form"}
                            saveMode="create"
                            onClose={toggleMitarbeiterForm}
                            createProjektMitarbeiterDTOinitialValues={{projektId: projekt.id}}
                            excludeMitarbeiterIdsFromAutocomplete={excludeMitarbeiterIds}
                        />
                    </Collapse>
                    {data && _.sortBy(data.data, ({mitarbeiterId}) => mitarbeiterId === projekt.projektLeiterId ? 0 : 1)
                        .map((pM, index) => (
                            <ProjektMitarbeiterListItem
                                istPauschal={projekt.istPauschal}
                                key={pM.id}
                                isProjektleiter={index === 0}
                                projektMitarbeiter={pM}
                            />
                        ))}
                </List>
            </CardContent>
        </Card>
    )
}
