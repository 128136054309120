import {createApi} from '@reduxjs/toolkit/query/react'
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {MitarbeiterRole} from "./types";

export interface LoginResponse {
    user_id: string;
    access_token: string;
    refresh_token: string;
    role: MitarbeiterRole;
}

export interface LoginRequest {
    username: string
    password: string
}

export const authApi = createApi({
    baseQuery: fetchBaseQuery({baseUrl: window.API_BASE_URL}),
    endpoints: (builder) => ({
        login: builder.mutation<LoginResponse, LoginRequest>({
            query: (credentials) => ({
                url: 'auth/login',
                method: 'POST',
                body: credentials,
            }),
        })
    }),
})

export const {useLoginMutation} = authApi;
