import React, {FC, useState} from "react";
import {useLoginMutation} from "../auth/api/authApi";
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Container,
    Grid,
    LinearProgress, Link,
    Snackbar, TextField,
    Typography
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';
import Avatar from "@mui/material/Avatar";
import {MitarbeiterRole} from "../auth/api/types";
import {DatenschutzLoginDialog} from "../datenschutz/DatenschutzLoginDialog";

export const LoginScreen: FC = () => {
    const [snackBarOpen, setSnackBarOpen] = useState<boolean>(false);
    const navigate = useNavigate();
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const [, setError] = React.useState<string | null>(null);

    const [login, {isLoading}] = useLoginMutation();

    const handleLogin = () => {
        login({username: email, password})
            .unwrap()
            .then((res) => {
                if (res) {
                    const from = res.role === MitarbeiterRole.EXTERN ? "/extern" : "/intern";
                    // navigate(from, {replace: true})

                    // Workaround for race condition
                    setTimeout(() => navigate(from, {replace: true}), 1000);
                }
            })
            .catch((err) => {
                setError(err?.data?.message)
                setSnackBarOpen(true)
            })
    }


    return (
        <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            sx={{minHeight: '100vh', backgroundColor: (theme) => theme.palette.grey["50"]}}>
            <Container maxWidth={"xs"}>
                {isLoading && <LinearProgress/>}
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Avatar>
                        <LoginIcon/>
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Anmelden
                    </Typography>
                    <Box>
                        <TextField
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    // Do code here
                                    // TODO wenn enter dann auf PW-Feld wechseln
                                    console.log("ENTER? mail")
                                    ev.preventDefault();
                                }
                            }}
                            margin="normal"
                            required
                            fullWidth
                            label="Email"
                            type="email"
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />
                        <TextField
                            onKeyPress={(ev) => {
                                if (ev.key === 'Enter') {
                                    handleLogin()
                                    ev.preventDefault();
                                }
                            }}
                            margin="normal"
                            required
                            fullWidth
                            label="Password"
                            type='password'
                            onChange={(e) => setPassword(e.target.value)}
                            value={password}
                        />
                        <Snackbar anchorOrigin={{vertical: "top", horizontal: "center"}}
                                  open={snackBarOpen}
                                  message="Anmeldung Fehlgeschlagen">
                            <Alert severity="error">
                                <AlertTitle>Anmeldung fehlgeschlagen!</AlertTitle>
                                Bitte überprüfen Sie Ihre Eingaben!
                            </Alert>
                        </Snackbar>
                        <Button variant="contained"
                                onClick={handleLogin}
                                fullWidth
                                sx={{marginBottom: 1}}>
                            Login
                        </Button>
                        <Box
                            sx={{display: "flex", justifyContent: "space-between"}}>
                            {/*TODO PW vergessen, Datenschutz etc*/}
                            <DatenschutzLoginDialog/>
                            <Link
                                href='https://auriscon.info/impressum'
                                target="_blank"
                                underline='none'
                            >
                                Impressum
                            </Link>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </Grid>
    )
}
