import {People as MitarbeiterIcon} from "@mui/icons-material";
import {MitarbeiterListScreen} from "./list/MitarbeiterListScreen";
import {MitarbeiterViewScreen} from "./view/MitarbeiterViewScreen";
import {MitarbeiterDialogForm} from "./save/MitarbeiterDialogForm";
import React from "react";
import {AppFeature} from "../../components/dcbApp/types";

export const mitarbeiterFeature: AppFeature = {
    path: "mitarbeiter",
    label: "Mitarbeiter",
    icon: <MitarbeiterIcon sx={{color: "secondary.main"}}/>,
    list: <MitarbeiterListScreen/>,
    view: <MitarbeiterViewScreen/>,
    dialogs: [<MitarbeiterDialogForm/>]
};
