import React, {FC} from "react";
import {useAppDispatch, useAppSelector} from "../../state/redux.hooks";
import {clearError, selectError} from "./errorSlice";
import {Alert, AlertTitle, Snackbar} from "@mui/material";

const ErrorMessages: FC<{ messages: string[] }> = ({messages}) => (
    <ul>
        {messages.map((m, i) =>
            <li key={"error-message-" + i}>
                {m}
            </li>
        )}
    </ul>
)

const ErrorAlert403: FC<{ onClose: () => void }> = ({onClose}) => {
    return (
        <Alert onClose={onClose} severity="error" sx={{width: '100%'}}>
            <AlertTitle>Nicht Autorisiert</AlertTitle>
            Sie sind nicht berechtigt dies auszuführen.
            Bitte wenden Sie sich an einen Admin.
        </Alert>
    )
}

export const ErrorSnackbar: FC = () => {

    const dispatch = useAppDispatch();
    const error = useAppSelector(selectError);

    const onClose = () => dispatch(clearError());

    return (
        <Snackbar
            open={Boolean(error)}
            autoHideDuration={8000}
            onClose={() => dispatch(clearError())}
            anchorOrigin={{horizontal: "center", vertical: "top"}}
        >
            <div>
                {error?.statusCode === 403
                    ? (<ErrorAlert403 onClose={onClose}/>)
                    : (
                        <Alert onClose={onClose} severity="error" sx={{width: '100%'}}>
                            <AlertTitle>{error?.error} ({error?.statusCode})</AlertTitle>
                            {Array.isArray(error?.message) ?
                                <ErrorMessages messages={error?.message ?? []}/> : error?.message}
                        </Alert>
                    )}
            </div>
        </Snackbar>
    )
}
