import React, {FC} from "react";
import {Typography} from "@mui/material";
import {AufwandTable} from "./AufwandTable";

export const AufwandListScreen: FC = () => {
    return (
        <div>
            <Typography variant="h4" sx={{mb: 3}}>Aufwände</Typography>
            <AufwandTable/>
        </div>
    )
}
