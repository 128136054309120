import {AssignmentTurnedIn as AufwaendeIcon} from "@mui/icons-material";
import React from "react";
import {AufwandListScreen} from "./list/AufwandListScreen";
import {AufwandDialogForm} from "./save/AufwandDialogForm";
import {AppFeature} from "../../components/dcbApp/types";

export const aufwandFeature: AppFeature = {
    path: "aufwand",
    label: "Aufwände",
    icon: <AufwaendeIcon sx={{color: "secondary.main"}}/>,
    list: <AufwandListScreen/>,
    dialogs: [<AufwandDialogForm/>]
}
