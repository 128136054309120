import React from "react";
import {Form, Formik, FormikProps} from "formik";
import {useDialogFormState} from "../dialogForm.hook";
import {Dialog, DialogContent, DialogTitle, LinearProgress} from "@mui/material";
import {DialogActions} from "./DialogActions";
import {DialogMode} from "../types";
import {ObjectSchema} from "yup";

export interface FormikDialogFormProps<T, C, U> {
    dialogId: string;
    title: React.ReactNode | ((mode: DialogMode) => JSX.Element);
    onSubmit: (input: C | U, mode: DialogMode) => Promise<T>;
    creationInitialValues: C;
    validationSchema: ((mode: DialogMode) => ObjectSchema<any>) | ObjectSchema<any>;
    loading: boolean;
    form: JSX.Element | ((mode: DialogMode) => JSX.Element);
    formikProps?: FormikProps<C | U>;
}

export function FormikDialogForm<T, C, U>(props: FormikDialogFormProps<T, C, U>) {

    const {open, closeDialog, initialValues, mode} = useDialogFormState<U>(props.dialogId);

    const handleSubmit = (dto: C | U) =>
        props.onSubmit(dto, mode)
            .then((mitarbeiter) => {
                if (mitarbeiter) {
                    closeDialog();
                }
            });

    return (
        <Dialog open={open} onClose={closeDialog}
                maxWidth={"lg"}
                fullWidth={true}>
            <DialogTitle>
                {typeof props.title === "function"
                    ? props.title(mode)
                    : props.title
                }
            </DialogTitle>
            <DialogContent style={{overflow: "visible"}}>
                <Formik
                    initialValues={{...props.creationInitialValues, ...initialValues}}
                    validationSchema={
                        typeof props.validationSchema === "function"
                            ? props.validationSchema(mode)
                            : props.validationSchema
                    }
                    onSubmit={handleSubmit}
                >
                    {({submitForm}) => (
                        <Form>
                            {typeof props.form === "function" ? props.form(mode) : props.form}
                            <DialogActions
                                disabled={props.loading}
                                confirm={{
                                    label: mode === "create" ? "Erstellen" : "Speichern",
                                    onClick: submitForm
                                }}
                                cancel={{onClick: closeDialog}}
                            />
                            {props.loading && <LinearProgress/>}
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    )
}
