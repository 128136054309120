import {Assignment as ProjekteIcon} from "@mui/icons-material";
import React from "react";
import {ProjektListScreen} from "./list/ProjektListScreen";
import {CreateProjektDialogForm} from "./save/CreateProjektDialogForm";
import {ProjektViewScreen} from "./view/ProjektViewScreen";
import {UpdateProjektStammDatenDialogForm} from "./save/EditProjektStammdatenDialogForm";
import {AppFeature} from "../../components/dcbApp/types";

export const projektFeature: AppFeature = {
    path: "projekte",
    label: "Projekte",
    icon: <ProjekteIcon sx={{color: "secondary.main"}}/>,
    list: <ProjektListScreen/>,
    view: <ProjektViewScreen/>,
    dialogs: [<CreateProjektDialogForm/>, <UpdateProjektStammDatenDialogForm/>]
}
