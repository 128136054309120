import React, {FC} from "react";
import {Box, Typography} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";

export const PasswordRequirementDisplay: FC<{isSatisfied: boolean; label: string}> = (props) => (
    <Box
        sx={{
            color: props.isSatisfied ? "success.main" : "error.main",
            display: "flex"
        }}
    >
        {props.isSatisfied ? <CheckIcon/> : <ClearIcon/>}
        <Typography>{props.label}</Typography>
    </Box>
)
