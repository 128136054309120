import React, {FC} from "react";
import {Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Typography} from "@mui/material";
import {MoreVert} from "@mui/icons-material";

interface CardOption {
    label: string,
    icon: JSX.Element,
    onClick: () => void
}

interface CardTitleWithOptionsProps {
    title: string;
    options?: CardOption[];
}

export const CardTitleWithOptions: FC<CardTitleWithOptionsProps> = (props) => {

    const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(null);

    const menuOpen = Boolean(menuAnchorEl);

    const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => setMenuAnchorEl(event.currentTarget);
    const handleCloseMenu = () => setMenuAnchorEl(null);

    return (
        <Box
            sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between"
            }}
        >
            <Typography variant="h6" color="textSecondary">
                {props.title}
            </Typography>
            {props.options &&
            <React.Fragment>
                <IconButton
                    id="card-menu-button"
                    aria-controls="card-menu"
                    aria-haspopup="true"
                    aria-expanded={menuOpen ? 'true' : undefined}
                    onClick={handleOpenMenu}
                >
                    <MoreVert/>
                </IconButton>
                <Menu
                    id="card-menu"
                    anchorEl={menuAnchorEl}
                    open={menuOpen}
                    onClose={handleCloseMenu}
                    MenuListProps={{
                        'aria-labelledby': 'card-menu-button',
                    }}
                >
                    {props.options.map((o, i) => (
                        <MenuItem
                            key={`card-option-${i}`}
                            onClick={() => {
                                handleCloseMenu();
                                o.onClick();
                            }}
                        >
                            <ListItemIcon>
                                {o.icon}
                            </ListItemIcon>
                            <ListItemText>
                                {o.label}
                            </ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            </React.Fragment>}
        </Box>
    );
}
